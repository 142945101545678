import { loadSettingsFromLocalStorage } from '../lib/localStorage'
import { CONFIG } from './config'

export const WORDS = [
  'barij',
  'kifla',
  'neven',
  'nužda',
  'sabor',
  'urban',
  'štuka',
  'žvaka',
  'brđan',
  'svila',
  'južni',
  'sokak',
  'princ',
  'sorta',
  'breza',
  'fosil',
  'pilić',
  'divan',
  'ribež',
  'scena',
  'izraz',
  'bravo',
  'oteći',
  'ponor',
  'rukav',
  'tinta',
  'bacan',
  'uklet',
  'hrvač',
  'start',
  'burek',
  'štura',
  'ćuška',
  'šansa',
  'oktet',
  'jelek',
  'paket',
  'mulat',
  'izaći',
  'teret',
  'obzir',
  'somun',
  'jarac',
  'elita',
  'blago',
  'lasta',
  'arena',
  'pilad',
  'gulaš',
  'javor',
  'konac',
  'štivo',
  'kriza',
  'tenor',
  'gruda',
  'brava',
  'kaput',
  'ustav',
  'smaći',
  'klada',
  'kruna',
  'flora',
  'punđa',
  'trbuh',
  'znati',
  'ameba',
  'nosač',
  'šteka',
  'povez',
  'vokal',
  'barut',
  'nerad',
  'rulet',
  'vašar',
  'moćan',
  'ceker',
  'sloga',
  'bodež',
  'jakna',
  'nagao',
  'crven',
  'džaba',
  'opseg',
  'bacač',
  'bruka',
  'ružan',
  'olovo',
  'dokaz',
  'sedlo',
  'odgoj',
  'zarez',
  'ulica',
  'pamet',
  'pleća',
  'klinč',
  'nagon',
  'obuća',
  'danak',
  'kokoš',
  'skalp',
  'reuma',
  'kvaka',
  'časan',
  'užeći',
  'kvant',
  'stvar',
  'tužan',
  'video',
  'drvar',
  'grmić',
  'humus',
  'tumač',
  'jahta',
  'ploha',
  'sanke',
  'šugav',
  'pozor',
  'roker',
  'kadar',
  'jedar',
  'đuveč',
  'rođak',
  'iskaz',
  'varoš',
  'fišek',
  'lovac',
  'ručni',
  'telad',
  'seoce',
  'greda',
  'biser',
  'suton',
  'bunda',
  'tjeme',
  'namaz',
  'čedan',
  'napon',
  'tisak',
  'šećer',
  'pekar',
  'larva',
  'nered',
  'šofer',
  'slovo',
  'fazon',
  'bijeg',
  'nađen',
  'presa',
  'reper',
  'plato',
  'uzvik',
  'sarma',
  'kokos',
  'litij',
  'brkan',
  'lovor',
  'bocun',
  'čipka',
  'tapir',
  'šepav',
  'vrsta',
  'hitin',
  'napet',
  'sklek',
  'cikot',
  'šverc',
  'grejp',
  'gajba',
  'bakin',
  'škart',
  'višak',
  'stric',
  'potok',
  'manji',
  'rival',
  'enzim',
  'visok',
  'kusur',
  'kopno',
  'talog',
  'obrva',
  'krčma',
  'zanat',
  'gotik',
  'binom',
  'ruina',
  'razum',
  'gorko',
  'ideja',
  'porez',
  'rasol',
  'lager',
  'odžak',
  'jaran',
  'klaun',
  'napad',
  'dreka',
  'šnaps',
  'epski',
  'hrđav',
  'karan',
  'lotus',
  'žohar',
  'jajar',
  'odići',
  'serum',
  'ćevap',
  'donor',
  'kamin',
  'bedro',
  'arhiv',
  'pudla',
  'maska',
  'ribar',
  'dosje',
  'ivica',
  'umiti',
  'vrata',
  'horor',
  'kuhar',
  'pojam',
  'ormar',
  'hitni',
  'pilot',
  'bojna',
  'oruđe',
  'sidro',
  'dubok',
  'batak',
  'puška',
  'sitan',
  'brlog',
  'brzac',
  'sluga',
  'psina',
  'pršut',
  'kramp',
  'sačma',
  'pluća',
  'papak',
  'pasta',
  'birač',
  'lista',
  'štrik',
  'uzeti',
  'nepar',
  'pazuh',
  'crnka',
  'borić',
  'lopov',
  'gepek',
  'burni',
  'aleja',
  'limar',
  'regal',
  'modem',
  'odjel',
  'patos',
  'kopač',
  'tekst',
  'kivan',
  'koban',
  'pužić',
  'krilo',
  'imalo',
  'kalup',
  'ljama',
  'mesar',
  'felga',
  'osama',
  'slama',
  'ćumez',
  'tesan',
  'česma',
  'putar',
  'mašna',
  'kolač',
  'oblak',
  'oslić',
  'kvrga',
  'oglas',
  'genij',
  'psiha',
  'tango',
  'parče',
  'front',
  'rogač',
  'nacrt',
  'krzno',
  'babun',
  'kriti',
  'steći',
  'čuven',
  'konop',
  'tajna',
  'mršav',
  'staza',
  'tunel',
  'garda',
  'žetva',
  'ikona',
  'ključ',
  'skejt',
  'ekipa',
  'čavka',
  'čitav',
  'smrča',
  'čudan',
  'šaran',
  'popis',
  'škamp',
  'kotor',
  'reket',
  'poema',
  'pruga',
  'kotač',
  'đavao',
  'harač',
  'majka',
  'avans',
  'opeka',
  'bolni',
  'gorje',
  'ekran',
  'svjež',
  'pepeo',
  'tečan',
  'okvir',
  'vinil',
  'ratar',
  'badem',
  'ragbi',
  'amper',
  'benav',
  'rujan',
  'škola',
  'bajan',
  'hvala',
  'kavez',
  'torba',
  'rasti',
  'butan',
  'golać',
  'korak',
  'poker',
  'grand',
  'čađav',
  'vitez',
  'bujan',
  'kefir',
  'vjera',
  'ubiti',
  'retro',
  'uštrb',
  'nagib',
  'cista',
  'idući',
  'pumpa',
  'tabla',
  'gatač',
  'ponos',
  'redni',
  'karat',
  'slina',
  'nalet',
  'proza',
  'cesta',
  'opeći',
  'lakši',
  'bačva',
  'ćorav',
  'zahod',
  'zdrav',
  'začas',
  'buket',
  'čopor',
  'okret',
  'grozd',
  'čišći',
  'zglob',
  'jeziv',
  'unuče',
  'nizak',
  'prvak',
  'komad',
  'teror',
  'čujan',
  'krava',
  'žilet',
  'molba',
  'mekan',
  'borac',
  'lavež',
  'akril',
  'režim',
  'tezga',
  'busen',
  'bujad',
  'fešta',
  'motka',
  'dušek',
  'slika',
  'mreža',
  'otvor',
  'cigar',
  'gudač',
  'kurva',
  'ručak',
  'lugar',
  'hašiš',
  'mliko',
  'stući',
  'đubar',
  'salto',
  'nasip',
  'pončo',
  'vazda',
  'taksi',
  'bogat',
  'banja',
  'ajvar',
  'burma',
  'sukob',
  'atlas',
  'breme',
  'blagi',
  'mrgud',
  'nemar',
  'prišt',
  'freza',
  'disaj',
  'dinar',
  'magla',
  'orati',
  'utući',
  'zavoj',
  'brana',
  'voćar',
  'sjeme',
  'kobra',
  'silos',
  'grunt',
  'ispad',
  'udati',
  'osvrt',
  'drven',
  'vlast',
  'fikus',
  'trend',
  'kolac',
  'trati',
  'moral',
  'galij',
  'udaja',
  'snaći',
  'oganj',
  'plata',
  'cinik',
  'frula',
  'utaja',
  'junac',
  'balon',
  'svuda',
  'kurir',
  'škrob',
  'sutra',
  'pagan',
  'skroz',
  'kupka',
  'aroma',
  'nemoć',
  'šljem',
  'nemir',
  'otići',
  'butik',
  'sreća',
  'dugme',
  'tifus',
  'strah',
  'bista',
  'komar',
  'pohod',
  'obrok',
  'štand',
  'dativ',
  'rakun',
  'garav',
  'urota',
  'remen',
  'balet',
  'okrug',
  'lažov',
  'borik',
  'islam',
  'varka',
  'roman',
  'trzaj',
  'volan',
  'afera',
  'pluto',
  'ljudi',
  'ploča',
  'berba',
  'kajak',
  'otpad',
  'nosić',
  'vesta',
  'pitom',
  'grbav',
  'deblo',
  'omlet',
  'jalov',
  'gorač',
  'razor',
  'šunka',
  'obraz',
  'orkan',
  'droga',
  'taksa',
  'carev',
  'zulum',
  'obići',
  'povod',
  'organ',
  'prići',
  'zalet',
  'kubni',
  'novac',
  'spust',
  'čemer',
  'četka',
  'zelen',
  'obala',
  'burad',
  'kvart',
  'sudac',
  'farma',
  'krsta',
  'žrtva',
  'mudar',
  'jesti',
  'korpa',
  'medij',
  'masiv',
  'maher',
  'virus',
  'preći',
  'šiške',
  'peron',
  'golet',
  'iskon',
  'minut',
  'terca',
  'hitan',
  'djeva',
  'seksi',
  'firma',
  'liker',
  'sječa',
  'topaz',
  'vidra',
  'banda',
  'dužan',
  'harfa',
  'odvod',
  'zapet',
  'mamba',
  'čvrst',
  'jasen',
  'luđak',
  'vlaga',
  'futur',
  'lutka',
  'etika',
  'klupa',
  'sisar',
  'zapad',
  'vulva',
  'aorta',
  'pelet',
  'dizel',
  'zvati',
  'zamka',
  'flota',
  'nijem',
  'teren',
  'začin',
  'večer',
  'igrač',
  'tikva',
  'stube',
  'čuvar',
  'ženka',
  'korov',
  'svrha',
  'pojas',
  'sauna',
  'idiom',
  'kosač',
  'gnjio',
  'bagra',
  'cigla',
  'kavga',
  'konus',
  'hrčak',
  'ležaj',
  'klasa',
  'šaraf',
  'omara',
  'lokva',
  'gluma',
  'tetak',
  'rušen',
  'izuti',
  'zmija',
  'vozač',
  'tačan',
  'priča',
  'radon',
  'apeks',
  'odmor',
  'stepa',
  'vihor',
  'štala',
  'aktiv',
  'ricin',
  'mulac',
  'aneks',
  'ljaga',
  'stoka',
  'hlače',
  'sirup',
  'ćosav',
  'glava',
  'titan',
  'etaža',
  'kapak',
  'gašen',
  'obrat',
  'užina',
  'citat',
  'iskra',
  'epoha',
  'naziv',
  'klapa',
  'božić',
  'kukac',
  'bruto',
  'rigor',
  'sigma',
  'unuka',
  'ugled',
  'kombi',
  'zbrka',
  'uliti',
  'način',
  'glina',
  'fokus',
  'sokol',
  'jagma',
  'braco',
  'obris',
  'karma',
  'unija',
  'efekt',
  'zakon',
  'važan',
  'vrana',
  'ljiga',
  'zolja',
  'perut',
  'izlog',
  'izvoz',
  'kotur',
  'građa',
  'revir',
  'metal',
  'umaći',
  'zavod',
  'komet',
  'dabar',
  'forum',
  'galop',
  'vlada',
  'sleng',
  'epika',
  'pozer',
  'dulum',
  'debeo',
  'sitar',
  'krađa',
  'čudak',
  'fajda',
  'oteti',
  'koral',
  'anđeo',
  'jutro',
  'obuti',
  'pacov',
  'buđav',
  'uspon',
  'hrast',
  'muški',
  'lagan',
  'haker',
  'odraz',
  'odjek',
  'bezub',
  'šlapa',
  'ptica',
  'mutan',
  'lanac',
  'padež',
  'nišan',
  'frape',
  'donji',
  'živac',
  'kazna',
  'vapno',
  'vezir',
  'plašt',
  'banka',
  'fauna',
  'sajla',
  'stići',
  'oblik',
  'strop',
  'bunar',
  'janje',
  'munja',
  'smjer',
  'kasta',
  'firer',
  'trnje',
  'avion',
  'lišaj',
  'dlaka',
  'afekt',
  'pomoć',
  'tesar',
  'ječam',
  'mačor',
  'pasus',
  'antik',
  'božur',
  'gajić',
  'helij',
  'nadut',
  'šapat',
  'zubić',
  'sonet',
  'taman',
  'farba',
  'bašča',
  'špaga',
  'naići',
  'tenis',
  'pilav',
  'kupus',
  'hauba',
  'krema',
  'dupli',
  'groši',
  'bomba',
  'april',
  'zvono',
  'basna',
  'sekta',
  'ovčar',
  'šumar',
  'šljam',
  'miris',
  'braća',
  'lijek',
  'riječ',
  'kiseo',
  'zebra',
  'božji',
  'maslo',
  'doksa',
  'poziv',
  'zašto',
  'crnac',
  'bučan',
  'metla',
  'polje',
  'kičma',
  'udalj',
  'inače',
  'robot',
  'dušik',
  'ugalj',
  'usran',
  'panda',
  'sinus',
  'palac',
  'lukav',
  'runda',
  'hrana',
  'golem',
  'gauda',
  'sjeta',
  'dvica',
  'bočni',
  'bečki',
  'onako',
  'podne',
  'bluza',
  'zombi',
  'ovuda',
  'akrap',
  'motor',
  'domar',
  'smeđi',
  'čamac',
  'remek',
  'muzej',
  'opera',
  'škrge',
  'dioda',
  'fraza',
  'alibi',
  'sport',
  'zicer',
  'fetus',
  'torzo',
  'ortak',
  'bosti',
  'strog',
  'dobro',
  'fjord',
  'redar',
  'druid',
  'sudar',
  'forma',
  'njiva',
  'harem',
  'čigra',
  'lišće',
  'geler',
  'ukras',
  'nalik',
  'norma',
  'požar',
  'torta',
  'uputa',
  'sjeći',
  'bijen',
  'šator',
  'umnik',
  'hitac',
  'furka',
  'vrbak',
  'bazga',
  'lider',
  'radar',
  'inćun',
  'bolji',
  'kocka',
  'ugriz',
  'splav',
  'sućut',
  'bačen',
  'obruč',
  'čepić',
  'navoj',
  'oltar',
  'splet',
  'tanak',
  'sfera',
  'hidra',
  'šifra',
  'guska',
  'bogme',
  'gesta',
  'tkivo',
  'žamor',
  'drvce',
  'gluhi',
  'pazar',
  'dojka',
  'bijel',
  'kisik',
  'olako',
  'omjer',
  'tigar',
  'zloba',
  'mrzak',
  'ibrik',
  'pismo',
  'nećak',
  'oklop',
  'plaža',
  'putić',
  'đački',
  'vakum',
  'nevin',
  'zraka',
  'limun',
  'kljun',
  'labud',
  'kofer',
  'pisac',
  'doček',
  'udica',
  'penal',
  'dingo',
  'sunce',
  'kulen',
  'dalek',
  'jetra',
  'bakar',
  'labav',
  'rebus',
  'narod',
  'izrod',
  'sifon',
  'beton',
  'njega',
  'bodac',
  'seoba',
  'klati',
  'flore',
  'porok',
  'gavka',
  'domet',
  'mozak',
  'ožeći',
  'baraž',
  'ručka',
  'ekser',
  'redak',
  'pijun',
  'uzlaz',
  'salon',
  'fetiš',
  'bazar',
  'trava',
  'miran',
  'pasoš',
  'proba',
  'palma',
  'osoba',
  'oštro',
  'griva',
  'slava',
  'sedra',
  'objed',
  'oštar',
  'bditi',
  'magma',
  'ćufta',
  'metak',
  'stroj',
  'pouka',
  'jezik',
  'kralj',
  'rizik',
  'bojni',
  'panel',
  'falus',
  'zapis',
  'bujni',
  'ćelav',
  'kakao',
  'sjena',
  'silaz',
  'polni',
  'salva',
  'buran',
  'peder',
  'idila',
  'tukac',
  'odbor',
  'kvarc',
  'daire',
  'gozba',
  'hajka',
  'cifra',
  'grčki',
  'brčić',
  'uočen',
  'crkva',
  'barok',
  'galon',
  'borer',
  'prkos',
  'kleti',
  'utrka',
  'gužva',
  'uzdah',
  'pirat',
  'smrad',
  'gošća',
  'djelo',
  'begov',
  'mišić',
  'pehar',
  'snaha',
  'ubica',
  'vazal',
  'kanal',
  'flert',
  'uvući',
  'stvor',
  'fazan',
  'grudi',
  'fakat',
  'blaži',
  'rudar',
  'bokal',
  'ishod',
  'jasle',
  'šešir',
  'irvas',
  'baron',
  'snaga',
  'ponoć',
  'tačka',
  'flaša',
  'lisac',
  'zaova',
  'otrov',
  'halva',
  'imbus',
  'vagon',
  'čizma',
  'ratni',
  'tavan',
  'srčan',
  'repić',
  'kadet',
  'tabak',
  'balav',
  'aloja',
  'pčela',
  'shema',
  'štaka',
  'štula',
  'sapun',
  'okuka',
  'čitač',
  'ubrus',
  'gamad',
  'junak',
  'odnos',
  'struk',
  'uvjet',
  'kupac',
  'stado',
  'autor',
  'težak',
  'šlajm',
  'klima',
  'uzlet',
  'jelka',
  'rebro',
  'vodik',
  'ćurka',
  'bitni',
  'puran',
  'žeton',
  'polip',
  'filet',
  'otkup',
  'naboj',
  'sajam',
  'fluks',
  'skuša',
  'bilje',
  'čičak',
  'notar',
  'učiti',
  'čerga',
  'laser',
  'finta',
  'sonda',
  'žalba',
  'lokna',
  'lijes',
  'udana',
  'alias',
  'mriti',
  'cajka',
  'lokus',
  'hokus',
  'jedan',
  'jarad',
  'bolan',
  'bazen',
  'kebab',
  'porod',
  'leglo',
  'ranac',
  'vapaj',
  'gibon',
  'dunja',
  'zloća',
  'saone',
  'šolja',
  'kazan',
  'foton',
  'pasiv',
  'dinja',
  'ovamo',
  'skica',
  'nalog',
  'šipak',
  'djeca',
  'brlja',
  'letak',
  'borje',
  'kožun',
  'dogma',
  'budni',
  'viola',
  'bitka',
  'sulud',
  'tajac',
  'nalaz',
  'izvor',
  'horda',
  'rubin',
  'pizda',
  'autić',
  'osuda',
  'dovik',
  'bizon',
  'saten',
  'bongo',
  'fleka',
  'strka',
  'tečaj',
  'špica',
  'izlet',
  'zubar',
  'pegla',
  'demon',
  'grana',
  'dojam',
  'blizu',
  'penis',
  'dodan',
  'zidar',
  'grubi',
  'šerpa',
  'potop',
  'piton',
  'logor',
  'klopa',
  'macan',
  'bager',
  'slast',
  'vijak',
  'relej',
  'gejša',
  'ljeto',
  'trica',
  'jesen',
  'gnjev',
  'juriš',
  'cigan',
  'svađa',
  'radio',
  'hamas',
  'vodič',
  'dečko',
  'krmak',
  'finiš',
  'ukrug',
  'bliži',
  'atrij',
  'tanan',
  'čorba',
  'iskup',
  'štene',
  'pjena',
  'mačak',
  'orden',
  'tokar',
  'prase',
  'brada',
  'pseći',
  'audio',
  'makar',
  'pušač',
  'zlato',
  'dolar',
  'datum',
  'trans',
  'korzo',
  'celer',
  'otpor',
  'idiot',
  'šelna',
  'imidž',
  'vizir',
  'hokej',
  'šaren',
  'gripa',
  'šizma',
  'punac',
  'hodža',
  'vitak',
  'iftar',
  'najam',
  'pauza',
  'viđen',
  'derbi',
  'bećar',
  'pelin',
  'bingo',
  'kvota',
  'svota',
  'ćošak',
  'vješt',
  'oprez',
  'alarm',
  'lampa',
  'obuka',
  'jahač',
  'trema',
  'kučka',
  'bukva',
  'traka',
  'kečap',
  'tabor',
  'cener',
  'brica',
  'oluja',
  'odron',
  'urlik',
  'jelen',
  'behar',
  'šteta',
  'ideal',
  'jarak',
  'šnala',
  'brzak',
  'kiosk',
  'prost',
  'tašta',
  'nokat',
  'dodir',
  'leden',
  'akter',
  'pupak',
  'tkati',
  'kupon',
  'kolaž',
  'bridž',
  'heroj',
  'ovdje',
  'hmelj',
  'major',
  'budan',
  'porta',
  'oslon',
  'mjera',
  'mater',
  'kutak',
  'želja',
  'šiber',
  'mamac',
  'stega',
  'skala',
  'mazga',
  'masan',
  'stopa',
  'civil',
  'neman',
  'tepih',
  'savez',
  'srati',
  'galeb',
  'voćka',
  'ritam',
  'sljez',
  'vatra',
  'durum',
  'kubik',
  'losos',
  'snovi',
  'nomad',
  'germa',
  'račun',
  'kanap',
  'bivši',
  'album',
  'selam',
  'rotor',
  'otkaz',
  'metež',
  'dopis',
  'govor',
  'sljed',
  'gubav',
  'usuti',
  'samac',
  'saldo',
  'ulcer',
  'posao',
  'mrkva',
  'dizna',
  'jadan',
  'šipka',
  'crtić',
  'držač',
  'ruter',
  'samrt',
  'hljeb',
  'gazda',
  'šaman',
  'dvoje',
  'pleti',
  'toner',
  'šašav',
  'kugla',
  'pašče',
  'pečat',
  'lavor',
  'lemur',
  'cikla',
  'safir',
  'borba',
  'špajz',
  'mačka',
  'dioba',
  'potez',
  'turbo',
  'golub',
  'uljez',
  'papir',
  'bonus',
  'obred',
  'pošta',
  'doboš',
  'pjega',
  'surov',
  'šamar',
  'površ',
  'berač',
  'žilav',
  'hindu',
  'bitak',
  'čoban',
  'boden',
  'uzrok',
  'bolid',
  'mrtav',
  'uvala',
  'grupa',
  'crtež',
  'bedem',
  'zamah',
  'takav',
  'rafal',
  'akord',
  'humor',
  'tučak',
  'pamuk',
  'odred',
  'pseto',
  'škare',
  'žešće',
  'točak',
  'sonar',
  'ćorak',
  'astma',
  'ruski',
  'hitar',
  'dekor',
  'meden',
  'sezam',
  'minus',
  'topao',
  'skije',
  'nepce',
  'atest',
  'etapa',
  'uloga',
  'kafić',
  'momak',
  'ćilim',
  'mango',
  'nimfa',
  'gorak',
  'tempo',
  'tmina',
  'pržen',
  'gajde',
  'ptiče',
  'mason',
  'uviti',
  'gušče',
  'truba',
  'izbor',
  'genom',
  'skela',
  'brati',
  'petak',
  'bajni',
  'smola',
  'mašta',
  'gusle',
  'fetva',
  'zadah',
  'okovi',
  'grlce',
  'gusar',
  'lopta',
  'metar',
  'singl',
  'ureći',
  'arsen',
  'doseg',
  'mošus',
  'dućan',
  'motel',
  'fonem',
  'samba',
  'brico',
  'farsa',
  'kovač',
  'govno',
  'blato',
  'prdež',
  'mačić',
  'brvno',
  'oseka',
  'dukat',
  'poriv',
  'bazni',
  'tumor',
  'svast',
  'ruglo',
  'čupav',
  'anoda',
  'zajam',
  'hotel',
  'čekić',
  'daska',
  'rabat',
  'vakuf',
  'debil',
  'tonik',
  'varen',
  'bušen',
  'graja',
  'šegrt',
  'briga',
  'rulja',
  'mamut',
  'patak',
  'biber',
  'pakao',
  'karta',
  'bajka',
  'draži',
  'barka',
  'nugat',
  'mutav',
  'noćni',
  'feder',
  'senat',
  'sljev',
  'pačić',
  'odaja',
  'surla',
  'dolet',
  'nehaj',
  'biran',
  'polis',
  'napor',
  'stres',
  'burza',
  'melem',
  'šerif',
  'strip',
  'rogat',
  'narav',
  'lokal',
  'sakat',
  'drama',
  'sudba',
  'agent',
  'dezen',
  'gadan',
  'duhan',
  'uveče',
  'važni',
  'tužba',
  'bahat',
  'ćitab',
  'upeći',
  'pleme',
  'smeće',
  'rodeo',
  'pomak',
  'poraz',
  'sklop',
  'perje',
  'ušiti',
  'tegla',
  'nafta',
  'plima',
  'drška',
  'misao',
  'čelik',
  'vreća',
  'diler',
  'kabal',
  'podij',
  'himna',
  'vrelo',
  'vrtić',
  'proći',
  'vosak',
  'litra',
  'uteći',
  'ispis',
  'svući',
  'klica',
  'mrena',
  'bordo',
  'bijes',
  'nauka',
  'motiv',
  'kamen',
  'đubre',
  'izdah',
  'kopča',
  'crvić',
  'dorat',
  'imati',
  'metro',
  'nemio',
  'vedar',
  'izvod',
  'odati',
  'tetka',
  'vepar',
]

function shuffle(array: any[]) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }
}

const isShuffle = loadSettingsFromLocalStorage()?.shuffle

if (isShuffle) {
  shuffle(WORDS)
}
