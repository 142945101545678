import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import { CONFIG } from './constants/config'
import HttpApi from 'i18next-http-backend'

export const localeLanguageKey = 'i18nextLng'
import baTranslation from './constants/locales/ba/translation.json'

i18next.use(initReactI18next).init({
  lng: 'ba',
  resources: {
    ba: {
      translation: baTranslation,
    },
  },
})
/*
i18next
  .use(HttpApi)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`,
    },
    fallbackLng: CONFIG.defaultLang,
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      useSuspense: false,
    },
    saveMissing: true,
  })
    */

export default i18next
