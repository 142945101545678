import { CONFIG } from './config'

export const VALIDGUESSES = [
  'abaka',
  'abake',
  'abces',
  'accis',
  'aceni',
  'adapa',
  'adend',
  'adion',
  'adobe',
  'adris',
  'adult',
  'aduta',
  'adute',
  'aduti',
  'adutu',
  'afekt',
  'afera',
  'afere',
  'aferi',
  'afero',
  'aferu',
  'afeza',
  'afina',
  'afine',
  'afini',
  'afinu',
  'agama',
  'agame',
  'agami',
  'agava',
  'agave',
  'agavi',
  'agavo',
  'agavu',
  'agens',
  'agent',
  'agnat',
  'agona',
  'agrar',
  'agrum',
  'aguti',
  'ahati',
  'ahmed',
  'aikia',
  'ajuga',
  'ajvar',
  'akant',
  'akcij',
  'akana',
  'aknom',
  'akono',
  'akord',
  'akrap',
  'akril',
  'akson',
  'akata',
  'aktom',
  'aktea',
  'akter',
  'aktin',
  'aktiv',
  'alain',
  'alana',
  'alarm',
  'alata',
  'alate',
  'alati',
  'alatu',
  'alaci',
  'alaun',
  'alban',
  'albin',
  'album',
  'aleja',
  'aleje',
  'aleji',
  'alejo',
  'aleju',
  'alela',
  'alele',
  'aleli',
  'alena',
  'aleni',
  'aleut',
  'alfom',
  'algom',
  'alias',
  'alibi',
  'alije',
  'alkom',
  'alkan',
  'alkar',
  'alkil',
  'allen',
  'aloja',
  'alojz',
  'alonž',
  'alpha',
  'alpin',
  'altom',
  'altai',
  'alter',
  'alupe',
  'alvom',
  'ambar',
  'ambis',
  'ambra',
  'ambre',
  'ambri',
  'ambro',
  'ambru',
  'ameba',
  'amebe',
  'amebi',
  'amebu',
  'amida',
  'amidi',
  'amina',
  'amine',
  'amini',
  'aminu',
  'amino',
  'amona',
  'amper',
  'anala',
  'anale',
  'analu',
  'anali',
  'andro',
  'anđel',
  'anđeo',
  'aneks',
  'anica',
  'anion',
  'anisa',
  'anise',
  'anisi',
  'anisu',
  'anker',
  'anoda',
  'anode',
  'anodi',
  'anodo',
  'anodu',
  'antej',
  'antom',
  'anusi',
  'anusa',
  'aorta',
  'aorte',
  'aorti',
  'aorto',
  'aortu',
  'apeks',
  'apela',
  'apele',
  'apeli',
  'apelu',
  'aphel',
  'aplet',
  'apoen',
  'apple',
  'april',
  'arima',
  'arama',
  'arabe',
  'araka',
  'arbun',
  'areal',
  'areja',
  'arena',
  'arene',
  'areni',
  'areno',
  'arenu',
  'argol',
  'argon',
  'argun',
  'argus',
  'arhiv',
  'ariel',
  'arija',
  'arije',
  'ariji',
  'arijo',
  'ariju',
  'arini',
  'arinu',
  'arina',
  'ariša',
  'ariše',
  'ariši',
  'arišu',
  'araka',
  'arkom',
  'arkus',
  'armen',
  'aroma',
  'arome',
  'aromi',
  'aromo',
  'aromu',
  'arsen',
  'aršin',
  'asima',
  'asova',
  'asove',
  'asovi',
  'asket',
  'askus',
  'asovu',
  'aspen',
  'aspik',
  'astat',
  'aster',
  'astma',
  'astme',
  'astmi',
  'astmo',
  'astmu',
  'aston',
  'astra',
  'ataci',
  'ataše',
  'atene',
  'ateni',
  'atenu',
  'atest',
  'atilo',
  'atlas',
  'atlet',
  'atola',
  'atole',
  'atoli',
  'atolu',
  'atoma',
  'atome',
  'atomi',
  'atomu',
  'atona',
  'atrij',
  'atula',
  'audio',
  'audit',
  'aulom',
  'aurom',
  'autom',
  'autić',
  'autom',
  'autor',
  'avala',
  'avale',
  'avali',
  'avalu',
  'avana',
  'avani',
  'avanu',
  'avans',
  'avdom',
  'avers',
  'aveću',
  'aveti',
  'avetu',
  'avion',
  'aviva',
  'avizo',
  'avnoj',
  'azani',
  'azema',
  'azidi',
  'azije',
  'aziji',
  'azila',
  'azile',
  'azili',
  'azilu',
  'azini',
  'aziza',
  'azola',
  'azura',
  'azure',
  'azuri',
  'azuru',
  'babak',
  'babin',
  'babit',
  'babom',
  'babun',
  'bacač',
  'bacan',
  'bacah',
  'bacaj',
  'bacam',
  'bacao',
  'bacaš',
  'bacat',
  'bacil',
  'bacih',
  'bacim',
  'bacio',
  'baciš',
  'bacit',
  'baciv',
  'bačen',
  'bačka',
  'bačke',
  'bački',
  'bačko',
  'bačku',
  'bačva',
  'bačve',
  'bačvi',
  'bačvo',
  'bačvu',
  'badem',
  'baden',
  'badić',
  'bafer',
  'bagav',
  'bager',
  'bagić',
  'bagra',
  'bahat',
  'baird',
  'bajam',
  'bajan',
  'bajna',
  'bajne',
  'bajno',
  'bajnu',
  'bajat',
  'bajaj',
  'bajao',
  'bajaš',
  'bajci',
  'bajka',
  'bajke',
  'bajki',
  'bajko',
  'bajku',
  'bajni',
  'bajta',
  'bajte',
  'bajtu',
  'bajti',
  'bajto',
  'bakom',
  'bakan',
  'bakar',
  'bakra',
  'bakri',
  'bakru',
  'bakći',
  'bakin',
  'bakće',
  'bakću',
  'balom',
  'balav',
  'baldo',
  'balek',
  'balen',
  'balet',
  'balić',
  'balin',
  'balim',
  'balio',
  'bališ',
  'balit',
  'balls',
  'balon',
  'balti',
  'banom',
  'banca',
  'banče',
  'banči',
  'banda',
  'bande',
  'baner',
  'banić',
  'banci',
  'banka',
  'banke',
  'banko',
  'banku',
  'banov',
  'banem',
  'baneš',
  'banuh',
  'banuo',
  'banut',
  'banuv',
  'banja',
  'banje',
  'banju',
  'barom',
  'barač',
  'baraž',
  'barba',
  'barda',
  'barde',
  'bardi',
  'bardu',
  'barel',
  'barem',
  'barić',
  'barij',
  'barit',
  'barci',
  'barka',
  'barke',
  'barki',
  'barko',
  'barku',
  'barok',
  'baron',
  'barov',
  'barun',
  'barut',
  'barža',
  'barže',
  'barži',
  'baržo',
  'baržu',
  'basom',
  'baset',
  'basme',
  'basna',
  'basne',
  'basni',
  'basno',
  'basnu',
  'basov',
  'bašan',
  'bašča',
  'bašče',
  'bašči',
  'baščo',
  'bašču',
  'bašić',
  'baška',
  'baške',
  'baški',
  'baško',
  'bašku',
  'batom',
  'batac',
  'batak',
  'batat',
  'batić',
  'batik',
  'batin',
  'bauer',
  'bauci',
  'bauče',
  'bauka',
  'bauku',
  'bavar',
  'bavim',
  'bavio',
  'baviš',
  'bavit',
  'bazom',
  'bazar',
  'bazah',
  'bazaj',
  'bazam',
  'bazan',
  'bazao',
  'bazaš',
  'bazat',
  'bazde',
  'bazdi',
  'bazen',
  'bazga',
  'bazge',
  'bazgi',
  'bazgo',
  'bazgu',
  'bazna',
  'bazne',
  'bazni',
  'bazno',
  'baznu',
  'bdija',
  'bdiji',
  'bdije',
  'bdiju',
  'bdimo',
  'bdjet',
  'beans',
  'bebom',
  'bečom',
  'bečah',
  'bečao',
  'bečat',
  'bečim',
  'bečiš',
  'bečej',
  'bečen',
  'bečah',
  'bečim',
  'bečio',
  'bečiš',
  'bečit',
  'bečka',
  'bečke',
  'bečki',
  'bečko',
  'bečku',
  'bećar',
  'bedak',
  'bedem',
  'bedra',
  'bedro',
  'bedru',
  'bedža',
  'bedžu',
  'begom',
  'begum',
  'behar',
  'bekom',
  'bekne',
  'bekni',
  'beknu',
  'belić',
  'belot',
  'belji',
  'belja',
  'belje',
  'beljo',
  'benav',
  'benda',
  'bendu',
  'benić',
  'benko',
  'bepov',
  'berač',
  'beram',
  'berba',
  'berbe',
  'berbi',
  'berbo',
  'berbu',
  'bereš',
  'beril',
  'beriv',
  'berma',
  'bermu',
  'besom',
  'besan',
  'besna',
  'besne',
  'besni',
  'besnu',
  'bešen',
  'betom',
  'betel',
  'betin',
  'beton',
  'bezan',
  'bezub',
  'bezuh',
  'binoj',
  'biber',
  'bičem',
  'bičaš',
  'bičuj',
  'bičić',
  'bičja',
  'bičje',
  'bičji',
  'bičju',
  'bićem',
  'bićih',
  'bićin',
  'bidea',
  'bidee',
  'bidei',
  'bideu',
  'bifea',
  'bifee',
  'bifei',
  'bifeu',
  'bifla',
  'bihać',
  'bijeg',
  'bijel',
  'bijen',
  'bijes',
  'bikom',
  'bikov',
  'bileć',
  'bilin',
  'billa',
  'bilom',
  'bilja',
  'bilje',
  'bilju',
  'bingo',
  'binom',
  'biota',
  'bipod',
  'birač',
  'biran',
  'birah',
  'biraj',
  'biram',
  'birao',
  'biraš',
  'birat',
  'biroa',
  'biroe',
  'biroi',
  'birou',
  'birsa',
  'bisom',
  'bisau',
  'biser',
  'biska',
  'biske',
  'biski',
  'bisko',
  'bisku',
  'bista',
  'biste',
  'bisti',
  'bisto',
  'bistu',
  'bitom',
  'bitak',
  'bitče',
  'bitka',
  'bitku',
  'biter',
  'bijem',
  'biješ',
  'bijte',
  'bitci',
  'bitka',
  'bitke',
  'bitki',
  'bitko',
  'bitku',
  'bitan',
  'bitna',
  'bitne',
  'bitni',
  'bitno',
  'bitnu',
  'bitov',
  'bitva',
  'bitve',
  'bitvi',
  'bitvu',
  'bivak',
  'bivci',
  'bivče',
  'bivka',
  'bivke',
  'bivku',
  'bivah',
  'bivaj',
  'bivam',
  'bivao',
  'bivaš',
  'bivat',
  'bivol',
  'bivša',
  'bivše',
  'bivši',
  'bivšu',
  'bizam',
  'bizon',
  'bjehu',
  'bjelo',
  'bježe',
  'bježi',
  'blaga',
  'blage',
  'blago',
  'blagu',
  'blaže',
  'blagi',
  'blaga',
  'blago',
  'blair',
  'blati',
  'blate',
  'blati',
  'blata',
  'blato',
  'blatu',
  'blaža',
  'blaži',
  'blaže',
  'blaži',
  'blefa',
  'blefe',
  'blefu',
  'bleje',
  'bleji',
  'blene',
  'bleni',
  'blenu',
  'blica',
  'blicu',
  'blind',
  'bliza',
  'blize',
  'blizi',
  'blizo',
  'bližu',
  'blizu',
  'bliže',
  'bliža',
  'bliži',
  'block',
  'bloga',
  'blogu',
  'bloka',
  'bloku',
  'bluda',
  'blude',
  'bludu',
  'bludi',
  'blues',
  'bluna',
  'blune',
  'bluni',
  'bluno',
  'blunu',
  'bluza',
  'bluze',
  'bluzi',
  'bluzo',
  'bluzu',
  'bljuj',
  'boama',
  'bobac',
  'bobić',
  'bocom',
  'bocim',
  'bocka',
  'bocne',
  'bocni',
  'bocnu',
  'bocun',
  'bočan',
  'bočat',
  'bočna',
  'bočne',
  'bočni',
  'bočno',
  'bočnu',
  'bočno',
  'boćom',
  'boćar',
  'boćah',
  'boćaj',
  'boćam',
  'boćao',
  'boćaš',
  'boćat',
  'bodom',
  'bocem',
  'bodac',
  'bodca',
  'bodce',
  'bodci',
  'bodcu',
  'bodče',
  'bodač',
  'bodar',
  'bodra',
  'bodre',
  'bodri',
  'bodro',
  'bodru',
  'boden',
  'bodež',
  'boduj',
  'bodre',
  'bodri',
  'bodul',
  'boema',
  'boeme',
  'boemi',
  'boemu',
  'boema',
  'boeme',
  'boemi',
  'boemo',
  'boemu',
  'bofla',
  'bofle',
  'bofli',
  'boflu',
  'bofor',
  'bogom',
  'bogac',
  'bokca',
  'bokci',
  'bokcu',
  'bokče',
  'bogat',
  'bogav',
  'bogih',
  'bogme',
  'boguj',
  'bohem',
  'bojom',
  'bojan',
  'bojah',
  'bojam',
  'bojao',
  'bojaš',
  'bojat',
  'bojim',
  'bojmo',
  'bojte',
  'bojen',
  'bojuj',
  'bojem',
  'bojah',
  'bojim',
  'bojio',
  'bojiš',
  'bojit',
  'bojna',
  'bojne',
  'bojni',
  'bojnu',
  'bojna',
  'bojne',
  'bojni',
  'bojno',
  'bojnu',
  'bojuj',
  'bokom',
  'bokal',
  'bokor',
  'boksu',
  'boksa',
  'bokun',
  'bolom',
  'bolan',
  'bolna',
  'bolne',
  'bolni',
  'bolnu',
  'bolid',
  'bolna',
  'bolno',
  'boluj',
  'bolta',
  'bolto',
  'bolim',
  'bolio',
  'bolja',
  'bolje',
  'bolji',
  'boljo',
  'bolju',
  'bomba',
  'bombe',
  'bombi',
  'bombo',
  'bombu',
  'bonom',
  'bongo',
  'bonus',
  'borom',
  'borac',
  'borca',
  'borce',
  'borci',
  'borcu',
  'borče',
  'borač',
  'borak',
  'boran',
  'borat',
  'borao',
  'borba',
  'borbe',
  'borbi',
  'borbo',
  'borbu',
  'bordo',
  'borer',
  'borić',
  'borik',
  'borah',
  'boren',
  'borim',
  'borio',
  'boriš',
  'borit',
  'borja',
  'borje',
  'borju',
  'borna',
  'borne',
  'borni',
  'bornu',
  'boron',
  'borov',
  'boršč',
  'bosih',
  'bosim',
  'bosog',
  'bosoj',
  'bosom',
  'bosan',
  'bosnu',
  'bodem',
  'bodeš',
  'bosti',
  'bosut',
  'boško',
  'botun',
  'bovom',
  'bozom',
  'bozon',
  'božan',
  'božja',
  'božje',
  'božji',
  'božju',
  'božur',
  'braca',
  'brace',
  'brace',
  'braci',
  'braco',
  'bracu',
  'braču',
  'braća',
  'braće',
  'braći',
  'braćo',
  'braću',
  'brada',
  'brade',
  'bradi',
  'brado',
  'bradu',
  'braga',
  'braka',
  'brake',
  'braku',
  'brama',
  'brane',
  'brani',
  'brana',
  'brano',
  'branu',
  'brand',
  'brane',
  'brani',
  'branj',
  'brata',
  'brate',
  'bratu',
  'braca',
  'brače',
  'berem',
  'brahu',
  'brala',
  'brale',
  'brali',
  'bralo',
  'brano',
  'brati',
  'brate',
  'brati',
  'braun',
  'braut',
  'brave',
  'bravi',
  'bravu',
  'brava',
  'brave',
  'bravi',
  'bravo',
  'bravu',
  'bravo',
  'brbot',
  'brčić',
  'brčka',
  'brčko',
  'brčni',
  'brčnu',
  'brčne',
  'brdom',
  'brđan',
  'breca',
  'breča',
  'breči',
  'breče',
  'bređa',
  'bređi',
  'bređu',
  'breka',
  'breme',
  'brent',
  'brest',
  'breve',
  'breza',
  'breze',
  'brezi',
  'brezo',
  'brezu',
  'brica',
  'brice',
  'bricu',
  'brida',
  'bride',
  'bridu',
  'bride',
  'bridi',
  'bridž',
  'briga',
  'brige',
  'brigo',
  'brigu',
  'brizi',
  'briga',
  'brije',
  'briju',
  'brika',
  'briku',
  'brine',
  'brini',
  'brinu',
  'brinj',
  'brioš',
  'brisa',
  'brise',
  'brisu',
  'briše',
  'briši',
  'brišu',
  'braše',
  'brkom',
  'brkan',
  'brkaj',
  'brkam',
  'brkao',
  'brkaš',
  'brkat',
  'brlić',
  'brlog',
  'brlja',
  'brlje',
  'brlji',
  'brljo',
  'brlju',
  'brnja',
  'brnje',
  'brnji',
  'brnjo',
  'brnju',
  'broća',
  'broću',
  'broda',
  'brode',
  'brodu',
  'brode',
  'brodi',
  'broja',
  'broju',
  'broje',
  'broji',
  'broje',
  'broji',
  'broma',
  'brome',
  'bromu',
  'bronh',
  'broša',
  'brošu',
  'brsta',
  'brste',
  'brstu',
  'brste',
  'brsti',
  'brtva',
  'brtve',
  'brtvi',
  'brtvo',
  'brtvu',
  'brtve',
  'brtvi',
  'bruha',
  'bruhu',
  'bruše',
  'bruja',
  'bruju',
  'bruje',
  'bruji',
  'bruci',
  'bruka',
  'bruke',
  'bruko',
  'bruku',
  'bruka',
  'brune',
  'brusa',
  'bruse',
  'brusi',
  'bruto',
  'brvak',
  'brvci',
  'brvče',
  'brvke',
  'brvku',
  'brvna',
  'brvno',
  'brvnu',
  'brzih',
  'brzoj',
  'brzac',
  'brzak',
  'brzan',
  'brzah',
  'brzaj',
  'brzam',
  'brzao',
  'brzaš',
  'brzat',
  'brzim',
  'brzog',
  'brzom',
  'bržem',
  'bržom',
  'bržeg',
  'bržih',
  'bržim',
  'bržoj',
  'bubom',
  'bubaj',
  'bubam',
  'buban',
  'bubaš',
  'bubin',
  'bubne',
  'bubni',
  'bubnu',
  'bubre',
  'bubri',
  'bučan',
  'bučne',
  'bučni',
  'bučno',
  'bučnu',
  'bučao',
  'bučah',
  'bučen',
  'bučim',
  'bučio',
  'bučiš',
  'bučit',
  'bučja',
  'bučje',
  'bučju',
  'bučji',
  'bučna',
  'bučno',
  'bućom',
  'bućin',
  'bućka',
  'bućni',
  'bućnu',
  'bućne',
  'budak',
  'budan',
  'budna',
  'budne',
  'budnu',
  'budim',
  'budim',
  'budio',
  'budiš',
  'budit',
  'buđah',
  'budni',
  'budno',
  'budvi',
  'budža',
  'budže',
  'budži',
  'budžo',
  'budžu',
  'buđav',
  'buđen',
  'bugar',
  'buhom',
  'buhač',
  'buhar',
  'buhav',
  'bujad',
  'bujan',
  'bujna',
  'bujne',
  'bujni',
  'bujno',
  'bujnu',
  'bujah',
  'bujaj',
  'bujam',
  'bujao',
  'bujaš',
  'bujat',
  'bujol',
  'bujon',
  'bukom',
  'bukač',
  'bukea',
  'bukee',
  'bukei',
  'bukeu',
  'buket',
  'bukne',
  'bukni',
  'buknu',
  'bukoč',
  'bukov',
  'bukša',
  'bukta',
  'bukte',
  'bukti',
  'bukva',
  'bukve',
  'bulom',
  'bulat',
  'bulić',
  'bulin',
  'bulov',
  'bulje',
  'bulji',
  'bumom',
  'bumbe',
  'bumbi',
  'bunom',
  'bunar',
  'bunca',
  'bunda',
  'bunde',
  'bundi',
  'bundo',
  'bundu',
  'bunim',
  'bunio',
  'buniš',
  'bunit',
  'bunta',
  'bunte',
  'buntu',
  'bunja',
  'bunje',
  'bunji',
  'bunjo',
  'bunju',
  'burom',
  'burač',
  'burad',
  'burag',
  'buran',
  'burna',
  'burne',
  'burni',
  'burno',
  'burnu',
  'burek',
  'burga',
  'burge',
  'burgu',
  'burže',
  'burin',
  'burka',
  'burle',
  'burme',
  'burmi',
  'burmu',
  'burno',
  'burza',
  'burze',
  'burzi',
  'burzo',
  'burzu',
  'busah',
  'busaj',
  'busam',
  'busan',
  'busao',
  'busaš',
  'busat',
  'busen',
  'busha',
  'bushu',
  'bušom',
  'bušač',
  'bušan',
  'bušna',
  'bušne',
  'bušni',
  'bušno',
  'bušnu',
  'bušel',
  'bušen',
  'bušić',
  'bušin',
  'bušah',
  'bušim',
  'bušio',
  'bušiš',
  'bušit',
  'bušja',
  'bušje',
  'bušji',
  'bušju',
  'butom',
  'butik',
  'butil',
  'buzin',
  'bužim',
  'bužir',
  'cache',
  'caffe',
  'cagan',
  'cajka',
  'cajke',
  'cakom',
  'cakći',
  'cakle',
  'cakli',
  'cakće',
  'cakći',
  'cakću',
  'capin',
  'capka',
  'carem',
  'cardo',
  'carev',
  'caruj',
  'carić',
  'carin',
  'carla',
  'carle',
  'carli',
  'carlo',
  'ccitt',
  'cedar',
  'cedra',
  'cedre',
  'cedru',
  'cehom',
  'ceker',
  'cekin',
  'cekum',
  'celer',
  'celom',
  'cener',
  'centi',
  'centa',
  'cente',
  'centi',
  'cento',
  'centu',
  'cepin',
  'cepte',
  'cepti',
  'cerju',
  'cerom',
  'cerij',
  'cerah',
  'cerim',
  'cerio',
  'ceriš',
  'cerit',
  'cerja',
  'cerov',
  'cesar',
  'ceski',
  'cesta',
  'ceste',
  'cesti',
  'cesto',
  'cestu',
  'cetin',
  'cezij',
  'chris',
  'cibet',
  'cibos',
  'cicem',
  'cicom',
  'cican',
  'cicah',
  'cicaj',
  'cicam',
  'cicao',
  'cicaš',
  'cicat',
  'cičom',
  'cičah',
  'cičao',
  'cičat',
  'cičim',
  'cičiš',
  'cifri',
  'cifru',
  'cifra',
  'cifre',
  'cifra',
  'cigan',
  'cigar',
  'cigin',
  'cigla',
  'cigle',
  'cigli',
  'ciglo',
  'ciglu',
  'cigla',
  'cigle',
  'cigli',
  'ciglo',
  'ciglu',
  'cijan',
  'cijeđ',
  'cijep',
  'cijev',
  'cijuk',
  'cikom',
  'cikas',
  'cikći',
  'cikla',
  'cikle',
  'cikli',
  'ciklo',
  'ciklu',
  'cikne',
  'cikni',
  'ciknu',
  'cikot',
  'cikće',
  'cikći',
  'cikću',
  'cilik',
  'cilja',
  'cilje',
  'cilju',
  'cilja',
  'cilju',
  'cimom',
  'ciman',
  'cimah',
  'cimaj',
  'cimam',
  'cimao',
  'cimaš',
  'cimat',
  'cimer',
  'cimet',
  'cimne',
  'cimni',
  'cimnu',
  'cinik',
  'cinka',
  'cinku',
  'cinka',
  'cipal',
  'cipla',
  'ciple',
  'cipli',
  'ciplu',
  'cipus',
  'cirka',
  'cirku',
  'cirus',
  'cisti',
  'cistu',
  'cista',
  'ciste',
  'cisto',
  'citat',
  'citra',
  'citre',
  'citri',
  'citro',
  'citru',
  'civil',
  'cjeli',
  'cjeti',
  'clark',
  'cliff',
  'cluba',
  'cmače',
  'cmači',
  'cmaču',
  'cmare',
  'cmari',
  'cobol',
  'cocom',
  'cokac',
  'cokće',
  'cokću',
  'colom',
  'colin',
  'comms',
  'coper',
  'copra',
  'cosac',
  'costa',
  'cotav',
  'craig',
  'crkla',
  'crkao',
  'crkli',
  'crkne',
  'crkni',
  'crknu',
  'crkoh',
  'crkva',
  'crkve',
  'crkvi',
  'crkvo',
  'crkvu',
  'crnih',
  'crnim',
  'crnoj',
  'crnac',
  'crnca',
  'crnce',
  'crnci',
  'crncu',
  'crnče',
  'crnči',
  'crnog',
  'crnom',
  'crnić',
  'crnit',
  'crnka',
  'crnke',
  'crnki',
  'crnko',
  'crnku',
  'crnja',
  'crnju',
  'crnim',
  'crnio',
  'crniš',
  'crnje',
  'crnji',
  'crpac',
  'crpca',
  'crpce',
  'crpci',
  'crpcu',
  'crpče',
  'crpen',
  'crpim',
  'crpio',
  'crpit',
  'crpci',
  'crpka',
  'crpke',
  'crpki',
  'crpko',
  'crpku',
  'crpna',
  'crpne',
  'crpni',
  'crpno',
  'crpnu',
  'crpah',
  'crpao',
  'crpem',
  'crpeš',
  'crpla',
  'crple',
  'crpli',
  'crplo',
  'crpst',
  'crtom',
  'crtač',
  'crtan',
  'crtah',
  'crtaj',
  'crtam',
  'crtao',
  'crtaš',
  'crtat',
  'crtež',
  'crtić',
  'crtka',
  'crvom',
  'crvac',
  'crvca',
  'crvce',
  'crvci',
  'crvcu',
  'crvče',
  'crvak',
  'crvci',
  'crvče',
  'crvka',
  'crvke',
  'crvku',
  'crvaš',
  'crven',
  'crvić',
  'cubar',
  'cucak',
  'cucka',
  'cucke',
  'cucki',
  'cucku',
  'cucla',
  'cucle',
  'cucli',
  'cuclo',
  'cuclu',
  'cucla',
  'cugah',
  'cugaj',
  'cugam',
  'cugao',
  'cugaš',
  'cugat',
  'cukar',
  'cukra',
  'cukre',
  'cukri',
  'cukru',
  'cukor',
  'cunja',
  'cunje',
  'cunji',
  'cunjo',
  'cunju',
  'cupka',
  'cupne',
  'cupni',
  'cupnu',
  'curom',
  'curij',
  'curah',
  'curim',
  'curio',
  'curiš',
  'curit',
  'curne',
  'curni',
  'curnu',
  'cuvik',
  'cvala',
  'cvale',
  'cvali',
  'cvalo',
  'cvast',
  'cvate',
  'cvati',
  'cvatu',
  'cvata',
  'cvate',
  'cvatu',
  'cvale',
  'cveče',
  'cveka',
  'cveku',
  'cvika',
  'cvile',
  'cvili',
  'cvita',
  'cvrči',
  'cvrče',
  'čabar',
  'čabri',
  'čačić',
  'čačka',
  'čador',
  'čađom',
  'čađav',
  'čađah',
  'čađim',
  'čađio',
  'čađiš',
  'čađit',
  'čajem',
  'čajna',
  'čajne',
  'čajni',
  'čajno',
  'čajnu',
  'čakra',
  'čakre',
  'čakri',
  'čalma',
  'čalme',
  'čalmi',
  'čalmo',
  'čalmu',
  'čamom',
  'čamac',
  'čamca',
  'čamce',
  'čamci',
  'čamcu',
  'čamče',
  'čaman',
  'čamna',
  'čamne',
  'čamni',
  'čamno',
  'čamnu',
  'čamov',
  'čamuj',
  'čanak',
  'čanci',
  'čanče',
  'čanka',
  'čanke',
  'čanku',
  'čanić',
  'čapor',
  'čarju',
  'čaran',
  'čarah',
  'čaraj',
  'čaram',
  'čarao',
  'čaraš',
  'čarat',
  'čarka',
  'čarke',
  'čarki',
  'čarku',
  'čarka',
  'čarna',
  'čarne',
  'čarni',
  'čarno',
  'čarnu',
  'časom',
  'časak',
  'časci',
  'časka',
  'časke',
  'časku',
  'čašče',
  'časan',
  'časki',
  'časna',
  'časni',
  'časno',
  'časti',
  'čašću',
  'časne',
  'časni',
  'časno',
  'časnu',
  'časte',
  'časti',
  'čašom',
  'čaška',
  'čaške',
  'čaški',
  'čašku',
  'čatež',
  'čauša',
  'čauše',
  'čauši',
  'čaušu',
  'čavao',
  'čavla',
  'čavle',
  'čavli',
  'čavlu',
  'čavić',
  'čavka',
  'čavke',
  'čavki',
  'čavko',
  'čavku',
  'čedom',
  'čedad',
  'čedan',
  'čedna',
  'čedne',
  'čedni',
  'čedno',
  'čednu',
  'čedno',
  'čehan',
  'čehah',
  'čehaj',
  'čeham',
  'čehao',
  'čehaš',
  'čehat',
  'čekom',
  'čekan',
  'čekah',
  'čekaj',
  'čekam',
  'čekao',
  'čekaš',
  'čekat',
  'čekić',
  'čekom',
  'čekrk',
  'čelnu',
  'čelce',
  'čelik',
  'čelna',
  'čelne',
  'čelni',
  'čelno',
  'čelom',
  'čemer',
  'čemin',
  'čelna',
  'čeona',
  'čeone',
  'čeoni',
  'čeono',
  'čeonu',
  'čepom',
  'čepić',
  'čepin',
  'čerga',
  'čerge',
  'čergi',
  'čergo',
  'čergu',
  'česan',
  'česki',
  'česma',
  'česme',
  'česmi',
  'česmo',
  'česmu',
  'česta',
  'česte',
  'čestu',
  'češću',
  'česti',
  'često',
  'češan',
  'češah',
  'češaj',
  'češam',
  'češao',
  'češaš',
  'češat',
  'češem',
  'češeš',
  'češće',
  'češća',
  'češće',
  'češći',
  'češer',
  'češka',
  'češke',
  'češki',
  'češko',
  'češku',
  'češne',
  'češni',
  'češnu',
  'četom',
  'četka',
  'četke',
  'četki',
  'četku',
  'četka',
  'četna',
  'četne',
  'četni',
  'četno',
  'četnu',
  'četuj',
  'čezne',
  'čezni',
  'čeznu',
  'čibuk',
  'čičom',
  'čičak',
  'čičci',
  'čička',
  'čičke',
  'čičku',
  'čičin',
  'čigra',
  'čigre',
  'čigri',
  'čihan',
  'čihah',
  'čihaj',
  'čiham',
  'čihao',
  'čihaš',
  'čihat',
  'čijeg',
  'čijem',
  'čijih',
  'čijim',
  'čijoj',
  'čijom',
  'čikom',
  'čikoš',
  'čilaš',
  'čileu',
  'čilih',
  'čilim',
  'čiloj',
  'čilom',
  'čilić',
  'činom',
  'činim',
  'činio',
  'činiš',
  'činit',
  'čilog',
  'čioda',
  'čiode',
  'čiodi',
  'čiodo',
  'čiodu',
  'čiopa',
  'čiope',
  'čiopi',
  'čiopo',
  'čiopu',
  'čipom',
  'čipka',
  'čipke',
  'čipki',
  'čipko',
  'čipku',
  'čipka',
  'čipsa',
  'čipsu',
  'čirom',
  'čirak',
  'čiraš',
  'čirav',
  'čirić',
  'čisla',
  'čislo',
  'čislu',
  'čista',
  'čiste',
  'čisti',
  'čisto',
  'čistu',
  'čiste',
  'čisti',
  'čisto',
  'čišća',
  'čišće',
  'čišći',
  'čišću',
  'čitač',
  'čitak',
  'čitka',
  'čitke',
  'čitki',
  'čitko',
  'čitku',
  'čitan',
  'čitah',
  'čitaj',
  'čitam',
  'čitao',
  'čitaš',
  'čitat',
  'čitav',
  'čitko',
  'čizma',
  'čizme',
  'čizmi',
  'čizmo',
  'čizmu',
  'čišci',
  'čišče',
  'čiška',
  'čiške',
  'čišku',
  'čižak',
  'čkalj',
  'člana',
  'člane',
  'člani',
  'članu',
  'čmara',
  'čmare',
  'čmaru',
  'čmava',
  'čoban',
  'čohom',
  'čohan',
  'čojom',
  'čokom',
  'čokom',
  'čokot',
  'čolak',
  'čoper',
  'čopor',
  'čorak',
  'čorba',
  'čorbe',
  'čorbi',
  'čorbo',
  'čorbu',
  'čotav',
  'čović',
  'črčka',
  'črčne',
  'črčni',
  'črčnu',
  'čubom',
  'čubar',
  'čubav',
  'čučah',
  'čučao',
  'čučat',
  'čučim',
  'čučiš',
  'čučne',
  'čučni',
  'čučnu',
  'čudak',
  'čudan',
  'čudna',
  'čudne',
  'čudni',
  'čudno',
  'čudnu',
  'čudim',
  'čudio',
  'čudiš',
  'čudit',
  'čudno',
  'čudom',
  'čujan',
  'čujna',
  'čujne',
  'čujno',
  'čujnu',
  'čujni',
  'čukom',
  'čukne',
  'čukni',
  'čuknu',
  'čulom',
  'čulan',
  'čulna',
  'čulne',
  'čulno',
  'čulnu',
  'čulav',
  'čulni',
  'čumav',
  'čunom',
  'čunak',
  'čunci',
  'čunka',
  'čunke',
  'čunku',
  'čunja',
  'čunju',
  'čupom',
  'čupaj',
  'čupam',
  'čupao',
  'čupaš',
  'čupav',
  'čupka',
  'čupne',
  'čupni',
  'čupnu',
  'čujah',
  'čujem',
  'čuješ',
  'čujmo',
  'čujte',
  'čusmo',
  'čuste',
  'čuvan',
  'čuvar',
  'čuvah',
  'čuvaj',
  'čuvam',
  'čuvao',
  'čuvaš',
  'čuvat',
  'čuven',
  'čuvši',
  'čvora',
  'čvore',
  'čvoru',
  'čvorč',
  'čvrga',
  'čvrge',
  'čvrgi',
  'čvrgo',
  'čvrgu',
  'čvrst',
  'ćaćom',
  'ćaćić',
  'ćaćin',
  'ćaska',
  'ćatom',
  'ćefom',
  'ćelom',
  'ćelav',
  'ćemer',
  'ćesić',
  'ćevap',
  'ćifta',
  'ćifte',
  'ćifti',
  'ćifto',
  'ćiftu',
  'ćilim',
  'ćiril',
  'ćitab',
  'ćopav',
  'ćopih',
  'ćopim',
  'ćopio',
  'ćopiš',
  'ćopit',
  'ćorak',
  'ćorci',
  'ćorče',
  'ćorka',
  'ćorke',
  'ćorku',
  'ćorav',
  'ćorom',
  'ćosav',
  'ćosom',
  'ćošak',
  'ćoška',
  'ćošku',
  'ćozom',
  'ćubom',
  'ćučja',
  'ćučje',
  'ćučji',
  'ćučju',
  'ćufte',
  'ćukom',
  'ćulom',
  'ćulim',
  'ćulio',
  'ćuliš',
  'ćulit',
  'ćumez',
  'ćupom',
  'ćurak',
  'ćurci',
  'ćurče',
  'ćurka',
  'ćurke',
  'ćurku',
  'ćurku',
  'ćušci',
  'ćuška',
  'ćuške',
  'ćuški',
  'ćuško',
  'ćušku',
  'ćuška',
  'ćušne',
  'ćušni',
  'ćušnu',
  'ćutim',
  'ćutio',
  'ćutiš',
  'dabar',
  'dabra',
  'dabru',
  'dacit',
  'dačka',
  'dački',
  'daćom',
  'dahom',
  'dahne',
  'dahni',
  'dahnu',
  'dahće',
  'dahću',
  'daira',
  'daire',
  'dajem',
  'dakah',
  'dakaj',
  'dakam',
  'dakao',
  'dakaš',
  'dakat',
  'dakle',
  'dalih',
  'dalaj',
  'dalek',
  'dalić',
  'dalim',
  'dalom',
  'dalje',
  'dalja',
  'dalji',
  'damom',
  'damin',
  'danom',
  'dance',
  'danak',
  'danas',
  'danih',
  'danim',
  'danog',
  'danoj',
  'danik',
  'danil',
  'danim',
  'danio',
  'daniš',
  'danit',
  'danka',
  'danku',
  'danko',
  'danuj',
  'dante',
  'danja',
  'danja',
  'danje',
  'danji',
  'danju',
  'danju',
  'darom',
  'drima',
  'darga',
  'dargo',
  'darne',
  'darni',
  'darnu',
  'daruj',
  'dasci',
  'daska',
  'daske',
  'daski',
  'dasko',
  'dasku',
  'daske',
  'dašak',
  'dašci',
  'dašče',
  'daška',
  'daške',
  'dašku',
  'dašće',
  'dašto',
  'dadem',
  'dadeš',
  'dadne',
  'dadnu',
  'dadoh',
  'dajmo',
  'dajte',
  'dasmo',
  'daste',
  'datih',
  'datog',
  'datom',
  'dativ',
  'datum',
  'davač',
  'davan',
  'dajem',
  'daješ',
  'davah',
  'davao',
  'davat',
  'davež',
  'david',
  'davis',
  'davim',
  'davio',
  'daviš',
  'davit',
  'davna',
  'davne',
  'davni',
  'davno',
  'davnu',
  'davno',
  'davši',
  'dažda',
  'dažde',
  'daždu',
  'dažde',
  'daždi',
  'debeo',
  'debil',
  'debla',
  'deblo',
  'deblu',
  'dečka',
  'dečke',
  'dečki',
  'dečko',
  'dečku',
  'dedić',
  'defom',
  'degen',
  'deist',
  'dekom',
  'dekan',
  'dekih',
  'dekor',
  'delen',
  'delta',
  'delte',
  'delti',
  'delto',
  'deltu',
  'demon',
  'dendi',
  'denes',
  'denga',
  'denis',
  'depoa',
  'depoe',
  'depoi',
  'depou',
  'derač',
  'deran',
  'derao',
  'derat',
  'derbi',
  'derma',
  'desen',
  'deset',
  'desih',
  'desim',
  'desio',
  'desiš',
  'desit',
  'desku',
  'deska',
  'deski',
  'desna',
  'desne',
  'desni',
  'desno',
  'desnu',
  'desno',
  'devom',
  'devet',
  'devin',
  'devon',
  'dezen',
  'diana',
  'diane',
  'dičan',
  'dična',
  'dične',
  'dični',
  'dično',
  'dičnu',
  'dičah',
  'dičim',
  'dičio',
  'dičiš',
  'dičit',
  'digao',
  'digla',
  'digli',
  'dignu',
  'dižeš',
  'diego',
  'dieni',
  'digao',
  'digla',
  'digle',
  'digli',
  'diglo',
  'digne',
  'digni',
  'dignu',
  'digoh',
  'dijel',
  'djeca',
  'djece',
  'djecu',
  'dijon',
  'dikih',
  'dikoj',
  'dikom',
  'dildi',
  'dildo',
  'diler',
  'dimom',
  'dimer',
  'dimim',
  'dimio',
  'dimiš',
  'dimit',
  'dimna',
  'dimne',
  'dimni',
  'dimno',
  'dimnu',
  'dinom',
  'dinar',
  'dingu',
  'dingo',
  'dinka',
  'dinko',
  'dinja',
  'dinje',
  'dinji',
  'dinjo',
  'dinju',
  'dioba',
  'diobe',
  'diobi',
  'diobo',
  'diobu',
  'dioda',
  'diode',
  'diodi',
  'diodo',
  'diodu',
  'diola',
  'dioli',
  'diona',
  'dione',
  'dioni',
  'diono',
  'dionu',
  'diple',
  'dipol',
  'diran',
  'dirah',
  'diraj',
  'diram',
  'dirao',
  'diraš',
  'dirat',
  'dirci',
  'dirka',
  'dirke',
  'dirki',
  'dirko',
  'dirku',
  'dirne',
  'dirni',
  'dirnu',
  'disaj',
  'disah',
  'disao',
  'disat',
  'dišeš',
  'diska',
  'disku',
  'diski',
  'disko',
  'dišem',
  'dišna',
  'dišne',
  'dišni',
  'dišno',
  'dišnu',
  'divom',
  'divan',
  'divna',
  'divne',
  'divni',
  'divnu',
  'divin',
  'divim',
  'divio',
  'diviš',
  'divit',
  'divno',
  'dizač',
  'dizan',
  'dizah',
  'dizao',
  'dizat',
  'dižem',
  'dizel',
  'dizna',
  'dizne',
  'dizni',
  'dizno',
  'diznu',
  'djeci',
  'djeco',
  'djeda',
  'djede',
  'djedu',
  'djedu',
  'djela',
  'djelo',
  'djelu',
  'djene',
  'djeni',
  'djenu',
  'djeva',
  'djeve',
  'djevi',
  'djevo',
  'djevu',
  'dlaci',
  'dlaka',
  'dlake',
  'dlako',
  'dlaku',
  'dlana',
  'dlane',
  'dlanu',
  'dobom',
  'dobit',
  'dobih',
  'dobij',
  'dobio',
  'dobiv',
  'dobna',
  'dobne',
  'dobni',
  'dobno',
  'dobnu',
  'doboj',
  'doboo',
  'doboš',
  'dobuj',
  'dobar',
  'dobra',
  'dobre',
  'dobri',
  'dobro',
  'dobru',
  'dobro',
  'dobro',
  'dočom',
  'doček',
  'dočeh',
  'dočeo',
  'dočet',
  'dočne',
  'dočni',
  'dočnu',
  'dočim',
  'dočuh',
  'dočuj',
  'dočuo',
  'dočut',
  'dođem',
  'dođeš',
  'dođoh',
  'došao',
  'došav',
  'došla',
  'došle',
  'došli',
  'došlo',
  'dodan',
  'dodna',
  'dodat',
  'dodah',
  'dodaj',
  'dodam',
  'dodao',
  'dodaš',
  'dodat',
  'dodav',
  'dodig',
  'dodik',
  'dodir',
  'dodre',
  'dodri',
  'dodro',
  'dodru',
  'dogom',
  'dogom',
  'dogma',
  'dogme',
  'dogmi',
  'dogmo',
  'dogmu',
  'dogna',
  'dogon',
  'doima',
  'dojam',
  'dojma',
  'dojme',
  'dojmu',
  'dojen',
  'dojah',
  'dojim',
  'dojio',
  'dojiš',
  'dojit',
  'dojci',
  'dojka',
  'dojke',
  'dojki',
  'dojko',
  'dojku',
  'dojme',
  'dojmi',
  'dojna',
  'dojni',
  'dojdi',
  'dojti',
  'dokom',
  'dokad',
  'dokaz',
  'doker',
  'dokle',
  'dokon',
  'doksa',
  'dokup',
  'dolom',
  'dolac',
  'dolca',
  'dolce',
  'dolci',
  'dolcu',
  'dolče',
  'dolap',
  'dolar',
  'dolet',
  'dolih',
  'dolij',
  'dolio',
  'dolit',
  'dolus',
  'dolje',
  'domom',
  'domar',
  'domet',
  'donom',
  'donat',
  'donde',
  'donio',
  'donle',
  'donor',
  'donos',
  'donja',
  'donje',
  'donji',
  'donju',
  'doora',
  'dopao',
  'dopao',
  'dopis',
  'dopro',
  'dopre',
  'dopri',
  'dopru',
  'dopun',
  'dorat',
  'doris',
  'dosad',
  'doseg',
  'dosje',
  'dosta',
  'dospe',
  'dospi',
  'dospu',
  'dosuh',
  'dosuo',
  'dosut',
  'došen',
  'dotom',
  'dotad',
  'dotle',
  'dotok',
  'doume',
  'doumi',
  'dovde',
  'doveo',
  'dovik',
  'dovle',
  'dovod',
  'dovoz',
  'dozom',
  'dozer',
  'doziv',
  'dozna',
  'dozni',
  'dozre',
  'dozru',
  'dozva',
  'drača',
  'drače',
  'drači',
  'draču',
  'drača',
  'drače',
  'drači',
  'drage',
  'dragu',
  'draža',
  'draže',
  'dražu',
  'draga',
  'dragi',
  'draga',
  'drama',
  'drame',
  'drami',
  'dramo',
  'dramu',
  'drapa',
  'drapu',
  'derem',
  'dereš',
  'drave',
  'draži',
  'draže',
  'draži',
  'draže',
  'draži',
  'dreče',
  'dreči',
  'dreče',
  'dreka',
  'dreku',
  'dreci',
  'dreka',
  'dreke',
  'dreki',
  'dreko',
  'dreku',
  'drena',
  'dresa',
  'dresu',
  'drhav',
  'drhće',
  'drhći',
  'drhću',
  'drhte',
  'drhti',
  'dršće',
  'drhte',
  'drift',
  'drilu',
  'drimo',
  'drkah',
  'drkaj',
  'drkam',
  'drkao',
  'drkaš',
  'drkat',
  'drljo',
  'drlja',
  'drmam',
  'drman',
  'drmao',
  'drmaš',
  'drmat',
  'drmeš',
  'drmne',
  'drmni',
  'drmnu',
  'drnda',
  'droba',
  'drobe',
  'drobu',
  'drobi',
  'droca',
  'droce',
  'droci',
  'droco',
  'drocu',
  'droga',
  'droge',
  'drogi',
  'drogo',
  'drogu',
  'dropa',
  'drope',
  'drozd',
  'drpan',
  'drpah',
  'drpaj',
  'drpam',
  'drpao',
  'drpaš',
  'drpat',
  'drpim',
  'drpio',
  'drpne',
  'drpni',
  'drpnu',
  'drska',
  'drske',
  'drski',
  'drsko',
  'drsku',
  'drsko',
  'dršću',
  'dršći',
  'dršci',
  'drška',
  'drške',
  'drški',
  'drško',
  'dršku',
  'druga',
  'drugu',
  'druzi',
  'druže',
  'drugu',
  'druga',
  'druge',
  'drugi',
  'drugo',
  'drugu',
  'drugo',
  'drugs',
  'druid',
  'druka',
  'druma',
  'drume',
  'drumu',
  'druza',
  'druže',
  'druži',
  'drvom',
  'drvan',
  'drvar',
  'drvca',
  'drvce',
  'drvcu',
  'drven',
  'drvim',
  'drvna',
  'drvne',
  'drvni',
  'drvno',
  'drvnu',
  'drzak',
  'drzne',
  'drzni',
  'drznu',
  'držač',
  'dršče',
  'držak',
  'držan',
  'držah',
  'držao',
  'držat',
  'držim',
  'držiš',
  'držih',
  'držić',
  'duala',
  'duale',
  'duali',
  'dualu',
  'dubač',
  'dubak',
  'dubao',
  'duben',
  'dubin',
  'dubim',
  'dubio',
  'dubiš',
  'dubit',
  'dubok',
  'dubov',
  'dućan',
  'dućna',
  'dudom',
  'dudan',
  'dudah',
  'dudaj',
  'dudam',
  'dudao',
  'dudaš',
  'dudat',
  'dudov',
  'duduk',
  'duela',
  'duele',
  'dueli',
  'duelu',
  'dueta',
  'duete',
  'dueti',
  'duetu',
  'dugom',
  'dugom',
  'dugih',
  'dugim',
  'dugin',
  'dugog',
  'dugoj',
  'dugme',
  'duguj',
  'duhom',
  'duhač',
  'duhan',
  'duhah',
  'duhao',
  'duhat',
  'duhne',
  'duhni',
  'duhnu',
  'dukat',
  'dulek',
  'dulum',
  'dulju',
  'dulja',
  'dulje',
  'dulji',
  'dunda',
  'dunde',
  'dundi',
  'dundo',
  'dundu',
  'dunne',
  'dunja',
  'dunje',
  'dunji',
  'dunjo',
  'dunju',
  'dupin',
  'dupke',
  'dupla',
  'duple',
  'dupli',
  'duplo',
  'duplu',
  'duplo',
  'dubem',
  'dubla',
  'duble',
  'dubli',
  'dublo',
  'durah',
  'duraj',
  'duram',
  'durao',
  'duraš',
  'durat',
  'durah',
  'durim',
  'durio',
  'duriš',
  'durit',
  'durum',
  'dusom',
  'dušom',
  'dušak',
  'dušan',
  'dušna',
  'dušni',
  'dušnu',
  'dušek',
  'dušik',
  'duših',
  'dušim',
  'duška',
  'duško',
  'dutch',
  'duvao',
  'dužan',
  'dužna',
  'dužne',
  'dužni',
  'dužno',
  'dužnu',
  'dužda',
  'dužde',
  'duždi',
  'duždu',
  'dužda',
  'dužeg',
  'dužem',
  'dužih',
  'dužim',
  'dužoj',
  'dužom',
  'dužne',
  'dužno',
  'dužnu',
  'dvaju',
  'dvama',
  'dvera',
  'dvere',
  'dveri',
  'dvica',
  'dvice',
  'dvici',
  'dvico',
  'dvicu',
  'dvije',
  'dviju',
  'dvoju',
  'dvoje',
  'dvoma',
  'dvome',
  'dvoja',
  'dvoji',
  'dvoji',
  'dvora',
  'dvore',
  'dvoru',
  'dvore',
  'dvori',
  'džabe',
  'džače',
  'džaka',
  'džaku',
  'džema',
  'džemu',
  'džepa',
  'džepe',
  'džepu',
  'džeza',
  'džezu',
  'džina',
  'džins',
  'džipa',
  'džipe',
  'džipu',
  'džula',
  'đačić',
  'đačka',
  'đačke',
  'đački',
  'đačko',
  'đačku',
  'đakom',
  'đakon',
  'đakuj',
  'đavao',
  'đavla',
  'đavle',
  'đavli',
  'đavlu',
  'đavla',
  'đavle',
  'đavlu',
  'đavol',
  'đeram',
  'đerma',
  'đerme',
  'đermi',
  'đermu',
  'đermo',
  'đipah',
  'đipaj',
  'đipam',
  'đipao',
  'đipaš',
  'đipat',
  'đipne',
  'đipni',
  'đipnu',
  'đirem',
  'đorom',
  'đoran',
  'đorah',
  'đoraj',
  'đoram',
  'đorao',
  'đoraš',
  'đorat',
  'đorđe',
  'đorne',
  'đorni',
  'đornu',
  'đubar',
  'đubra',
  'đubru',
  'đubre',
  'đubri',
  'đukić',
  'đulom',
  'đuveč',
  'đuzel',
  'edema',
  'edeme',
  'edemi',
  'edemu',
  'edikt',
  'efeem',
  'efekt',
  'efrit',
  'egida',
  'eglon',
  'egzil',
  'ehima',
  'eidos',
  'ekcem',
  'ekipa',
  'ekipe',
  'ekipi',
  'ekipo',
  'ekipu',
  'ekran',
  'ekron',
  'eksaj',
  'ekser',
  'ektim',
  'elana',
  'elane',
  'elani',
  'elanu',
  'elbit',
  'elena',
  'elisa',
  'elise',
  'elisi',
  'eliso',
  'elisu',
  'elita',
  'elite',
  'eliti',
  'elito',
  'elitu',
  'eliti',
  'eluat',
  'elvis',
  'email',
  'emajl',
  'emali',
  'emira',
  'emire',
  'emiri',
  'emiru',
  'emske',
  'emski',
  'endem',
  'eneja',
  'engel',
  'engle',
  'enoli',
  'enoze',
  'enzim',
  'eocen',
  'eolna',
  'eolne',
  'eolni',
  'eolno',
  'eolnu',
  'eonom',
  'epova',
  'epove',
  'epovi',
  'eparh',
  'epici',
  'epiče',
  'epika',
  'epike',
  'epiku',
  'epici',
  'epika',
  'epike',
  'epiko',
  'epiku',
  'epira',
  'epoha',
  'epohe',
  'epohi',
  'epoho',
  'epohu',
  'eposi',
  'eprom',
  'epska',
  'epske',
  'epski',
  'epsko',
  'epsku',
  'erama',
  'erara',
  'erare',
  'erari',
  'eraru',
  'erbij',
  'erceg',
  'erica',
  'ericu',
  'erker',
  'ernst',
  'esloj',
  'eseja',
  'eseje',
  'eseji',
  'eseju',
  'eshop',
  'esker',
  'esnaf',
  'ester',
  'estet',
  'etama',
  'etana',
  'etane',
  'etani',
  'etanu',
  'etapa',
  'etape',
  'etapi',
  'etapo',
  'etapu',
  'etaža',
  'etaže',
  'etaži',
  'etažo',
  'etažu',
  'etena',
  'etera',
  'etere',
  'eteri',
  'eteru',
  'etida',
  'etide',
  'etidi',
  'etido',
  'etidu',
  'etici',
  'etika',
  'etike',
  'etiko',
  'etiku',
  'etila',
  'etile',
  'etili',
  'etilu',
  'etnik',
  'etnos',
  'etosa',
  'etose',
  'etosi',
  'etosu',
  'etski',
  'eugen',
  'eunuh',
  'eurco',
  'eures',
  'eurom',
  'europ',
  'evala',
  'evski',
  'facom',
  'fačuk',
  'faćka',
  'fagot',
  'fahom',
  'fajda',
  'fajde',
  'fajdi',
  'fajdo',
  'fajdu',
  'fajla',
  'fakat',
  'fakin',
  'fakir',
  'faksa',
  'fakti',
  'falco',
  'falda',
  'falde',
  'faldi',
  'faldo',
  'faldu',
  'falio',
  'falta',
  'falte',
  'falti',
  'falto',
  'faltu',
  'falus',
  'famom',
  'fanom',
  'farad',
  'farba',
  'farbe',
  'farbi',
  'farbo',
  'farbu',
  'farma',
  'farme',
  'farmi',
  'farmo',
  'farmu',
  'farsa',
  'farse',
  'farsi',
  'farso',
  'farsu',
  'farsi',
  'fatos',
  'fatum',
  'faula',
  'faule',
  'faulu',
  'faunu',
  'fauna',
  'faune',
  'fauni',
  'fauno',
  'faunu',
  'faust',
  'fazom',
  'fazan',
  'fazna',
  'fazne',
  'fazni',
  'fazno',
  'faznu',
  'fazno',
  'fazor',
  'febra',
  'febus',
  'feces',
  'feder',
  'fehta',
  'felom',
  'feler',
  'felga',
  'felge',
  'felgi',
  'felgo',
  'felgu',
  'fenom',
  'fenig',
  'fenil',
  'fenol',
  'feral',
  'ferdo',
  'ferit',
  'ferma',
  'fesom',
  'fešta',
  'fešte',
  'fešti',
  'fešto',
  'feštu',
  'fešte',
  'fetiš',
  'fetus',
  'fetva',
  'fetve',
  'fetvi',
  'fetvo',
  'fetvu',
  'feuda',
  'feude',
  'feudi',
  'feudu',
  'fever',
  'fiber',
  'fibra',
  'fibre',
  'fićuk',
  'figom',
  'fijuk',
  'fikus',
  'filom',
  'filan',
  'filah',
  'filaj',
  'filam',
  'filao',
  'filaš',
  'filat',
  'filca',
  'filea',
  'filei',
  'filek',
  'filer',
  'files',
  'filet',
  'filir',
  'filit',
  'filma',
  'filme',
  'filmi',
  'filmu',
  'finih',
  'finim',
  'finog',
  'finoj',
  'finom',
  'final',
  'finiš',
  'finta',
  'finte',
  'finti',
  'finto',
  'fintu',
  'firer',
  'firma',
  'firme',
  'firmi',
  'firmo',
  'firmu',
  'first',
  'fišek',
  'fjaci',
  'fjaka',
  'fjake',
  'fjaki',
  'fjako',
  'fjaku',
  'fjord',
  'flame',
  'flash',
  'flaša',
  'flaše',
  'flaši',
  'flašo',
  'flašu',
  'fleci',
  'fleka',
  'fleke',
  'fleki',
  'fleko',
  'fleku',
  'flert',
  'fleša',
  'flešu',
  'flint',
  'floem',
  'floks',
  'flori',
  'floru',
  'flora',
  'flore',
  'flota',
  'flote',
  'floti',
  'floto',
  'flotu',
  'fluid',
  'fluks',
  'fluor',
  'foaje',
  'fokom',
  'fokus',
  'folat',
  'folna',
  'folne',
  'folni',
  'folno',
  'folnu',
  'fonda',
  'fonde',
  'fondu',
  'fondi',
  'fonem',
  'fonet',
  'fonon',
  'fonta',
  'forom',
  'forca',
  'force',
  'force',
  'forda',
  'forma',
  'forme',
  'formi',
  'formo',
  'formu',
  'forte',
  'forum',
  'fosil',
  'foski',
  'foton',
  'frače',
  'fraka',
  'frake',
  'fraku',
  'frane',
  'frank',
  'franz',
  'frape',
  'fraza',
  'fraze',
  'frazi',
  'frazo',
  'frazu',
  'fraze',
  'frcah',
  'frcaj',
  'frcam',
  'frcao',
  'frcaš',
  'frcat',
  'frčci',
  'frčka',
  'frčke',
  'frčki',
  'frčko',
  'frčku',
  'fredu',
  'freon',
  'freza',
  'friga',
  'frita',
  'frkom',
  'frkan',
  'frkah',
  'frkao',
  'frkat',
  'frkći',
  'frkne',
  'frkni',
  'frknu',
  'frkće',
  'frkći',
  'frkću',
  'frula',
  'frule',
  'fruli',
  'frulo',
  'frulu',
  'fucaj',
  'fućka',
  'fufom',
  'fufla',
  'fugom',
  'fumić',
  'funka',
  'funta',
  'funte',
  'funti',
  'funto',
  'funtu',
  'furan',
  'furen',
  'furah',
  'furim',
  'furio',
  'furiš',
  'furit',
  'furci',
  'furka',
  'furke',
  'furki',
  'furko',
  'furku',
  'fuski',
  'fušer',
  'futur',
  'gabro',
  'gacah',
  'gacaj',
  'gacam',
  'gacao',
  'gacaš',
  'gacat',
  'gacke',
  'gačac',
  'gačca',
  'gačce',
  'gačci',
  'gačcu',
  'gaćan',
  'gadom',
  'gadan',
  'gadna',
  'gadne',
  'gadni',
  'gadno',
  'gadnu',
  'gadim',
  'gadio',
  'gadiš',
  'gadit',
  'gadno',
  'gađan',
  'gađah',
  'gađaj',
  'gađam',
  'gađao',
  'gađaš',
  'gađat',
  'gafom',
  'gajem',
  'gajba',
  'gajbe',
  'gajbi',
  'gajbo',
  'gajbu',
  'gajda',
  'gajde',
  'gajdi',
  'gajen',
  'gajić',
  'gajah',
  'gajim',
  'gajio',
  'gajiš',
  'gajit',
  'gajci',
  'gajka',
  'gajke',
  'gajki',
  'gajko',
  'gajku',
  'gakom',
  'gakah',
  'gakao',
  'gakaš',
  'gakat',
  'galom',
  'galeb',
  'galga',
  'galge',
  'galgi',
  'galij',
  'galon',
  'galop',
  'gamom',
  'gamad',
  'ganih',
  'ganga',
  'gange',
  'gangi',
  'gangu',
  'ganit',
  'ganut',
  'ganem',
  'ganeš',
  'ganuh',
  'ganuo',
  'ganut',
  'ganja',
  'ganja',
  'ganje',
  'garju',
  'garma',
  'garom',
  'garan',
  'garna',
  'garne',
  'garni',
  'garno',
  'garnu',
  'garav',
  'garda',
  'garde',
  'gardu',
  'garda',
  'garde',
  'gardi',
  'gardo',
  'gardu',
  'garen',
  'garež',
  'garah',
  'garim',
  'gario',
  'gariš',
  'garit',
  'garov',
  'gasom',
  'gasim',
  'gasio',
  'gasiš',
  'gasit',
  'gasna',
  'gasni',
  'gasno',
  'gasne',
  'gasni',
  'gasnu',
  'gašen',
  'gatom',
  'gatač',
  'gatah',
  'gataj',
  'gatam',
  'gatao',
  'gataš',
  'gatat',
  'gater',
  'gatka',
  'gatke',
  'gatki',
  'gatko',
  'gatku',
  'gauda',
  'gaude',
  'gaudi',
  'gaudo',
  'gaudu',
  'gauss',
  'gavan',
  'gavez',
  'gavka',
  'gavun',
  'gazom',
  'gazom',
  'gazda',
  'gazde',
  'gazdi',
  'gazdo',
  'gazdu',
  'gazim',
  'gazio',
  'gaziš',
  'gazit',
  'gazne',
  'gazni',
  'gažen',
  'gđica',
  'gedža',
  'gedže',
  'gedži',
  'gedžo',
  'gedžu',
  'gegom',
  'gegah',
  'gegaj',
  'gegam',
  'gegao',
  'gegaš',
  'gegat',
  'gegav',
  'gejša',
  'gejše',
  'gejši',
  'gejšo',
  'gejšu',
  'gekon',
  'gelom',
  'geler',
  'gelna',
  'gemom',
  'genom',
  'genet',
  'genij',
  'genom',
  'genov',
  'geoff',
  'geoid',
  'gepek',
  'gerla',
  'gerle',
  'gerli',
  'gerlo',
  'gerlu',
  'germa',
  'germe',
  'germi',
  'germo',
  'germu',
  'gesla',
  'geslo',
  'geslu',
  'gesta',
  'geste',
  'gesti',
  'gesto',
  'gestu',
  'gešur',
  'getom',
  'gibak',
  'gipka',
  'gipke',
  'gipko',
  'gipku',
  'giban',
  'gibah',
  'gibaj',
  'gibam',
  'gibao',
  'gibaš',
  'gibat',
  'gibon',
  'gicom',
  'gicah',
  'gicaj',
  'gicam',
  'gicao',
  'gicaš',
  'gicat',
  'gihta',
  'gihte',
  'gihtu',
  'ginka',
  'ginko',
  'ginem',
  'gineš',
  'ginuo',
  'ginut',
  'gipki',
  'gipko',
  'gipsa',
  'gipsu',
  'girom',
  'giros',
  'girus',
  'gista',
  'gizda',
  'gizde',
  'gizdi',
  'gizdo',
  'gizdu',
  'gizda',
  'gjana',
  'glacu',
  'glača',
  'gladi',
  'glađu',
  'glađa',
  'glađe',
  'glađi',
  'glade',
  'gladi',
  'glađe',
  'glans',
  'glasa',
  'glasu',
  'glasa',
  'glase',
  'glasi',
  'glava',
  'glave',
  'glavi',
  'glavo',
  'glavu',
  'gleda',
  'glede',
  'gleđi',
  'gleđu',
  'gleđa',
  'gleđe',
  'gleđu',
  'glenn',
  'gliba',
  'glibe',
  'glibu',
  'glina',
  'gline',
  'glini',
  'glino',
  'glinu',
  'gliom',
  'globa',
  'globe',
  'globi',
  'globo',
  'globu',
  'globe',
  'globi',
  'glođe',
  'glođi',
  'glođu',
  'gloga',
  'glogu',
  'glože',
  'glosa',
  'glosi',
  'glosu',
  'gluha',
  'gluhe',
  'gluhu',
  'gluhi',
  'gluho',
  'gluma',
  'glume',
  'glumi',
  'glumu',
  'glume',
  'glumi',
  'gluon',
  'glupa',
  'glupu',
  'glupe',
  'glupi',
  'glupo',
  'glupe',
  'glupo',
  'gluši',
  'gmaza',
  'gmaze',
  'gmazu',
  'gmiže',
  'gmiži',
  'gmižu',
  'gmiža',
  'gnajs',
  'gnana',
  'gnani',
  'gnano',
  'gnoja',
  'gnoju',
  'gnoje',
  'gnoji',
  'gnoma',
  'gnome',
  'gnomi',
  'gnomu',
  'gnoma',
  'gnome',
  'gnomi',
  'gnomo',
  'gnomu',
  'gnuom',
  'gnusa',
  'gnusu',
  'gnuša',
  'gnjat',
  'gnjeo',
  'gnjev',
  'gnjil',
  'gnjil',
  'gnjio',
  'gnjij',
  'gnjio',
  'gnjiš',
  'gnjit',
  'gnjus',
  'godom',
  'godim',
  'godio',
  'godiš',
  'godit',
  'goduj',
  'gofom',
  'gojan',
  'gojnu',
  'gojen',
  'gojoj',
  'gojah',
  'gojim',
  'gojio',
  'gojiš',
  'gojit',
  'gojka',
  'gojko',
  'gojna',
  'gojni',
  'golom',
  'golać',
  'golan',
  'golem',
  'golet',
  'golfa',
  'golfe',
  'golfu',
  'golgi',
  'golih',
  'golim',
  'goloj',
  'golog',
  'golub',
  'golja',
  'golje',
  'golji',
  'goljo',
  'golju',
  'gomba',
  'gonom',
  'gonga',
  'gongu',
  'gonže',
  'gonič',
  'gonim',
  'gonio',
  'goniš',
  'gonit',
  'gonja',
  'gorom',
  'gorač',
  'gorak',
  'gorke',
  'gorku',
  'gorča',
  'gorče',
  'gorči',
  'gorda',
  'gorde',
  'gordi',
  'gordo',
  'gordu',
  'gorde',
  'gordi',
  'goren',
  'goreg',
  'gorem',
  'gorih',
  'gorim',
  'goroj',
  'gorom',
  'goriv',
  'gorja',
  'gorje',
  'gorju',
  'gorah',
  'gorio',
  'goriš',
  'gorču',
  'gorka',
  'gorki',
  'gorko',
  'goruć',
  'gospa',
  'gospe',
  'gospi',
  'gospo',
  'gospu',
  'gosta',
  'goste',
  'gosti',
  'gostu',
  'goste',
  'gosti',
  'gošća',
  'gošće',
  'gošći',
  'gošćo',
  'gošću',
  'gošen',
  'gotov',
  'gouda',
  'gould',
  'govni',
  'govna',
  'govno',
  'govnu',
  'govor',
  'gozba',
  'gozbe',
  'gozbi',
  'gozbo',
  'gozbu',
  'grabu',
  'graba',
  'grabe',
  'grabi',
  'grabo',
  'grabu',
  'grabe',
  'grace',
  'grada',
  'gradu',
  'grade',
  'gradi',
  'građa',
  'građe',
  'građi',
  'građo',
  'građu',
  'grafa',
  'grafe',
  'grafu',
  'graft',
  'graha',
  'grahu',
  'graše',
  'grahi',
  'graja',
  'graje',
  'graji',
  'grajo',
  'graju',
  'grajk',
  'grače',
  'grači',
  'graču',
  'grama',
  'grame',
  'grami',
  'gramu',
  'grana',
  'grane',
  'grani',
  'grano',
  'granu',
  'grand',
  'grane',
  'grani',
  'granu',
  'grapa',
  'grbom',
  'grbav',
  'grbim',
  'grbio',
  'grbiš',
  'grbit',
  'grcaj',
  'grcah',
  'grcaj',
  'grcam',
  'grcao',
  'grcaš',
  'grcat',
  'grcne',
  'grcni',
  'grcnu',
  'grčem',
  'grčah',
  'grčim',
  'grčio',
  'grčiš',
  'grčit',
  'grčka',
  'grčke',
  'grčki',
  'grčko',
  'grčku',
  'grdoj',
  'grdom',
  'grdan',
  'grdne',
  'grdih',
  'grdim',
  'grdio',
  'grdiš',
  'grdit',
  'grdni',
  'grdno',
  'grđom',
  'grđem',
  'grđen',
  'grđih',
  'grđim',
  'grđoj',
  'grebe',
  'greda',
  'grede',
  'gredi',
  'gredo',
  'gredu',
  'greja',
  'greje',
  'greji',
  'greju',
  'grejp',
  'grebi',
  'grebu',
  'grgeč',
  'grgut',
  'griča',
  'griče',
  'griči',
  'griču',
  'grida',
  'gridu',
  'grije',
  'griju',
  'grila',
  'grile',
  'grilu',
  'gripa',
  'gripe',
  'gripi',
  'gripu',
  'gripe',
  'grist',
  'grize',
  'grizi',
  'grizu',
  'griva',
  'grive',
  'grivi',
  'grivo',
  'grivu',
  'griža',
  'griže',
  'griži',
  'grižo',
  'grižu',
  'grkim',
  'grkom',
  'grkov',
  'grlom',
  'grlac',
  'grlaš',
  'grlat',
  'grlca',
  'grlce',
  'grlcu',
  'grlen',
  'grlić',
  'grlim',
  'grlio',
  'grliš',
  'grlit',
  'grmom',
  'grmak',
  'grmci',
  'grmče',
  'grmka',
  'grmke',
  'grmku',
  'grmim',
  'grmio',
  'grmiš',
  'grmne',
  'grmni',
  'grmnu',
  'grnac',
  'grnca',
  'grnce',
  'grnci',
  'grncu',
  'grnče',
  'grnim',
  'grnom',
  'grnem',
  'grneš',
  'grnuh',
  'grnuo',
  'grnut',
  'groom',
  'groba',
  'grobe',
  'grobi',
  'grobu',
  'grofa',
  'grofe',
  'grofi',
  'grofu',
  'groga',
  'grogu',
  'grože',
  'groma',
  'grome',
  'gromi',
  'gromu',
  'groša',
  'groše',
  'groši',
  'grošu',
  'groši',
  'grota',
  'groti',
  'grote',
  'groza',
  'groze',
  'grozi',
  'grozo',
  'grozu',
  'grozd',
  'groze',
  'grozi',
  'gruba',
  'grube',
  'grubo',
  'grubu',
  'grubi',
  'grube',
  'grubo',
  'gruda',
  'grude',
  'grudo',
  'grudu',
  'gruda',
  'grudi',
  'gruha',
  'grunt',
  'grune',
  'gruni',
  'grunu',
  'grupa',
  'grupe',
  'grupi',
  'grupo',
  'grupu',
  'grupo',
  'gruša',
  'gruva',
  'guava',
  'guave',
  'gubom',
  'gubac',
  'gupca',
  'gupce',
  'gupci',
  'gubar',
  'gubav',
  'gubec',
  'guber',
  'gubim',
  'gubio',
  'gubiš',
  'gubit',
  'gucić',
  'gucne',
  'gucni',
  'gucnu',
  'gučom',
  'gudač',
  'gudim',
  'gudio',
  'gudiš',
  'gugut',
  'gujom',
  'gujav',
  'gučeš',
  'gukah',
  'gukan',
  'gukao',
  'gukat',
  'guknu',
  'gulaš',
  'gulim',
  'gulio',
  'guliš',
  'gulit',
  'gumom',
  'gumba',
  'gumbe',
  'gumbi',
  'gumbu',
  'gumbe',
  'gumbi',
  'gumen',
  'gumna',
  'gumno',
  'gumnu',
  'gunđa',
  'gunja',
  'gunju',
  'gunja',
  'gupče',
  'gurač',
  'guraj',
  'guram',
  'guran',
  'gurao',
  'guraš',
  'gurat',
  'gurav',
  'gurka',
  'gurne',
  'gurni',
  'gurnu',
  'gurta',
  'gurtu',
  'gurua',
  'gurue',
  'gurui',
  'guruu',
  'gusak',
  'gusan',
  'gusar',
  'gusav',
  'gusin',
  'gusja',
  'gusji',
  'gusci',
  'guska',
  'guske',
  'guski',
  'gusko',
  'gusku',
  'gusla',
  'gusle',
  'gusli',
  'gusta',
  'guste',
  'gusti',
  'gustu',
  'gušće',
  'gusto',
  'gusto',
  'gušom',
  'gušav',
  'gušče',
  'gušća',
  'gušći',
  'gušću',
  'gušeć',
  'gušen',
  'gušah',
  'gušim',
  'gušio',
  'gušiš',
  'gušit',
  'gutač',
  'gutan',
  'gutne',
  'gutni',
  'gutnu',
  'gutah',
  'gutaj',
  'gutam',
  'gutao',
  'gutaš',
  'gutat',
  'guzom',
  'gužva',
  'gužve',
  'gužvi',
  'gužvo',
  'gužvu',
  'gvaša',
  'gvašu',
  'gverc',
  'gviri',
  'gvozd',
  'haban',
  'habah',
  'habaj',
  'habam',
  'habao',
  'habaš',
  'habat',
  'habit',
  'hadža',
  'hadži',
  'hadžu',
  'hahar',
  'hajah',
  'hajao',
  'hajat',
  'hajem',
  'haješ',
  'hajmo',
  'hajte',
  'hajde',
  'hajci',
  'hajka',
  'hajke',
  'hajki',
  'hajko',
  'hajku',
  'haker',
  'halom',
  'halfa',
  'halfe',
  'halfu',
  'halit',
  'halma',
  'halou',
  'halva',
  'halve',
  'halvi',
  'halvo',
  'halvu',
  'halja',
  'halje',
  'halji',
  'haljo',
  'halju',
  'hamom',
  'hamas',
  'hamih',
  'hamov',
  'hanom',
  'hanks',
  'hansa',
  'hansi',
  'hapsa',
  'hapse',
  'hapsu',
  'hapse',
  'hapsi',
  'harač',
  'haran',
  'harna',
  'harne',
  'harni',
  'harno',
  'harnu',
  'harah',
  'haraj',
  'haram',
  'harao',
  'haraš',
  'harat',
  'harem',
  'harfa',
  'harfe',
  'harfi',
  'harfo',
  'harfu',
  'harsh',
  'harta',
  'harte',
  'hasan',
  'hasij',
  'hašiš',
  'haška',
  'haške',
  'haški',
  'haško',
  'hašku',
  'hauba',
  'haube',
  'haubi',
  'haubo',
  'haubu',
  'hegel',
  'hekla',
  'hekto',
  'helij',
  'henin',
  'henri',
  'herca',
  'herce',
  'hercu',
  'herim',
  'heroj',
  'hertz',
  'hicih',
  'hidra',
  'hidre',
  'hidri',
  'hidro',
  'hidru',
  'hidro',
  'higgs',
  'hihot',
  'hijat',
  'hilus',
  'himba',
  'himen',
  'himna',
  'himne',
  'himni',
  'himno',
  'himnu',
  'himus',
  'hindi',
  'hindu',
  'hinim',
  'hinio',
  'hiniš',
  'hinit',
  'hinko',
  'hipom',
  'hiper',
  'hirom',
  'hiram',
  'hitom',
  'hicem',
  'hitac',
  'hitca',
  'hitce',
  'hitci',
  'hitcu',
  'hitče',
  'hitan',
  'hitna',
  'hitni',
  'hitno',
  'hitar',
  'hitra',
  'hitre',
  'hitri',
  'hitro',
  'hitru',
  'hitin',
  'hitim',
  'hitio',
  'hitiš',
  'hitit',
  'hitne',
  'hitnu',
  'hitno',
  'hitne',
  'hitni',
  'hitnu',
  'hitro',
  'hivom',
  'hlačo',
  'hlača',
  'hlače',
  'hlada',
  'hlade',
  'hladu',
  'hlade',
  'hladi',
  'hlapa',
  'hlapu',
  'hlapi',
  'hlape',
  'hlapi',
  'hljeb',
  'hmelj',
  'hoana',
  'hoane',
  'hoćim',
  'hodom',
  'hodač',
  'hodak',
  'hodah',
  'hodaj',
  'hodam',
  'hodao',
  'hodaš',
  'hodat',
  'hođah',
  'hodim',
  'hodio',
  'hodiš',
  'hodit',
  'hodna',
  'hodne',
  'hodni',
  'hodno',
  'hodnu',
  'hodža',
  'hodže',
  'hodži',
  'hodžo',
  'hodžu',
  'hokej',
  'hokus',
  'homić',
  'honda',
  'hopše',
  'hopši',
  'hopšu',
  'horom',
  'hordi',
  'hordu',
  'horda',
  'horde',
  'horor',
  'hosta',
  'hotel',
  'hotje',
  'hrima',
  'hrama',
  'hrame',
  'hrami',
  'hramu',
  'hrama',
  'hrana',
  'hrane',
  'hrani',
  'hrano',
  'hranu',
  'hrane',
  'hrani',
  'hranj',
  'hrast',
  'hrbat',
  'hrpta',
  'hrpti',
  'hrptu',
  'hrčak',
  'hrčci',
  'hrčka',
  'hrčke',
  'hrčku',
  'hrdan',
  'hrdah',
  'hrdaj',
  'hrdam',
  'hrdao',
  'hrdaš',
  'hrdat',
  'hrđom',
  'hrđah',
  'hrđaj',
  'hrđam',
  'hrđao',
  'hrđaš',
  'hrđat',
  'hrđav',
  'hrena',
  'hrene',
  'hrenu',
  'hrgav',
  'hridi',
  'hridu',
  'hriđu',
  'hride',
  'hripa',
  'hrkač',
  'hrčem',
  'hrkah',
  'hrkao',
  'hrkat',
  'hrkec',
  'hrlec',
  'hrlim',
  'hrlio',
  'hrliš',
  'hrlit',
  'hrman',
  'hroma',
  'hrome',
  'hromi',
  'hromu',
  'hromo',
  'hrpom',
  'hrska',
  'hrtom',
  'hrtih',
  'hrušt',
  'hrvom',
  'hrvač',
  'hrvam',
  'hrzaj',
  'hrzao',
  'hsdpa',
  'hoćeš',
  'htjeh',
  'htjet',
  'htjev',
  'hucka',
  'hučom',
  'hučan',
  'hučna',
  'hučne',
  'hučni',
  'hučno',
  'hučnu',
  'hučah',
  'hučao',
  'hučat',
  'hučim',
  'hučiš',
  'hudih',
  'hudim',
  'hudoj',
  'hudom',
  'hujah',
  'hujao',
  'hujat',
  'hujim',
  'hujiš',
  'hukom',
  'hukom',
  'hučeš',
  'hukah',
  'hukao',
  'hukat',
  'hukne',
  'hukni',
  'huknu',
  'hulim',
  'hulio',
  'huliš',
  'hulit',
  'hulja',
  'hulje',
  'hulji',
  'huljo',
  'hulju',
  'humca',
  'humak',
  'humci',
  'humče',
  'humka',
  'humke',
  'humku',
  'human',
  'humih',
  'humim',
  'humka',
  'humke',
  'humki',
  'humko',
  'humku',
  'humor',
  'humus',
  'hunta',
  'hunte',
  'hunti',
  'hunto',
  'huntu',
  'husar',
  'husit',
  'huski',
  'huška',
  'hvala',
  'hvale',
  'hvali',
  'hvalo',
  'hvalu',
  'hvale',
  'hvali',
  'hvata',
  'hvate',
  'hvati',
  'hvatu',
  'hvata',
  'ibisa',
  'ibise',
  'ibisi',
  'ibisu',
  'ibize',
  'ibrik',
  'ičija',
  'ičije',
  'ičiji',
  'ičijo',
  'ičije',
  'ičiju',
  'idemo',
  'idete',
  'idite',
  'idima',
  'ideal',
  'ideja',
  'ideje',
  'ideji',
  'idejo',
  'ideju',
  'ident',
  'idila',
  'idile',
  'idili',
  'idilo',
  'idilu',
  'idiom',
  'idiot',
  'idimo',
  'iđahu',
  'iđaše',
  'idola',
  'idole',
  'idoli',
  'idolu',
  'iduća',
  'iduće',
  'idući',
  'iduću',
  'iftar',
  'igalu',
  'igloj',
  'igati',
  'igdje',
  'igala',
  'iglom',
  'iglua',
  'iglue',
  'iglui',
  'igluu',
  'iglun',
  'igora',
  'igoru',
  'igara',
  'igrom',
  'igrač',
  'igran',
  'igrah',
  'igraj',
  'igram',
  'igrao',
  'igraš',
  'igrat',
  'igriv',
  'ikada',
  'ikako',
  'ikamo',
  'ikoga',
  'ikoja',
  'ikoje',
  'ikoji',
  'ikoju',
  'ikome',
  'ikomu',
  'ikoma',
  'ikona',
  'ikone',
  'ikoni',
  'ikono',
  'ikonu',
  'ikone',
  'ikrom',
  'ikraš',
  'ikrih',
  'ilica',
  'ilice',
  'ilici',
  'ilicu',
  'ilidi',
  'iliev',
  'ilija',
  'ilije',
  'ilipe',
  'ilira',
  'imago',
  'imalo',
  'imama',
  'imame',
  'imami',
  'imamu',
  'imaoc',
  'imade',
  'imadu',
  'imahu',
  'imaju',
  'imala',
  'imale',
  'imali',
  'imalo',
  'imamo',
  'imana',
  'imani',
  'imaše',
  'imate',
  'imati',
  'imbus',
  'imena',
  'imenu',
  'imela',
  'imele',
  'imeli',
  'imelo',
  'imelu',
  'imele',
  'imeci',
  'imeče',
  'imida',
  'imidi',
  'imidž',
  'imina',
  'imini',
  'imuna',
  'imune',
  'imuno',
  'imunu',
  'imuni',
  'inače',
  'inata',
  'inate',
  'inati',
  'inatu',
  'inčem',
  'inčna',
  'inčni',
  'inčun',
  'inćun',
  'indij',
  'indok',
  'indol',
  'infra',
  'ingot',
  'inima',
  'inoga',
  'inina',
  'inine',
  'inker',
  'inoča',
  'inoču',
  'inoli',
  'input',
  'inska',
  'inski',
  'insku',
  'inter',
  'intim',
  'intra',
  'injem',
  'injim',
  'ionom',
  'irian',
  'irina',
  'irska',
  'irske',
  'irski',
  'irsko',
  'irsku',
  'irvas',
  'iscrt',
  'isele',
  'iseli',
  'ishod',
  'isisa',
  'isiše',
  'isišu',
  'isjek',
  'iskan',
  'iskah',
  'iskao',
  'iskat',
  'išćem',
  'išćeš',
  'iskaz',
  'isklj',
  'iskon',
  'iskop',
  'iskra',
  'iskre',
  'iskro',
  'iskru',
  'iskre',
  'iskri',
  'iskup',
  'ismij',
  'ispad',
  'ispao',
  'ispis',
  'ispit',
  'ispih',
  'ispij',
  'ispio',
  'ispiv',
  'ispne',
  'ispod',
  'ispra',
  'ispuh',
  'ispun',
  'istam',
  'istar',
  'istek',
  'istih',
  'istim',
  'istog',
  'istoj',
  'istom',
  'istin',
  'istka',
  'istok',
  'istom',
  'istru',
  'istrh',
  'istro',
  'istrt',
  'istup',
  'isuče',
  'isuču',
  'isuka',
  'isuše',
  'isuši',
  'isula',
  'isuli',
  'isuti',
  'išara',
  'išler',
  'ičega',
  'ičemu',
  'ičime',
  'ištem',
  'išteš',
  'ikoga',
  'itrij',
  'ivama',
  'ivera',
  'ivere',
  'iveri',
  'iveru',
  'ivica',
  'ivici',
  'ivini',
  'ivoni',
  'izaći',
  'izađe',
  'izađi',
  'izađu',
  'izasu',
  'izaže',
  'izbom',
  'izbih',
  'izbij',
  'izbio',
  'izbit',
  'izbiv',
  'izboj',
  'izbor',
  'izboo',
  'izbra',
  'izdah',
  'izdan',
  'izdam',
  'izdao',
  'izdaš',
  'izdat',
  'izdav',
  'izdaj',
  'izgib',
  'izgna',
  'izgon',
  'izići',
  'iziđe',
  'iziđi',
  'iziđu',
  'izuju',
  'izjeo',
  'izlaz',
  'izlet',
  'izlih',
  'izlij',
  'izlio',
  'izlit',
  'izliv',
  'izlog',
  'izlov',
  'izmak',
  'izmeo',
  'izmet',
  'izmir',
  'iznad',
  'iznio',
  'iznos',
  'izora',
  'izore',
  'izori',
  'izoru',
  'izraz',
  'izrez',
  'izrod',
  'izuče',
  'izuči',
  'izuma',
  'izumi',
  'izumu',
  'izume',
  'izumi',
  'izuta',
  'izuto',
  'izuje',
  'izula',
  'izuli',
  'izute',
  'izuti',
  'izuva',
  'izuze',
  'izvan',
  'izveo',
  'izvid',
  'izvih',
  'izvij',
  'izvio',
  'izvit',
  'izvod',
  'izvoj',
  'izvor',
  'izvoz',
  'ižinj',
  'ižive',
  'iživi',
  'mnome',
  'jačan',
  'jačah',
  'jačaj',
  'jačam',
  'jačao',
  'jačaš',
  'jačat',
  'jačeg',
  'jačem',
  'jačih',
  'jačim',
  'jačoj',
  'jačom',
  'jadom',
  'jadac',
  'jadan',
  'jadna',
  'jadne',
  'jadni',
  'jadno',
  'jadnu',
  'jadah',
  'jadaj',
  'jadam',
  'jadao',
  'jadaš',
  'jadat',
  'jadim',
  'jadio',
  'jadiš',
  'jadit',
  'jadni',
  'jadno',
  'jaduj',
  'jagma',
  'jagme',
  'jagmi',
  'jagmo',
  'jagmu',
  'jagme',
  'jagmi',
  'jahač',
  'jahan',
  'jahah',
  'jahao',
  'jahat',
  'jašem',
  'jašeš',
  'jahta',
  'jahte',
  'jahti',
  'jahto',
  'jahtu',
  'jahvu',
  'jajca',
  'jajcu',
  'jajar',
  'jajem',
  'jajna',
  'jajne',
  'jajni',
  'jajno',
  'jajnu',
  'jakih',
  'jakim',
  'jakoj',
  'jakom',
  'jakim',
  'jakog',
  'jakić',
  'jakna',
  'jakne',
  'jakni',
  'jakno',
  'jaknu',
  'jakša',
  'jalom',
  'jalan',
  'jalna',
  'jalne',
  'jalni',
  'jalno',
  'jalnu',
  'jalov',
  'jalša',
  'jamom',
  'jamac',
  'jamca',
  'jamce',
  'jamci',
  'jamcu',
  'jamče',
  'jamna',
  'jamni',
  'jamnu',
  'jamba',
  'jambe',
  'jambu',
  'jambi',
  'jambo',
  'jamče',
  'jamči',
  'jamie',
  'jamim',
  'jamra',
  'janaf',
  'janez',
  'janša',
  'janja',
  'janje',
  'janju',
  'janji',
  'jarac',
  'jarca',
  'jarce',
  'jarci',
  'jarcu',
  'jarče',
  'jarad',
  'jarak',
  'jarku',
  'jaram',
  'jarmu',
  'jaran',
  'jarda',
  'jarde',
  'jardi',
  'jardu',
  'jareb',
  'jarih',
  'jarim',
  'jarog',
  'jaroj',
  'jarom',
  'jarić',
  'jarka',
  'jarke',
  'jarki',
  'jarku',
  'jarko',
  'jarma',
  'jarme',
  'jarmi',
  'jarni',
  'jaruh',
  'jasan',
  'jasna',
  'jasne',
  'jasni',
  'jasnu',
  'jasen',
  'jaski',
  'jasla',
  'jasle',
  'jasli',
  'jasne',
  'jasna',
  'jasni',
  'jasno',
  'jason',
  'jatom',
  'jatak',
  'jatim',
  'jatio',
  'jatiš',
  'jatit',
  'jauci',
  'jauče',
  'jauka',
  'jauke',
  'jauku',
  'jauče',
  'jauči',
  'jauču',
  'javom',
  'javih',
  'javim',
  'javio',
  'javiš',
  'javit',
  'javan',
  'javna',
  'javne',
  'javni',
  'javno',
  'javnu',
  'javno',
  'javor',
  'jazom',
  'jazan',
  'jazna',
  'jazne',
  'jazni',
  'jazno',
  'jaznu',
  'jazza',
  'jeana',
  'jeans',
  'jebac',
  'jebač',
  'jeban',
  'jebah',
  'jebaj',
  'jebao',
  'jebat',
  'jebem',
  'jebeš',
  'jeben',
  'jecaj',
  'jecam',
  'jecah',
  'jecaj',
  'jecam',
  'jecao',
  'jecaš',
  'jecat',
  'jecav',
  'jecim',
  'jecne',
  'jecni',
  'jecnu',
  'ječam',
  'ječma',
  'ječme',
  'ječmu',
  'ječah',
  'ječan',
  'ječao',
  'ječat',
  'ječim',
  'ječiš',
  'ječni',
  'jedom',
  'jedak',
  'jetka',
  'jetke',
  'jedan',
  'jedna',
  'jedne',
  'jedan',
  'jedni',
  'jedno',
  'jednu',
  'jedra',
  'jedri',
  'jedro',
  'jedru',
  'jedem',
  'jedić',
  'jedar',
  'jedre',
  'jedra',
  'jedro',
  'jedru',
  'jedva',
  'jekom',
  'jekom',
  'jekne',
  'jekni',
  'jeknu',
  'jelac',
  'jelca',
  'jelce',
  'jelci',
  'jelcu',
  'jelče',
  'jelek',
  'jelen',
  'jelin',
  'jelka',
  'jelke',
  'jelki',
  'jelko',
  'jelku',
  'jelom',
  'jelov',
  'jemin',
  'jenin',
  'jenja',
  'jerbo',
  'jeren',
  'jerks',
  'jesen',
  'jesna',
  'jesne',
  'jesni',
  'jesno',
  'jesnu',
  'jesno',
  'jedeš',
  'jesti',
  'ješan',
  'ješna',
  'ješne',
  'ješni',
  'ješno',
  'ješnu',
  'ješci',
  'ješka',
  'ješke',
  'ješki',
  'ješko',
  'ješku',
  'jetku',
  'jetan',
  'jetka',
  'jetki',
  'jetko',
  'jetra',
  'jetre',
  'jetri',
  'jetru',
  'jetro',
  'jezom',
  'jezan',
  'jezna',
  'jezne',
  'jezni',
  'jezno',
  'jeznu',
  'jezda',
  'jezdu',
  'jezde',
  'jezdi',
  'jezer',
  'jezga',
  'jezik',
  'jeziv',
  'ježom',
  'ježac',
  'ježak',
  'ježev',
  'ježić',
  'ježah',
  'ježim',
  'ježio',
  'ježiš',
  'ježit',
  'ježur',
  'jiang',
  'jidiš',
  'jobov',
  'jodom',
  'jodid',
  'jodla',
  'jodna',
  'jodne',
  'jodni',
  'jodno',
  'jodnu',
  'jodnu',
  'jogom',
  'jogin',
  'johom',
  'johin',
  'johnu',
  'joint',
  'joker',
  'jolit',
  'joras',
  'jošte',
  'jovan',
  'jović',
  'jožef',
  'juana',
  'jučer',
  'judić',
  'judom',
  'jugom',
  'juhom',
  'juice',
  'julia',
  'julie',
  'julij',
  'juliu',
  'julka',
  'jumar',
  'jumbo',
  'junac',
  'junca',
  'junce',
  'junci',
  'juncu',
  'junak',
  'junom',
  'junij',
  'jurna',
  'jurca',
  'juriš',
  'jurah',
  'juren',
  'jurij',
  'jurim',
  'jurio',
  'juriš',
  'jurit',
  'jurne',
  'jurni',
  'jurnu',
  'jušan',
  'jušna',
  'jušne',
  'jušni',
  'jušno',
  'jušnu',
  'jutih',
  'jutom',
  'juten',
  'jutre',
  'jutri',
  'jutra',
  'jutro',
  'jutru',
  'južan',
  'južnu',
  'južah',
  'južen',
  'južim',
  'južio',
  'južiš',
  'južit',
  'južna',
  'južne',
  'južni',
  'južno',
  'južnu',
  'južno',
  'kabal',
  'kabao',
  'kabla',
  'kabel',
  'kable',
  'kablu',
  'kabul',
  'kacom',
  'kacim',
  'kačak',
  'kaćun',
  'kadom',
  'kadar',
  'kadra',
  'kadru',
  'kadet',
  'kadim',
  'kadio',
  'kadiš',
  'kadit',
  'kadri',
  'kadro',
  'kafar',
  'kafić',
  'kagan',
  'kaire',
  'kairu',
  'kaiša',
  'kaiše',
  'kaiši',
  'kaišu',
  'kajak',
  'kajah',
  'kajan',
  'kajao',
  'kajat',
  'kajem',
  'kaješ',
  'kajde',
  'kakaa',
  'kakao',
  'kakau',
  'kakav',
  'kakva',
  'kakve',
  'kakvi',
  'kakvo',
  'kakvu',
  'kakit',
  'kakim',
  'kakio',
  'kakiš',
  'kakit',
  'kakov',
  'kakav',
  'kalaj',
  'kalan',
  'kalah',
  'kalam',
  'kalao',
  'kalat',
  'kalav',
  'kaleb',
  'kalem',
  'kalež',
  'kalfa',
  'kalfe',
  'kalfi',
  'kalfo',
  'kalfu',
  'kalif',
  'kalij',
  'kalim',
  'kalio',
  'kališ',
  'kalit',
  'kalka',
  'kalku',
  'kalle',
  'kalom',
  'kalta',
  'kalup',
  'kalus',
  'kalju',
  'kalja',
  'kamom',
  'kamen',
  'kamin',
  'kamiš',
  'kampa',
  'kampe',
  'kampu',
  'kampa',
  'kamut',
  'kanom',
  'kanal',
  'kanap',
  'kanat',
  'kanda',
  'kanim',
  'kanio',
  'kaniš',
  'kanit',
  'kanon',
  'kanta',
  'kante',
  'kanti',
  'kanto',
  'kantu',
  'kanua',
  'kanue',
  'kanui',
  'kanuu',
  'kanuo',
  'kanut',
  'kanja',
  'kanje',
  'kaosa',
  'kaose',
  'kaosi',
  'kaosu',
  'kapom',
  'kapak',
  'kapci',
  'kapče',
  'kapka',
  'kapke',
  'kapku',
  'kapar',
  'kapah',
  'kapaj',
  'kapam',
  'kapao',
  'kapaš',
  'kapat',
  'kapav',
  'kapel',
  'kapki',
  'kapok',
  'kaput',
  'karan',
  'karar',
  'karas',
  'karat',
  'karah',
  'karaj',
  'karam',
  'karao',
  'karaš',
  'karde',
  'karea',
  'kareu',
  'karen',
  'karga',
  'kargo',
  'kargu',
  'karić',
  'karin',
  'karla',
  'karma',
  'karme',
  'karmi',
  'karmo',
  'karmu',
  'karta',
  'karte',
  'karti',
  'kartu',
  'kasom',
  'kasač',
  'kasan',
  'kasne',
  'kasno',
  'kasnu',
  'kasar',
  'kasah',
  'kasaj',
  'kasam',
  'kasao',
  'kasaš',
  'kasat',
  'kaska',
  'kasko',
  'kasna',
  'kasne',
  'kasni',
  'kasno',
  'kasta',
  'kaste',
  'kasti',
  'kasto',
  'kastu',
  'kašom',
  'kašev',
  'kašin',
  'kaših',
  'kašlj',
  'katom',
  'katan',
  'katna',
  'katnu',
  'katni',
  'katun',
  'kaube',
  'kaubu',
  'kauča',
  'kauče',
  'kauči',
  'kauču',
  'kauda',
  'kaula',
  'kaule',
  'kauli',
  'kaulu',
  'kavim',
  'kavoj',
  'kavom',
  'kavan',
  'kavez',
  'kavga',
  'kavge',
  'kavgi',
  'kavgo',
  'kavgu',
  'kavin',
  'kazan',
  'kazah',
  'kazao',
  'kazat',
  'kažem',
  'kažeš',
  'kazuj',
  'kazna',
  'kazne',
  'kazni',
  'kazno',
  'kaznu',
  'kažih',
  'kažim',
  'kćeri',
  'kebab',
  'kebaj',
  'kecom',
  'kečap',
  'kečke',
  'keder',
  'kefom',
  'kefir',
  'kegla',
  'kegle',
  'kegli',
  'keglo',
  'keglu',
  'kejom',
  'kejih',
  'keksa',
  'kekse',
  'keksi',
  'keksu',
  'kelat',
  'keleš',
  'kelta',
  'kelja',
  'kelje',
  'kelju',
  'kepec',
  'keper',
  'kerma',
  'kerme',
  'kerže',
  'kesom',
  'kesih',
  'kesim',
  'kesio',
  'kesiš',
  'keson',
  'kešem',
  'keton',
  'kevin',
  'kiang',
  'kibic',
  'kiblu',
  'kibuc',
  'kicoš',
  'kičem',
  'kičen',
  'kičma',
  'kičme',
  'kičmi',
  'kičmo',
  'kičmu',
  'kićen',
  'kidan',
  'kidah',
  'kidaj',
  'kidam',
  'kidao',
  'kidaš',
  'kidat',
  'kidne',
  'kifla',
  'kifle',
  'kifli',
  'kiflo',
  'kiflu',
  'kihah',
  'kihao',
  'kihat',
  'kišem',
  'kihne',
  'kihni',
  'kihnu',
  'kikim',
  'kikom',
  'kikaš',
  'kikot',
  'kiksa',
  'kiksu',
  'kilom',
  'kilav',
  'kimom',
  'kimah',
  'kimaj',
  'kimam',
  'kimao',
  'kimaš',
  'kimat',
  'kimim',
  'kimle',
  'kimli',
  'kimne',
  'kimni',
  'kimnu',
  'kimte',
  'kinih',
  'kinim',
  'kinoj',
  'kinin',
  'kinom',
  'kinoa',
  'kinon',
  'kinje',
  'kinji',
  'kiosk',
  'kipom',
  'kipar',
  'kiper',
  'kipić',
  'kipim',
  'kipio',
  'kipiš',
  'kipti',
  'kipuć',
  'kirin',
  'kiseo',
  'kisik',
  'kisna',
  'kisne',
  'kisni',
  'kisnu',
  'kista',
  'kiste',
  'kistu',
  'kišom',
  'kišan',
  'kišne',
  'kišnu',
  'kišah',
  'kišim',
  'kišio',
  'kišiš',
  'kišit',
  'kišna',
  'kišni',
  'kišno',
  'kitom',
  'kitim',
  'kitio',
  'kitiš',
  'kitit',
  'kitov',
  'kivan',
  'kivna',
  'kivne',
  'kivni',
  'kivno',
  'klada',
  'klade',
  'kladi',
  'klado',
  'kladu',
  'klade',
  'kladi',
  'klaka',
  'klana',
  'klanu',
  'klane',
  'klani',
  'klano',
  'klapu',
  'klapa',
  'klape',
  'klapi',
  'klapa',
  'klara',
  'klasa',
  'klasi',
  'klasu',
  'klasa',
  'klase',
  'klasi',
  'klasu',
  'klasa',
  'klahu',
  'klala',
  'klale',
  'klali',
  'klalo',
  'klaše',
  'klati',
  'klate',
  'klati',
  'klaun',
  'klaus',
  'kleca',
  'kleče',
  'kleči',
  'klein',
  'kleka',
  'kleke',
  'kleki',
  'kleko',
  'kleku',
  'klela',
  'kleli',
  'klema',
  'kleme',
  'klena',
  'klenu',
  'klepa',
  'klera',
  'klere',
  'kleri',
  'kleru',
  'klesa',
  'klešu',
  'kleši',
  'klele',
  'klelo',
  'kleše',
  'klete',
  'kleti',
  'kunem',
  'kleta',
  'kleti',
  'kletu',
  'kleto',
  'klica',
  'klice',
  'klici',
  'klico',
  'klicu',
  'kliče',
  'kliči',
  'kliču',
  'klice',
  'kliča',
  'klifa',
  'klife',
  'klifu',
  'klija',
  'klije',
  'kliji',
  'klijo',
  'kliju',
  'klija',
  'klika',
  'kliku',
  'klika',
  'klike',
  'kliki',
  'kliko',
  'kliku',
  'klilo',
  'klima',
  'klime',
  'klimi',
  'klimu',
  'klima',
  'klina',
  'kline',
  'klinu',
  'klinč',
  'klipa',
  'klipe',
  'klipu',
  'kliše',
  'kliza',
  'kliže',
  'kliži',
  'kližu',
  'klize',
  'klizi',
  'klona',
  'klone',
  'klonu',
  'kloni',
  'klone',
  'kloni',
  'klonu',
  'klopa',
  'klope',
  'klopi',
  'klopo',
  'klopu',
  'klopa',
  'klora',
  'klore',
  'kloru',
  'kluba',
  'klube',
  'klubi',
  'klubu',
  'klupa',
  'klupe',
  'klupi',
  'klupo',
  'klupu',
  'ključ',
  'kljun',
  'kmeče',
  'kmeči',
  'kmeta',
  'kmete',
  'kmeti',
  'kmetu',
  'kneza',
  'kneze',
  'knezi',
  'knezu',
  'kneže',
  'knina',
  'kobac',
  'kopca',
  'kopce',
  'kopci',
  'kopcu',
  'koban',
  'kobna',
  'kobne',
  'kobni',
  'kobno',
  'kobnu',
  'kobru',
  'kobcu',
  'kobno',
  'koboj',
  'kobra',
  'kobre',
  'kobri',
  'kocen',
  'kocka',
  'kocke',
  'kocki',
  'kocko',
  'kocku',
  'kocka',
  'kocke',
  'kocoj',
  'kočom',
  'kočar',
  'kočen',
  'kočah',
  'kočim',
  'kočio',
  'kočiš',
  'kočit',
  'kočna',
  'kočne',
  'kočni',
  'kočno',
  'kočnu',
  'koćom',
  'koćar',
  'koćim',
  'kodom',
  'kodak',
  'kodek',
  'koder',
  'kodna',
  'kodne',
  'kodni',
  'kodno',
  'kodnu',
  'kodon',
  'kofer',
  'kogol',
  'koion',
  'kojeg',
  'kojem',
  'kojih',
  'kojim',
  'kojoj',
  'kojom',
  'kojić',
  'kojot',
  'kokom',
  'koker',
  'kokne',
  'kokni',
  'koknu',
  'kokon',
  'kokos',
  'kokoš',
  'kokot',
  'koksa',
  'koksu',
  'kolac',
  'kolca',
  'kolce',
  'kolci',
  'kolcu',
  'kolče',
  'kolač',
  'kolan',
  'kolar',
  'kolah',
  'kolaj',
  'kolam',
  'kolao',
  'kolaš',
  'kolat',
  'kolaž',
  'kolet',
  'kolin',
  'kolim',
  'kolna',
  'kolne',
  'kolni',
  'kolno',
  'kolnu',
  'kolom',
  'kolon',
  'kolor',
  'kolos',
  'kolut',
  'kolju',
  'kolja',
  'kolja',
  'kolje',
  'kolji',
  'komom',
  'komad',
  'komar',
  'kombi',
  'komen',
  'komet',
  'komin',
  'komis',
  'komor',
  'komut',
  'konac',
  'konca',
  'konce',
  'konci',
  'koncu',
  'konak',
  'konga',
  'konga',
  'konop',
  'konti',
  'konta',
  'konto',
  'kontu',
  'konus',
  'konva',
  'konvi',
  'konvu',
  'konja',
  'konje',
  'konji',
  'konju',
  'kopom',
  'kopač',
  'kopal',
  'kopan',
  'kopar',
  'kopru',
  'kopah',
  'kopaj',
  'kopam',
  'kopao',
  'kopaš',
  'kopat',
  'kopča',
  'kopče',
  'kopči',
  'kopčo',
  'kopču',
  'kopka',
  'kopna',
  'kopno',
  'kopnu',
  'kopne',
  'kopni',
  'kopra',
  'kopun',
  'korom',
  'korać',
  'korak',
  'koral',
  'korda',
  'korda',
  'koren',
  'korah',
  'korim',
  'korio',
  'koriš',
  'korit',
  'korov',
  'korpa',
  'korpe',
  'korpi',
  'korpo',
  'korpu',
  'korza',
  'korzo',
  'korzu',
  'kosom',
  'kosac',
  'kosce',
  'kosci',
  'košče',
  'kosač',
  'kosan',
  'kosni',
  'kosno',
  'kosah',
  'kosaj',
  'kosam',
  'kosao',
  'kosaš',
  'kosat',
  'kosih',
  'kosog',
  'kosić',
  'kosir',
  'kosim',
  'kosio',
  'kosit',
  'kosne',
  'kosni',
  'kosnu',
  'kosov',
  'kosti',
  'košću',
  'kosna',
  'košem',
  'košah',
  'košen',
  'košer',
  'košim',
  'košić',
  'koška',
  'košta',
  'košte',
  'košti',
  'košto',
  'koštu',
  'košta',
  'košut',
  'kocem',
  'kotac',
  'kotca',
  'kotce',
  'kotci',
  'kotcu',
  'kotče',
  'kotač',
  'kotao',
  'kotla',
  'kotle',
  'kotli',
  'kotlo',
  'kotlu',
  'kotar',
  'kotim',
  'kotio',
  'kotiš',
  'kotit',
  'kotna',
  'kotni',
  'kotor',
  'kotur',
  'kotva',
  'kotve',
  'kotvi',
  'kotvo',
  'kotvu',
  'kovom',
  'kovač',
  'kovan',
  'kovah',
  'kovao',
  'kovat',
  'kujem',
  'kuješ',
  'kujte',
  'kovit',
  'kovki',
  'kovko',
  'kovni',
  'kozom',
  'kosca',
  'koscu',
  'kozar',
  'kozer',
  'kozih',
  'kozja',
  'kozje',
  'kozji',
  'kozju',
  'kozle',
  'kožom',
  'kožan',
  'kožar',
  'kožna',
  'kožne',
  'kožni',
  'kožno',
  'kožnu',
  'kožuh',
  'kožun',
  'kraće',
  'kraća',
  'kraći',
  'kraću',
  'kradi',
  'krađa',
  'krađe',
  'krađi',
  'krađo',
  'krađu',
  'kraft',
  'kraha',
  'krahu',
  'kraše',
  'kraja',
  'kraji',
  'kraju',
  'kraju',
  'kraci',
  'krače',
  'kraka',
  'krake',
  'kraki',
  'kraku',
  'kralj',
  'krama',
  'krame',
  'krami',
  'kramo',
  'kramu',
  'kramp',
  'krana',
  'krane',
  'kranu',
  'kranj',
  'krasa',
  'krase',
  'krasu',
  'krase',
  'krasi',
  'krast',
  'krade',
  'kradu',
  'krala',
  'krale',
  'krali',
  'kraša',
  'krata',
  'kralo',
  'krati',
  'krate',
  'krati',
  'kraul',
  'kraus',
  'krava',
  'krave',
  'kravi',
  'kravo',
  'kravu',
  'krave',
  'krbav',
  'krcat',
  'krcam',
  'krcao',
  'krcka',
  'krčom',
  'krčag',
  'krčah',
  'krčao',
  'krčat',
  'krčim',
  'krčiš',
  'krčen',
  'krčah',
  'krčih',
  'krčim',
  'krčio',
  'krčiš',
  'krčit',
  'krčka',
  'krčka',
  'krčke',
  'krčki',
  'krčko',
  'krčku',
  'krčma',
  'krčme',
  'krčmi',
  'krčmo',
  'krčmu',
  'krčme',
  'krčmi',
  'krdoj',
  'krdom',
  'krdža',
  'kreča',
  'kreče',
  'kreču',
  'kreče',
  'kreči',
  'kreća',
  'kreda',
  'krede',
  'kredi',
  'kreda',
  'kredo',
  'kredu',
  'kreja',
  'krema',
  'kreme',
  'kremi',
  'kremo',
  'kremu',
  'krena',
  'krene',
  'kreni',
  'krenu',
  'kreol',
  'krepa',
  'krepe',
  'krepu',
  'krepa',
  'krešu',
  'kreše',
  'kreće',
  'kreći',
  'kreću',
  'krhaj',
  'krhak',
  'krhka',
  'krhke',
  'krhki',
  'krhko',
  'krhku',
  'krhko',
  'krica',
  'kriče',
  'kriči',
  'krici',
  'krika',
  'krike',
  'kriku',
  'krika',
  'krike',
  'kriku',
  'krila',
  'krilo',
  'krilu',
  'krina',
  'krinu',
  'krish',
  'krije',
  'kriju',
  'krila',
  'krile',
  'krili',
  'krilo',
  'kriti',
  'kriva',
  'krive',
  'krivo',
  'krivu',
  'krive',
  'krivi',
  'krivo',
  'kriza',
  'krize',
  'krizi',
  'krizo',
  'krizu',
  'križa',
  'križe',
  'križu',
  'krkao',
  'krkne',
  'krkni',
  'krknu',
  'krmoj',
  'krmad',
  'krmak',
  'krmci',
  'krmče',
  'krmka',
  'krmke',
  'krmku',
  'krmar',
  'krmen',
  'krmna',
  'krmne',
  'krmni',
  'krmno',
  'krmnu',
  'krmom',
  'krnja',
  'krnje',
  'krnji',
  'krnjo',
  'krnju',
  'krnje',
  'krnja',
  'krnje',
  'krnje',
  'krnji',
  'kroče',
  'kroči',
  'kroja',
  'kroju',
  'kroje',
  'kroji',
  'kroki',
  'kroma',
  'krome',
  'kromi',
  'kromu',
  'krone',
  'kropi',
  'krosa',
  'krosu',
  'krote',
  'kroti',
  'krova',
  'krove',
  'krovi',
  'krovu',
  'kroza',
  'krpan',
  'krpom',
  'krpač',
  'krpar',
  'krpah',
  'krpaj',
  'krpam',
  'krpao',
  'krpaš',
  'krpat',
  'krpež',
  'krpih',
  'krpim',
  'krpio',
  'krpiš',
  'krpit',
  'krpom',
  'krsna',
  'krsne',
  'krsni',
  'krsno',
  'krsnu',
  'krsno',
  'krsta',
  'krste',
  'krstu',
  'krsta',
  'krste',
  'krsti',
  'kršem',
  'kršan',
  'kršna',
  'kršne',
  'kršno',
  'kršah',
  'kršen',
  'kršim',
  'kršio',
  'kršiš',
  'kršit',
  'kršja',
  'kršje',
  'kršju',
  'krška',
  'krške',
  'krški',
  'krško',
  'kršni',
  'kršnu',
  'krtih',
  'krtim',
  'krtog',
  'krtoj',
  'krtom',
  'kruća',
  'krući',
  'kruću',
  'kruga',
  'krugu',
  'kruzi',
  'kruže',
  'krugi',
  'kruha',
  'kruhu',
  'krusi',
  'kruše',
  'krula',
  'kruli',
  'kruna',
  'krune',
  'kruni',
  'kruno',
  'krunu',
  'krune',
  'krupa',
  'krupe',
  'kruta',
  'krute',
  'krutu',
  'kruti',
  'kruto',
  'kruto',
  'kruže',
  'kruži',
  'krvav',
  'krvih',
  'krvim',
  'krvna',
  'krvne',
  'krvni',
  'krvno',
  'krvnu',
  'krzma',
  'krzni',
  'krzna',
  'krzna',
  'krzno',
  'krznu',
  'kuban',
  'kubna',
  'kubik',
  'kubna',
  'kubne',
  'kubni',
  'kubno',
  'kubnu',
  'kubus',
  'kucah',
  'kucaj',
  'kucam',
  'kucao',
  'kucaš',
  'kucat',
  'kucka',
  'kucna',
  'kucne',
  'kucni',
  'kucnu',
  'kučan',
  'kučja',
  'kučji',
  'kučju',
  'kučka',
  'kučke',
  'kučki',
  'kučko',
  'kučku',
  'kućom',
  'kućan',
  'kućen',
  'kućah',
  'kućim',
  'kućio',
  'kućiš',
  'kućit',
  'kućna',
  'kućne',
  'kućni',
  'kućno',
  'kućnu',
  'kudim',
  'kudio',
  'kudiš',
  'kudit',
  'kudzu',
  'kuđen',
  'kufer',
  'kugom',
  'kugla',
  'kugle',
  'kugli',
  'kuglo',
  'kuglu',
  'kuhač',
  'kuhan',
  'kuhar',
  'kuhah',
  'kuhaj',
  'kuham',
  'kuhao',
  'kuhaš',
  'kuhat',
  'kujom',
  'kukom',
  'kukom',
  'kukac',
  'kukca',
  'kukce',
  'kukci',
  'kukcu',
  'kukče',
  'kukah',
  'kukaj',
  'kukam',
  'kukan',
  'kukao',
  'kukaš',
  'kukat',
  'kukes',
  'kukoč',
  'kukov',
  'kukuc',
  'kulom',
  'kulen',
  'kulik',
  'kulim',
  'kulon',
  'kulta',
  'kulte',
  'kulti',
  'kultu',
  'kulju',
  'kulji',
  'kulja',
  'kumom',
  'kumom',
  'kumče',
  'kumen',
  'kumin',
  'kumir',
  'kumim',
  'kumio',
  'kumiš',
  'kumit',
  'kumov',
  'kumul',
  'kunom',
  'kunić',
  'kunih',
  'kunem',
  'kuneš',
  'kunje',
  'kunji',
  'kunja',
  'kunji',
  'kupom',
  'kupac',
  'kupca',
  'kupce',
  'kupci',
  'kupcu',
  'kupče',
  'kupač',
  'kupać',
  'kupan',
  'kupah',
  'kupaj',
  'kupam',
  'kupao',
  'kupaš',
  'kupat',
  'kupea',
  'kupee',
  'kupei',
  'kupeu',
  'kupić',
  'kupit',
  'kupih',
  'kupim',
  'kupio',
  'kupiš',
  'kupiv',
  'kupka',
  'kupke',
  'kupki',
  'kupku',
  'kupon',
  'kupuj',
  'kupus',
  'kurom',
  'kurac',
  'kurca',
  'kurce',
  'kurci',
  'kurcu',
  'kurče',
  'kurat',
  'kuraž',
  'kurda',
  'kurdi',
  'kuren',
  'kuret',
  'kurij',
  'kurir',
  'kurih',
  'kurit',
  'kurja',
  'kurje',
  'kurji',
  'kurju',
  'kursa',
  'kursu',
  'kursk',
  'kurva',
  'kurve',
  'kurvi',
  'kurvo',
  'kurvu',
  'kusim',
  'kusom',
  'kusat',
  'kusin',
  'kusur',
  'kušač',
  'kušan',
  'kušah',
  'kušaj',
  'kušam',
  'kušao',
  'kušaš',
  'kušat',
  'kušav',
  'kušet',
  'kutem',
  'kutom',
  'kutak',
  'kutci',
  'kutka',
  'kutke',
  'kutku',
  'kutan',
  'kutić',
  'kutli',
  'kutna',
  'kutne',
  'kutni',
  'kutno',
  'kutnu',
  'kutre',
  'kutri',
  'kupem',
  'kužan',
  'kužna',
  'kužne',
  'kužni',
  'kužno',
  'kužnu',
  'kužim',
  'kužio',
  'kužiš',
  'kužit',
  'kužno',
  'kvača',
  'kvaču',
  'kvače',
  'kvači',
  'kvaci',
  'kvaka',
  'kvake',
  'kvaku',
  'kvant',
  'kvara',
  'kvare',
  'kvaru',
  'kvarc',
  'kvare',
  'kvari',
  'kvark',
  'kvart',
  'kvasa',
  'kvasi',
  'kvasu',
  'kvase',
  'kvazi',
  'kvint',
  'kviza',
  'kvize',
  'kvizu',
  'kviza',
  'kvoca',
  'kvota',
  'kvote',
  'kvoti',
  'kvoto',
  'kvotu',
  'kvrga',
  'kvrge',
  'kvrgi',
  'kvrgo',
  'kvrgu',
  'kvrzi',
  'labav',
  'label',
  'labin',
  'labud',
  'labus',
  'lacij',
  'lačan',
  'lačna',
  'lačne',
  'lačni',
  'lačno',
  'lačnu',
  'laćah',
  'laćaj',
  'laćam',
  'laćao',
  'laćaš',
  'laćat',
  'ladić',
  'ladva',
  'lađom',
  'lađar',
  'lađim',
  'lafom',
  'lafet',
  'lagan',
  'lagao',
  'lagat',
  'lažeš',
  'lagav',
  'lagva',
  'lagve',
  'lagvi',
  'lagvu',
  'lager',
  'lagum',
  'lahor',
  'laici',
  'laiče',
  'laika',
  'laike',
  'laiku',
  'lajom',
  'lajah',
  'lajao',
  'lajat',
  'lajem',
  'laješ',
  'lajmo',
  'lajte',
  'lajav',
  'lajih',
  'lajka',
  'lakih',
  'lakim',
  'lakaj',
  'lakat',
  'lakta',
  'lakte',
  'lakti',
  'laktu',
  'lakej',
  'lakog',
  'lakne',
  'lakni',
  'laknu',
  'lakom',
  'lakše',
  'lakša',
  'lakše',
  'lakši',
  'lakšu',
  'laman',
  'lamah',
  'lamaj',
  'lamam',
  'lamao',
  'lamaš',
  'lamat',
  'lampa',
  'lampe',
  'lampi',
  'lampu',
  'lanim',
  'lanac',
  'lanca',
  'lance',
  'lanci',
  'lancu',
  'lanče',
  'lanad',
  'lanen',
  'lanem',
  'laneš',
  'lanuh',
  'lanuo',
  'lanut',
  'lapis',
  'lapor',
  'lapće',
  'lapći',
  'lapću',
  'largo',
  'larma',
  'larme',
  'larmi',
  'larmo',
  'larmu',
  'larma',
  'larva',
  'larve',
  'larvi',
  'larvo',
  'larvu',
  'larve',
  'laser',
  'lasić',
  'lasih',
  'lasju',
  'laska',
  'laske',
  'lasku',
  'laska',
  'laski',
  'laslo',
  'lasom',
  'lasta',
  'laste',
  'lasti',
  'lasto',
  'lastu',
  'lašca',
  'lašcu',
  'laška',
  'laške',
  'laški',
  'laško',
  'lašku',
  'laško',
  'lašte',
  'lašti',
  'latin',
  'latih',
  'latim',
  'latio',
  'latiš',
  'latit',
  'lauda',
  'laude',
  'laudi',
  'laudo',
  'laudu',
  'laura',
  'lavom',
  'lavat',
  'lavež',
  'lavić',
  'lavor',
  'lazom',
  'lažem',
  'lazim',
  'lazni',
  'lažju',
  'lašce',
  'lašci',
  'lašče',
  'lažac',
  'lažan',
  'lažna',
  'lažne',
  'lažni',
  'lažno',
  'lažnu',
  'lažih',
  'lažno',
  'lažov',
  'lebda',
  'lebdi',
  'lebde',
  'lebdi',
  'lebić',
  'lecih',
  'lecne',
  'lecni',
  'lecnu',
  'lečva',
  'lećom',
  'legao',
  'legni',
  'legnu',
  'legoh',
  'legne',
  'ledom',
  'lecem',
  'ledac',
  'ledca',
  'ledce',
  'ledci',
  'ledcu',
  'ledče',
  'ledak',
  'leden',
  'ledić',
  'ledin',
  'ledim',
  'ledio',
  'lediš',
  'ledit',
  'leđah',
  'leđen',
  'leđoj',
  'leđna',
  'leđne',
  'leđni',
  'leđno',
  'leđnu',
  'leđno',
  'legat',
  'legla',
  'leglo',
  'leglu',
  'lemom',
  'lemom',
  'leman',
  'lemah',
  'lemaj',
  'lemam',
  'lemao',
  'lemaš',
  'lemat',
  'lemeš',
  'lemim',
  'lemio',
  'lemiš',
  'lemit',
  'lemna',
  'lemne',
  'lemni',
  'lemur',
  'lenca',
  'lencu',
  'lenom',
  'lenta',
  'lente',
  'lenti',
  'lento',
  'lentu',
  'lento',
  'leona',
  'leone',
  'lepet',
  'lepir',
  'lepra',
  'lepre',
  'lepri',
  'lepro',
  'lepru',
  'lepuh',
  'lerom',
  'lešom',
  'leška',
  'letom',
  'letač',
  'letak',
  'letci',
  'letče',
  'letka',
  'letke',
  'letku',
  'letim',
  'letim',
  'letio',
  'letiš',
  'letna',
  'letne',
  'letni',
  'letva',
  'letve',
  'letvi',
  'letvo',
  'letvu',
  'leuti',
  'levar',
  'levit',
  'ležaj',
  'ležah',
  'ležao',
  'ležat',
  'ležim',
  'ležiš',
  'liaza',
  'libar',
  'libra',
  'libre',
  'libri',
  'libru',
  'liber',
  'libim',
  'libio',
  'libiš',
  'libit',
  'libor',
  'libra',
  'libre',
  'licom',
  'licem',
  'licej',
  'licih',
  'licim',
  'licka',
  'ličan',
  'lična',
  'lične',
  'lični',
  'ličnu',
  'ličen',
  'ličim',
  'ličio',
  'lička',
  'ličke',
  'lički',
  'ličko',
  'ličku',
  'lična',
  'lične',
  'lični',
  'lično',
  'ličnu',
  'lider',
  'lifra',
  'lifta',
  'liftu',
  'ligom',
  'ligaš',
  'lihen',
  'lihva',
  'lihve',
  'lihvi',
  'lihvo',
  'lihvu',
  'lijom',
  'lijas',
  'lijek',
  'lijen',
  'lijep',
  'lijer',
  'lijes',
  'lijev',
  'lijih',
  'lijim',
  'lijoj',
  'likom',
  'liker',
  'likom',
  'likov',
  'likuj',
  'lilek',
  'limar',
  'limbe',
  'limbu',
  'limba',
  'limen',
  'limes',
  'limfa',
  'limfe',
  'limfi',
  'limfo',
  'limfu',
  'limit',
  'limim',
  'limun',
  'linča',
  'linču',
  'lingo',
  'linij',
  'linka',
  'linke',
  'linku',
  'linja',
  'lipom',
  'lipid',
  'lipik',
  'lipin',
  'lipnj',
  'lipom',
  'lipov',
  'lipsa',
  'lipše',
  'lipši',
  'lipšu',
  'lipće',
  'lipći',
  'lipću',
  'lirom',
  'lirik',
  'liroj',
  'lisac',
  'lisca',
  'liscu',
  'lisin',
  'lisja',
  'lisju',
  'liska',
  'liske',
  'lisko',
  'liski',
  'lisna',
  'lisne',
  'lisni',
  'lisno',
  'lisnu',
  'lista',
  'listu',
  'lista',
  'liste',
  'listi',
  'listo',
  'listu',
  'lisci',
  'liska',
  'liske',
  'lisku',
  'lišče',
  'lista',
  'liste',
  'lišaj',
  'lišca',
  'lišce',
  'lišcu',
  'lišća',
  'lišće',
  'lišću',
  'lišen',
  'liših',
  'lišim',
  'lišio',
  'lišiš',
  'lišit',
  'lijem',
  'lijte',
  'litij',
  'litra',
  'litre',
  'litri',
  'litru',
  'litve',
  'litvo',
  'livom',
  'livac',
  'liven',
  'livon',
  'lizan',
  'lizni',
  'lizah',
  'lizao',
  'lizat',
  'ližem',
  'ližeš',
  'lizne',
  'liznu',
  'lizol',
  'ližim',
  'lobom',
  'lobor',
  'ločem',
  'lođom',
  'logim',
  'loger',
  'login',
  'logis',
  'logoi',
  'logom',
  'logor',
  'lojem',
  'lojen',
  'lojan',
  'lojna',
  'lojav',
  'lojen',
  'lojah',
  'lojim',
  'lojio',
  'lojiš',
  'lojit',
  'lojka',
  'lojna',
  'lojne',
  'lojni',
  'lojno',
  'lojnu',
  'lokal',
  'lokah',
  'lokao',
  'lokat',
  'lokna',
  'lokne',
  'lokni',
  'lokno',
  'loknu',
  'lokot',
  'lokus',
  'lokva',
  'lokve',
  'lokvi',
  'lokvo',
  'lokvu',
  'lolom',
  'lomom',
  'loman',
  'lomna',
  'lomne',
  'lomno',
  'lomnu',
  'lomim',
  'lomio',
  'lomiš',
  'lomit',
  'lomiv',
  'lomiv',
  'lomni',
  'lomno',
  'lomot',
  'lonac',
  'lonca',
  'lonce',
  'lonci',
  'loncu',
  'lonče',
  'lopez',
  'lopoč',
  'lopov',
  'lopta',
  'lopte',
  'lopti',
  'lopto',
  'loptu',
  'lopta',
  'lopud',
  'lopuh',
  'lorda',
  'lordi',
  'lordu',
  'losom',
  'losos',
  'lošeg',
  'lošem',
  'loših',
  'lošim',
  'lošom',
  'lošoj',
  'lotom',
  'lotan',
  'lotim',
  'lotos',
  'lotus',
  'louis',
  'lovom',
  'lovom',
  'lovac',
  'lovca',
  'lovce',
  'lovci',
  'lovcu',
  'lovče',
  'lovaš',
  'lovim',
  'lovio',
  'loviš',
  'lovit',
  'lovka',
  'lovke',
  'lovna',
  'lovne',
  'lovni',
  'lovno',
  'lovnu',
  'lovno',
  'lovor',
  'lovre',
  'lozom',
  'lozin',
  'lozna',
  'lozne',
  'lozni',
  'lozno',
  'loznu',
  'ložom',
  'ložač',
  'ložen',
  'ložah',
  'ložih',
  'ložim',
  'ložio',
  'ložiš',
  'ložit',
  'loživ',
  'ložna',
  'ložne',
  'ložni',
  'ložno',
  'ložnu',
  'lubin',
  'lucom',
  'lučju',
  'lučom',
  'lučac',
  'lučca',
  'lučce',
  'lučci',
  'lučcu',
  'lučen',
  'lučah',
  'lučim',
  'lučio',
  'lučiš',
  'lučit',
  'lučiv',
  'lučka',
  'lučke',
  'lučki',
  'lučko',
  'lučku',
  'lučna',
  'lučne',
  'lučni',
  'lučno',
  'lučnu',
  'ludih',
  'ludim',
  'ludoj',
  'ludom',
  'luđeg',
  'ludom',
  'ludaš',
  'ludog',
  'ludim',
  'ludio',
  'ludiš',
  'ludov',
  'luduj',
  'luđoj',
  'luđak',
  'luđim',
  'luđom',
  'luesa',
  'luese',
  'luesi',
  'luesu',
  'lugar',
  'lukom',
  'lukom',
  'lukav',
  'lukić',
  'lukov',
  'luksa',
  'lukse',
  'luksi',
  'luksu',
  'lulom',
  'lular',
  'lulaš',
  'lulav',
  'lulih',
  'lumen',
  'lumin',
  'lumpa',
  'lunom',
  'lunar',
  'lundu',
  'lunim',
  'lunja',
  'lunja',
  'lunje',
  'lunju',
  'luloj',
  'lupom',
  'lupač',
  'lupan',
  'lupar',
  'lupah',
  'lupaj',
  'lupam',
  'lupao',
  'lupaš',
  'lupat',
  'lupež',
  'lupka',
  'lupne',
  'lupni',
  'lupnu',
  'lupus',
  'lutak',
  'lutci',
  'lutče',
  'lutka',
  'lutke',
  'lutku',
  'lutah',
  'lutaj',
  'lutam',
  'lutao',
  'lutaš',
  'lutat',
  'lutka',
  'lutke',
  'lutki',
  'lutko',
  'lutku',
  'luton',
  'lužit',
  'ljaga',
  'ljagi',
  'ljago',
  'ljagu',
  'ljazi',
  'ljage',
  'ljama',
  'ljame',
  'ljami',
  'ljamo',
  'ljamu',
  'ljeti',
  'ljeta',
  'ljeto',
  'ljetu',
  'ljiga',
  'ljige',
  'ljigi',
  'ljigo',
  'ljigu',
  'ljuba',
  'ljube',
  'ljubi',
  'ljuća',
  'ljuće',
  'ljući',
  'ljuću',
  'ljude',
  'ljudi',
  'ljulj',
  'ljuta',
  'ljute',
  'ljuti',
  'ljutu',
  'ljuto',
  'ljute',
  'ljuti',
  'ljuto',
  'macom',
  'macan',
  'macho',
  'macih',
  'macku',
  'mačem',
  'mačak',
  'mačke',
  'mačuj',
  'mačić',
  'mačja',
  'mačje',
  'mačji',
  'mačju',
  'mačci',
  'mačka',
  'mačke',
  'mački',
  'mačko',
  'mačku',
  'mačku',
  'mačoa',
  'mačoe',
  'mačoi',
  'mačou',
  'mačor',
  'madar',
  'madež',
  'magom',
  'magaš',
  'mažem',
  'magee',
  'magla',
  'magle',
  'magli',
  'maglo',
  'maglu',
  'magma',
  'magme',
  'magmi',
  'magmo',
  'magmu',
  'mahah',
  'mahan',
  'mahao',
  'mahat',
  'mašeš',
  'maher',
  'mahim',
  'mahne',
  'mahni',
  'mahnu',
  'mahom',
  'maier',
  'maila',
  'majem',
  'majom',
  'majić',
  'majim',
  'majci',
  'majka',
  'majke',
  'majki',
  'majko',
  'majku',
  'major',
  'majur',
  'makim',
  'makom',
  'makac',
  'makar',
  'makla',
  'makle',
  'makli',
  'maklo',
  'makne',
  'makni',
  'maknu',
  'makoh',
  'makro',
  'maksa',
  'maksi',
  'malaj',
  'malen',
  'maler',
  'malih',
  'malim',
  'malog',
  'maloj',
  'malom',
  'malik',
  'malko',
  'malne',
  'malto',
  'malus',
  'malja',
  'malju',
  'malja',
  'malje',
  'malji',
  'maljo',
  'malju',
  'mamom',
  'mamac',
  'mamca',
  'mamce',
  'mamci',
  'mamcu',
  'mamče',
  'mamba',
  'mamin',
  'mamih',
  'mamio',
  'mamiš',
  'mamit',
  'mamim',
  'mamut',
  'manom',
  'manga',
  'mango',
  'mangu',
  'manić',
  'manko',
  'manta',
  'manje',
  'manja',
  'manje',
  'manji',
  'manju',
  'maona',
  'mapom',
  'marom',
  'maran',
  'marna',
  'marno',
  'marnu',
  'marat',
  'marca',
  'marco',
  'marie',
  'marah',
  'maren',
  'marih',
  'marim',
  'mario',
  'mariš',
  'marit',
  'marka',
  'marku',
  'marci',
  'marka',
  'marke',
  'marki',
  'marku',
  'marku',
  'marni',
  'marod',
  'marof',
  'marsa',
  'marša',
  'marše',
  'maršu',
  'marši',
  'marta',
  'marte',
  'martu',
  'marta',
  'marva',
  'marve',
  'marvi',
  'marvo',
  'marvu',
  'marža',
  'marže',
  'marži',
  'maržo',
  'maržu',
  'masom',
  'masan',
  'masnu',
  'masen',
  'maser',
  'masih',
  'masiv',
  'masci',
  'maska',
  'maske',
  'maski',
  'masko',
  'masku',
  'maske',
  'masla',
  'maslo',
  'maslu',
  'masni',
  'masna',
  'masne',
  'masno',
  'masno',
  'mason',
  'masti',
  'mašću',
  'masnu',
  'mašem',
  'mašak',
  'maših',
  'mašim',
  'mašio',
  'mašiš',
  'mašit',
  'maška',
  'maški',
  'mašku',
  'mašna',
  'mašne',
  'mašni',
  'mašno',
  'mašnu',
  'mašta',
  'mašte',
  'mašti',
  'mašto',
  'maštu',
  'mašta',
  'matea',
  'matej',
  'mater',
  'matku',
  'matna',
  'matne',
  'matni',
  'matno',
  'matnu',
  'mator',
  'maona',
  'mauna',
  'maune',
  'mauni',
  'mauno',
  'maunu',
  'mazan',
  'mazom',
  'mazac',
  'mazač',
  'mazan',
  'mazna',
  'mazne',
  'mazno',
  'maznu',
  'mazao',
  'mazaš',
  'mazat',
  'mažeš',
  'mazga',
  'mazge',
  'mazgi',
  'mazgo',
  'mazgu',
  'mazim',
  'mazio',
  'maziš',
  'mazit',
  'maziv',
  'mazni',
  'mazoj',
  'mazut',
  'mažen',
  'mečem',
  'mečom',
  'mečić',
  'medom',
  'medan',
  'medna',
  'medne',
  'medni',
  'medno',
  'mednu',
  'medar',
  'meden',
  'media',
  'medić',
  'medij',
  'medim',
  'međom',
  'međaš',
  'međim',
  'međne',
  'megid',
  'mehun',
  'mejaš',
  'mekih',
  'mekoj',
  'mekom',
  'mekše',
  'mekač',
  'mekan',
  'meket',
  'mekim',
  'mekog',
  'mekša',
  'mekše',
  'mekši',
  'mekšu',
  'melem',
  'melez',
  'melon',
  'melos',
  'melje',
  'memli',
  'menta',
  'menza',
  'menze',
  'menzi',
  'menzo',
  'menzu',
  'mesar',
  'mesin',
  'mesna',
  'mesne',
  'mesni',
  'mesno',
  'mesnu',
  'mesom',
  'mesti',
  'metem',
  'meteš',
  'metom',
  'metak',
  'metče',
  'metka',
  'metke',
  'metku',
  'metal',
  'metan',
  'metar',
  'metra',
  'metre',
  'metri',
  'metru',
  'mećem',
  'mećeš',
  'metah',
  'metao',
  'metat',
  'metež',
  'metil',
  'metci',
  'metla',
  'metle',
  'metli',
  'metlu',
  'metne',
  'metni',
  'metnu',
  'metoh',
  'metro',
  'mezij',
  'mezon',
  'mican',
  'micah',
  'micao',
  'micat',
  'mičem',
  'mičeš',
  'micro',
  'mićen',
  'mider',
  'migom',
  'migah',
  'migaj',
  'migam',
  'migao',
  'migaš',
  'migat',
  'migni',
  'migne',
  'mignu',
  'mijau',
  'mijeh',
  'mijić',
  'milom',
  'milas',
  'miles',
  'milog',
  'milić',
  'milim',
  'milio',
  'miliš',
  'milit',
  'milka',
  'miloš',
  'miluj',
  'milja',
  'milje',
  'milji',
  'miljo',
  'milju',
  'minom',
  'miner',
  'minih',
  'minim',
  'minog',
  'minij',
  'minor',
  'minov',
  'minsk',
  'minuo',
  'minus',
  'minut',
  'minem',
  'mineš',
  'minuh',
  'milih',
  'miloj',
  'mirom',
  'mirom',
  'miran',
  'mirne',
  'mirni',
  'mirno',
  'mirnu',
  'miraz',
  'miraž',
  'mirha',
  'miris',
  'miren',
  'mirim',
  'mirio',
  'miriš',
  'mirit',
  'mirni',
  'mirna',
  'mirno',
  'miruj',
  'mirta',
  'mirte',
  'mirti',
  'mirtu',
  'mirza',
  'misom',
  'misal',
  'misao',
  'misli',
  'misle',
  'misli',
  'misna',
  'misne',
  'misni',
  'misno',
  'misnu',
  'misno',
  'mišem',
  'mišar',
  'mišić',
  'mišja',
  'mišja',
  'mišje',
  'mišji',
  'mišju',
  'mišci',
  'miška',
  'mitom',
  'mitri',
  'mitru',
  'miješ',
  'mitim',
  'mitio',
  'mitiš',
  'mitit',
  'mitom',
  'mitra',
  'mitre',
  'mitro',
  'mjedi',
  'mjeđu',
  'mjeda',
  'mjedo',
  'mjera',
  'mjere',
  'mjeri',
  'mjero',
  'mjeru',
  'mjeri',
  'mlača',
  'mlaču',
  'mlada',
  'mlade',
  'mladu',
  'mlada',
  'mladu',
  'mlaca',
  'mlace',
  'mlaci',
  'mlacu',
  'mlače',
  'mladi',
  'mlado',
  'mlađa',
  'mlađe',
  'mlađi',
  'mlađu',
  'mlađo',
  'mlaka',
  'mlake',
  'mlaki',
  'mlako',
  'mlaku',
  'mlaka',
  'mlako',
  'mlata',
  'mlate',
  'mlatu',
  'mlati',
  'mlaza',
  'mlazu',
  'mliko',
  'mlina',
  'mline',
  'mlinu',
  'mlina',
  'mlini',
  'mliva',
  'mlivo',
  'mljac',
  'mljet',
  'melji',
  'melju',
  'mnoga',
  'mnoge',
  'mnogi',
  'mnogo',
  'mnogu',
  'mnogo',
  'množe',
  'množi',
  'moare',
  'mobom',
  'mobil',
  'močen',
  'močah',
  'močim',
  'močio',
  'močiš',
  'močit',
  'močiv',
  'moćan',
  'moćna',
  'moćne',
  'moćno',
  'moćnu',
  'mogao',
  'mogav',
  'mogla',
  'mogle',
  'mogli',
  'moglo',
  'mogne',
  'mognu',
  'mogoh',
  'možeš',
  'moćni',
  'moćno',
  'modom',
  'modan',
  'modne',
  'modar',
  'modra',
  'modre',
  'modri',
  'modro',
  'modru',
  'model',
  'modem',
  'modna',
  'modne',
  'modni',
  'modno',
  'modnu',
  'modro',
  'modul',
  'modur',
  'modus',
  'mogah',
  'moguć',
  'moher',
  'mojeg',
  'mojem',
  'mojih',
  'mojim',
  'mojoj',
  'mojom',
  'mojih',
  'mokar',
  'mokra',
  'mokre',
  'mokri',
  'mokro',
  'mokru',
  'mokri',
  'mokro',
  'molba',
  'molbe',
  'molbi',
  'molbo',
  'molbu',
  'molim',
  'molio',
  'moliš',
  'molit',
  'molna',
  'molne',
  'molni',
  'molom',
  'moloh',
  'momak',
  'momci',
  'momka',
  'momke',
  'momku',
  'momče',
  'monah',
  'monom',
  'month',
  'moore',
  'moped',
  'morom',
  'moral',
  'morah',
  'moraj',
  'moram',
  'moran',
  'morao',
  'moraš',
  'morat',
  'morem',
  'morić',
  'moren',
  'morim',
  'morio',
  'moriš',
  'morit',
  'morta',
  'morte',
  'mortu',
  'morža',
  'moržu',
  'mosna',
  'mosne',
  'mosni',
  'mosno',
  'mosnu',
  'mosta',
  'moste',
  'mosti',
  'mostu',
  'mosul',
  'mošak',
  'mošta',
  'mošte',
  'mošti',
  'moštu',
  'mošus',
  'motan',
  'motar',
  'motah',
  'motaj',
  'motam',
  'motao',
  'motaš',
  'motat',
  'motel',
  'motet',
  'motiv',
  'motci',
  'motka',
  'motke',
  'motki',
  'motko',
  'motku',
  'motom',
  'motor',
  'motre',
  'motri',
  'mozak',
  'mozga',
  'mozgu',
  'možda',
  'mrače',
  'mrači',
  'mraže',
  'mraci',
  'mraka',
  'mrake',
  'mraku',
  'mrava',
  'mrave',
  'mravi',
  'mravu',
  'mraza',
  'mraze',
  'mrazi',
  'mrazu',
  'mrčom',
  'mrčih',
  'mrdaj',
  'mrdne',
  'mrdni',
  'mrdnu',
  'mrena',
  'mrene',
  'mreni',
  'mrenu',
  'mreža',
  'mreže',
  'mreži',
  'mrežo',
  'mrežu',
  'mrgud',
  'mremo',
  'mraše',
  'mrčim',
  'mrkih',
  'mrkim',
  'mrkog',
  'mrkoj',
  'mrkom',
  'mrkač',
  'mrkle',
  'mrkne',
  'mrkni',
  'mrknu',
  'mrkva',
  'mrkve',
  'mrkvi',
  'mrkvo',
  'mrkvu',
  'mrkve',
  'mrlja',
  'mrlje',
  'mrlji',
  'mrljo',
  'mrlju',
  'mrmor',
  'mrmot',
  'mrsim',
  'mrsio',
  'mrsiš',
  'mrsit',
  'mrska',
  'mrski',
  'mrsko',
  'mrsne',
  'mršav',
  'mršte',
  'mršti',
  'mrtav',
  'mrtva',
  'mrtve',
  'mrtvi',
  'mrtvo',
  'mrtvu',
  'mrvom',
  'mrvim',
  'mrvio',
  'mrviš',
  'mrvit',
  'mrska',
  'mrske',
  'mrsku',
  'mrzak',
  'mrzao',
  'mrzim',
  'mrzio',
  'mrziš',
  'mrzit',
  'mržen',
  'mrzla',
  'mrzle',
  'mrzli',
  'mrzlo',
  'mrzlu',
  'mrzne',
  'mrzni',
  'mrznu',
  'mucah',
  'mucaj',
  'mucam',
  'mucao',
  'mucaš',
  'mucat',
  'mucav',
  'mucin',
  'mučan',
  'mučne',
  'mučni',
  'mučnu',
  'mučen',
  'mučah',
  'mučim',
  'mučio',
  'mučiš',
  'mučit',
  'mučka',
  'mučki',
  'mučku',
  'mučna',
  'mučno',
  'mućak',
  'mućka',
  'mudar',
  'mudra',
  'mudre',
  'mudri',
  'mudro',
  'mudru',
  'mudom',
  'mudro',
  'mufom',
  'mufić',
  'muhom',
  'mukom',
  'mukao',
  'mukla',
  'mukle',
  'muklo',
  'muklu',
  'mukah',
  'mukam',
  'mukat',
  'mukin',
  'mukli',
  'muklo',
  'mulom',
  'mulca',
  'mulci',
  'mulcu',
  'mulat',
  'mulit',
  'multi',
  'mulja',
  'mulji',
  'mulju',
  'mulja',
  'mumps',
  'mumsa',
  'mumse',
  'mumsi',
  'mumsu',
  'munzi',
  'munga',
  'mungo',
  'munja',
  'munje',
  'munji',
  'munjo',
  'munju',
  'murva',
  'murve',
  'murvi',
  'murvo',
  'murvu',
  'music',
  'musti',
  'muzem',
  'muzeš',
  'muzla',
  'muzle',
  'muzli',
  'muška',
  'muške',
  'muški',
  'muško',
  'mušku',
  'mutan',
  'mutna',
  'mutne',
  'mutni',
  'mutno',
  'mutnu',
  'mutav',
  'mutim',
  'mutio',
  'mutiš',
  'mutit',
  'mutne',
  'mutni',
  'mutno',
  'muvah',
  'muvaj',
  'muvam',
  'muvao',
  'muvaš',
  'muvat',
  'muzom',
  'muzac',
  'muzar',
  'muzao',
  'muzej',
  'muzne',
  'muzni',
  'mužem',
  'mužar',
  'mužić',
  'mužik',
  'nabit',
  'nabih',
  'nabij',
  'nabio',
  'nabit',
  'nabla',
  'nable',
  'nablu',
  'naboj',
  'nabor',
  'naboo',
  'nabra',
  'nacrt',
  'načas',
  'načet',
  'načeh',
  'načeo',
  'načet',
  'načev',
  'načne',
  'načni',
  'načnu',
  'način',
  'načuh',
  'načuj',
  'načuo',
  'načut',
  'nađem',
  'nađeš',
  'nađoh',
  'našao',
  'našav',
  'našla',
  'našle',
  'našli',
  'našlo',
  'naćve',
  'nadom',
  'nadal',
  'nadam',
  'nadan',
  'nadah',
  'nadam',
  'nadao',
  'nadaš',
  'nadat',
  'nadaj',
  'nadir',
  'nadje',
  'nadre',
  'nadru',
  'nadri',
  'nadri',
  'nadro',
  'nadut',
  'nadmu',
  'naduh',
  'naduo',
  'nadvi',
  'nađen',
  'nafta',
  'nafte',
  'nafti',
  'nafto',
  'naftu',
  'nagih',
  'nagim',
  'nagog',
  'nagoj',
  'nagom',
  'nagal',
  'nagao',
  'nagli',
  'naglo',
  'naglu',
  'nagib',
  'nagla',
  'nagle',
  'nagli',
  'naglo',
  'nagna',
  'nagne',
  'nagni',
  'nagnu',
  'nagoh',
  'nagon',
  'nahod',
  'naići',
  'naiđe',
  'naiđi',
  'naiđu',
  'naila',
  'naime',
  'nairn',
  'naiva',
  'naive',
  'naivi',
  'naivo',
  'naivu',
  'najaf',
  'najam',
  'najma',
  'najmu',
  'najeo',
  'najme',
  'najmi',
  'nakaj',
  'nakan',
  'nakić',
  'nakit',
  'nakla',
  'nakon',
  'nakot',
  'nakuj',
  'nakup',
  'nalaz',
  'nalet',
  'nalič',
  'nalik',
  'nalij',
  'nalio',
  'nalit',
  'naliv',
  'nalog',
  'namaz',
  'namet',
  'namot',
  'namre',
  'namri',
  'namro',
  'namru',
  'namsa',
  'nandu',
  'nanio',
  'nanos',
  'naoči',
  'naoko',
  'naoru',
  'napad',
  'napao',
  'napet',
  'napeh',
  'napeo',
  'napne',
  'napni',
  'napnu',
  'napis',
  'napit',
  'napih',
  'napij',
  'napio',
  'napit',
  'napoj',
  'napon',
  'napor',
  'narom',
  'narav',
  'narez',
  'nariv',
  'narko',
  'narod',
  'naron',
  'nasad',
  'nasip',
  'naspe',
  'nasuh',
  'nasuo',
  'nasut',
  'našeg',
  'našem',
  'naših',
  'našim',
  'našoj',
  'našom',
  'našto',
  'natom',
  'natru',
  'natre',
  'natrh',
  'natri',
  'natro',
  'natrt',
  'nauče',
  'nauči',
  'naude',
  'naudi',
  'nauci',
  'nauka',
  'nauke',
  'nauku',
  'nauka',
  'nauke',
  'nauko',
  'nauku',
  'nauma',
  'naumu',
  'naume',
  'naumi',
  'nauta',
  'naute',
  'nauti',
  'nautu',
  'navar',
  'naveo',
  'navez',
  'navih',
  'navij',
  'navio',
  'navit',
  'navod',
  'navoj',
  'navoz',
  'navrh',
  'navre',
  'nazad',
  'nazal',
  'naziv',
  'nazor',
  'nazre',
  'nazuh',
  'nazuj',
  'nazuo',
  'nazut',
  'nazva',
  'nažao',
  'ncage',
  'nebom',
  'nećak',
  'nećka',
  'nefin',
  'negda',
  'negve',
  'nehaj',
  'nehat',
  'nejač',
  'nejak',
  'nekad',
  'nekih',
  'nekim',
  'nekog',
  'nekoj',
  'nekom',
  'nekić',
  'nekoć',
  'nekud',
  'neman',
  'nemar',
  'nemah',
  'nemaj',
  'nemam',
  'nemao',
  'nemaš',
  'nemat',
  'nemec',
  'nemio',
  'nemir',
  'nemoć',
  'nemrs',
  'neona',
  'neone',
  'neoni',
  'neonu',
  'neošt',
  'nepar',
  'nepca',
  'nepce',
  'nepcu',
  'neper',
  'nepun',
  'nerad',
  'nerca',
  'nercu',
  'nered',
  'nerka',
  'nerom',
  'nervi',
  'nesen',
  'nesit',
  'nesta',
  'nesti',
  'nečeg',
  'nečem',
  'nečim',
  'nešto',
  'nekim',
  'nekog',
  'nekom',
  'netko',
  'netom',
  'netto',
  'neuka',
  'neuke',
  'neuki',
  'neuko',
  'neuku',
  'neven',
  'nevin',
  'neživ',
  'nicah',
  'nicao',
  'nicat',
  'ničem',
  'ničeš',
  'nijek',
  'nijem',
  'nikim',
  'nikad',
  'nikal',
  'nikao',
  'nikla',
  'nikne',
  'nikni',
  'niknu',
  'nikša',
  'nikud',
  'nimac',
  'nimfa',
  'nimfe',
  'nimfi',
  'ninić',
  'ninja',
  'nisak',
  'nisam',
  'nisci',
  'niska',
  'niske',
  'niski',
  'nisko',
  'nisku',
  'niski',
  'nisko',
  'nišom',
  'nišan',
  'ničeg',
  'ničim',
  'ništa',
  'ništi',
  'nikog',
  'nitko',
  'nitne',
  'nitni',
  'nitro',
  'nivoa',
  'nivoe',
  'nivoi',
  'nivou',
  'nivoi',
  'nizom',
  'niska',
  'niske',
  'nisku',
  'nizak',
  'nizan',
  'nizah',
  'nizao',
  'nizat',
  'nižem',
  'nižeš',
  'nižeg',
  'nižim',
  'nižoj',
  'nižom',
  'nižih',
  'noćcu',
  'noćas',
  'noćca',
  'noćah',
  'noćim',
  'noćio',
  'noćiš',
  'noćit',
  'noćci',
  'noćna',
  'noćne',
  'noćni',
  'noćno',
  'noćnu',
  'noćno',
  'nogom',
  'nojem',
  'nojev',
  'nokat',
  'nokta',
  'nokte',
  'nokti',
  'noktu',
  'noksa',
  'nokse',
  'nomad',
  'nonij',
  'norcu',
  'norma',
  'norme',
  'normi',
  'normo',
  'normu',
  'north',
  'nosom',
  'nosac',
  'nosač',
  'nosan',
  'nosat',
  'nosaj',
  'nosam',
  'nosao',
  'nosaš',
  'nosić',
  'nosim',
  'nosio',
  'nosiš',
  'nosit',
  'nošah',
  'nosiv',
  'nosna',
  'nosne',
  'nosni',
  'nosno',
  'nosnu',
  'nošen',
  'notom',
  'notna',
  'notne',
  'notnu',
  'notar',
  'notes',
  'notni',
  'notno',
  'novac',
  'novca',
  'novce',
  'novci',
  'novcu',
  'novak',
  'novih',
  'novim',
  'novog',
  'novoj',
  'novom',
  'novin',
  'nožem',
  'nožar',
  'nožić',
  'nožna',
  'nožne',
  'nožni',
  'nožno',
  'nožnu',
  'npkan',
  'nskan',
  'nssol',
  'nudim',
  'nudio',
  'nudiš',
  'nudit',
  'nuđač',
  'nuđen',
  'nugat',
  'nujan',
  'nujno',
  'nukah',
  'nukaj',
  'nukam',
  'nukaš',
  'nukat',
  'nukan',
  'nukao',
  'nulom',
  'nulta',
  'nulte',
  'nulti',
  'nulto',
  'nultu',
  'nutka',
  'nužan',
  'nužna',
  'nužne',
  'nužni',
  'nužno',
  'nužnu',
  'nužda',
  'nužde',
  'nuždi',
  'nuždo',
  'nuždu',
  'nužda',
  'nužno',
  'njaci',
  'njače',
  'njači',
  'njaču',
  'njama',
  'njega',
  'njege',
  'njego',
  'njegu',
  'njezi',
  'njena',
  'njene',
  'njeni',
  'njeno',
  'njenu',
  'njiše',
  'njiši',
  'njišu',
  'njiva',
  'njive',
  'njivi',
  'njivo',
  'njivu',
  'njive',
  'njoke',
  'njoki',
  'njoka',
  'njuha',
  'njuhe',
  'njuhu',
  'njuše',
  'njupa',
  'njuši',
  'njutn',
  'oazom',
  'obaju',
  'obaći',
  'obada',
  'obade',
  'obadi',
  'obadu',
  'obala',
  'obale',
  'obali',
  'obalo',
  'obalu',
  'obara',
  'obasu',
  'obave',
  'obavi',
  'obdan',
  'obeća',
  'obići',
  'obiđe',
  'obiđi',
  'obiđu',
  'obija',
  'obima',
  'obime',
  'obimi',
  'obimu',
  'obita',
  'obito',
  'obije',
  'obila',
  'obile',
  'obili',
  'obilo',
  'obite',
  'obiti',
  'obiju',
  'objed',
  'oblom',
  'oblak',
  'oblih',
  'oblim',
  'obloj',
  'oblić',
  'oblik',
  'oblio',
  'oblit',
  'oblog',
  'obnoć',
  'oboom',
  'oboda',
  'obode',
  'obodi',
  'obodu',
  'oboja',
  'oboga',
  'oboje',
  'obome',
  'oboji',
  'obola',
  'obole',
  'oboli',
  'obolu',
  'oboma',
  'obora',
  'obore',
  'obori',
  'oboru',
  'obori',
  'obran',
  'obrat',
  'obere',
  'oberi',
  'oberu',
  'obrah',
  'obrao',
  'obraz',
  'obred',
  'obrij',
  'obris',
  'obrne',
  'obrni',
  'obrnu',
  'obrok',
  'obrta',
  'obrte',
  'obrtu',
  'obrti',
  'obrće',
  'obrći',
  'obrću',
  'obrub',
  'obruč',
  'obrva',
  'obrve',
  'obrvi',
  'obrvo',
  'obrvu',
  'obrve',
  'obuče',
  'obuči',
  'obuća',
  'obuće',
  'obući',
  'obućo',
  'obuću',
  'obuci',
  'obuče',
  'obući',
  'obuci',
  'obuka',
  'obuke',
  'obuko',
  'obuku',
  'obuta',
  'obuto',
  'obuje',
  'obuju',
  'obula',
  'obule',
  'obuli',
  'obute',
  'obuti',
  'obuva',
  'obuze',
  'obzir',
  'obzor',
  'ocata',
  'ocean',
  'ocrne',
  'ocrni',
  'ocrti',
  'ocrta',
  'octim',
  'octom',
  'octen',
  'ocvao',
  'očaja',
  'očaje',
  'očaji',
  'očaju',
  'očala',
  'očale',
  'očara',
  'očeše',
  'očešu',
  'očeva',
  'očeve',
  'očevi',
  'očevo',
  'očevu',
  'očiju',
  'očima',
  'očica',
  'očice',
  'očicu',
  'očita',
  'očite',
  'očiti',
  'očitu',
  'očita',
  'očito',
  'očito',
  'očnih',
  'očnim',
  'očnog',
  'očnoj',
  'očnom',
  'očuha',
  'očuhe',
  'očuhu',
  'očusi',
  'očuše',
  'očuhi',
  'očuva',
  'oćute',
  'oćuti',
  'odama',
  'odaha',
  'odaja',
  'odaje',
  'odaji',
  'odaju',
  'odaje',
  'odana',
  'odane',
  'odani',
  'odano',
  'odanu',
  'odano',
  'odape',
  'odara',
  'odari',
  'odaru',
  'odrom',
  'odaju',
  'odala',
  'odale',
  'odali',
  'odalo',
  'odamo',
  'odaše',
  'odate',
  'odati',
  'odaji',
  'odaju',
  'odbih',
  'odbij',
  'odbio',
  'odbit',
  'odbiv',
  'odboj',
  'odbor',
  'odeon',
  'odera',
  'odere',
  'oderi',
  'oderu',
  'odgoj',
  'odići',
  'odiže',
  'odire',
  'odiše',
  'odiši',
  'odišu',
  'odiži',
  'odižu',
  'odjek',
  'odjel',
  'odlih',
  'odlij',
  'odlio',
  'odlit',
  'odliv',
  'odmah',
  'odmak',
  'odmet',
  'odmor',
  'odnio',
  'odnos',
  'odoka',
  'odole',
  'odoli',
  'odora',
  'odore',
  'odori',
  'odoro',
  'odoru',
  'odran',
  'odraz',
  'odred',
  'odrez',
  'odrod',
  'odron',
  'održa',
  'održe',
  'održi',
  'odsad',
  'odših',
  'odšij',
  'odšio',
  'odšit',
  'oduči',
  'oduze',
  'oduže',
  'oduži',
  'odveć',
  'odveo',
  'odvez',
  'odvih',
  'odvij',
  'odvio',
  'odvit',
  'odvod',
  'odvoz',
  'odziv',
  'ofuca',
  'ofuri',
  'ogade',
  'ogadi',
  'oganj',
  'ognje',
  'ogibe',
  'ogibi',
  'ogibu',
  'ogiba',
  'oglas',
  'oglav',
  'ogled',
  'ognja',
  'ognji',
  'ognju',
  'ogole',
  'ogoli',
  'ogrij',
  'ogrne',
  'ogrni',
  'ogrnu',
  'ogrće',
  'ogule',
  'oguli',
  'ohola',
  'ohole',
  'oholi',
  'oholu',
  'oholo',
  'oholo',
  'ohrid',
  'oidie',
  'ojača',
  'ojade',
  'ojadi',
  'ojkao',
  'okama',
  'okače',
  'okači',
  'okaja',
  'okaje',
  'okaju',
  'okane',
  'okani',
  'okapa',
  'okati',
  'okatu',
  'okida',
  'okine',
  'okini',
  'okite',
  'okiti',
  'okuje',
  'okuju',
  'oklop',
  'oknom',
  'okolo',
  'okopa',
  'okore',
  'okori',
  'okota',
  'okote',
  'okoti',
  'okotu',
  'okova',
  'okove',
  'okovi',
  'okovu',
  'okovi',
  'okrao',
  'okrek',
  'okret',
  'okrpi',
  'okrug',
  'oksid',
  'oksim',
  'okata',
  'oktan',
  'oktav',
  'oktet',
  'okuka',
  'okuke',
  'okuko',
  'okuku',
  'okunj',
  'okupu',
  'okupa',
  'okupe',
  'okupi',
  'okusa',
  'okuse',
  'okusi',
  'okusu',
  'okuša',
  'okuci',
  'okuže',
  'okvir',
  'olama',
  'olako',
  'oleum',
  'oliba',
  'oliče',
  'oliči',
  'olića',
  'oliga',
  'olige',
  'olimp',
  'oliva',
  'ološu',
  'ološa',
  'ološi',
  'olovn',
  'olova',
  'olovo',
  'olovu',
  'oltar',
  'oluja',
  'oluje',
  'oluji',
  'olujo',
  'oluju',
  'oluci',
  'oluče',
  'oluka',
  'oluke',
  'oluku',
  'olupi',
  'omima',
  'omace',
  'omaci',
  'omaći',
  'omače',
  'omama',
  'omame',
  'omami',
  'omane',
  'omanu',
  'omara',
  'omare',
  'omari',
  'omaro',
  'omaru',
  'omata',
  'omaza',
  'omčom',
  'omeđe',
  'omeđi',
  'omega',
  'omela',
  'omele',
  'omeli',
  'omelo',
  'omest',
  'omete',
  'ometi',
  'ometu',
  'ometa',
  'omiču',
  'omiša',
  'omili',
  'omjer',
  'omlet',
  'omoče',
  'omoči',
  'omota',
  'omote',
  'omoti',
  'omotu',
  'omrse',
  'omrsi',
  'omska',
  'omski',
  'omsko',
  'njega',
  'njemu',
  'njime',
  'njome',
  'onima',
  'onoga',
  'onome',
  'onomu',
  'onako',
  'onamo',
  'ončas',
  'ondje',
  'njima',
  'njima',
  'oniks',
  'oniži',
  'onlej',
  'onuda',
  'oolit',
  'opada',
  'opaja',
  'opaka',
  'opake',
  'opaki',
  'opaku',
  'opaki',
  'opako',
  'opako',
  'opala',
  'opale',
  'opali',
  'opalu',
  'opare',
  'opasa',
  'opala',
  'opalo',
  'opast',
  'opaši',
  'opašu',
  'opata',
  'opate',
  'opati',
  'opatu',
  'opaše',
  'opaze',
  'opazi',
  'opaža',
  'općeg',
  'općem',
  'općih',
  'općim',
  'općoj',
  'općom',
  'općin',
  'općah',
  'općen',
  'općim',
  'općio',
  'općiš',
  'općit',
  'opeci',
  'opeče',
  'opeći',
  'opeku',
  'opeka',
  'opeke',
  'opeko',
  'opera',
  'opere',
  'operi',
  'operu',
  'ophod',
  'opica',
  'opiju',
  'opija',
  'opipa',
  'opipe',
  'opipi',
  'opipu',
  'opire',
  'opiri',
  'opiru',
  'opisa',
  'opise',
  'opisi',
  'opisu',
  'opisa',
  'opiše',
  'opiši',
  'opišu',
  'opita',
  'opitu',
  'opije',
  'opila',
  'opile',
  'opili',
  'opilo',
  'opite',
  'opiti',
  'opito',
  'opkop',
  'opleo',
  'oplet',
  'opnom',
  'opora',
  'opore',
  'opori',
  'oporu',
  'oporo',
  'oporo',
  'opran',
  'operi',
  'operu',
  'oprah',
  'oprao',
  'oprat',
  'oprez',
  'opreš',
  'oprže',
  'oprži',
  'oprem',
  'opseg',
  'opsuj',
  'opšav',
  'opših',
  'opšij',
  'opšim',
  'opšio',
  'opšit',
  'optok',
  'opuga',
  'opusa',
  'opuse',
  'opusi',
  'opusu',
  'oputa',
  'oputo',
  'oputu',
  'opute',
  'orača',
  'orače',
  'oraču',
  'orači',
  'oraći',
  'orada',
  'orade',
  'oradi',
  'orado',
  'oradu',
  'oraha',
  'orahe',
  'orahu',
  'orasi',
  'orasi',
  'orala',
  'orali',
  'oralo',
  'orana',
  'orano',
  'oraše',
  'orati',
  'orimo',
  'orite',
  'orući',
  'orden',
  'oreni',
  'orenu',
  'oreol',
  'orfej',
  'orfeo',
  'organ',
  'orgij',
  'oremo',
  'orete',
  'oriks',
  'orisi',
  'orisa',
  'orišu',
  'oreno',
  'orila',
  'orile',
  'orili',
  'orilo',
  'orimo',
  'oriše',
  'orite',
  'oriti',
  'orkan',
  'orlom',
  'orlić',
  'orlov',
  'orama',
  'ormom',
  'ormar',
  'orobe',
  'orobi',
  'oroče',
  'oroči',
  'oronu',
  'orose',
  'orosi',
  'oroza',
  'oroze',
  'orozi',
  'orozu',
  'ortak',
  'oruđa',
  'oruđe',
  'oruđu',
  'oruža',
  'osiju',
  'osima',
  'osama',
  'osama',
  'osame',
  'osami',
  'osamo',
  'osamu',
  'osame',
  'oslom',
  'oseci',
  'oseka',
  'oseke',
  'oseki',
  'oseko',
  'oseku',
  'osica',
  'osice',
  'osici',
  'osicu',
  'osion',
  'osipa',
  'osipi',
  'osipa',
  'osili',
  'osuje',
  'osjem',
  'osjet',
  'oslad',
  'oslić',
  'oslon',
  'osman',
  'osmih',
  'osmim',
  'osmog',
  'osmoj',
  'osmom',
  'osmij',
  'osnih',
  'osnoj',
  'osnom',
  'osnov',
  'osnuj',
  'osoba',
  'osobe',
  'osobi',
  'osobo',
  'osobu',
  'osora',
  'osove',
  'osovi',
  'ostaj',
  'osnim',
  'ostah',
  'ostao',
  'ostat',
  'ostav',
  'ostve',
  'osuda',
  'osude',
  'osudi',
  'osudo',
  'osudu',
  'osudi',
  'osuše',
  'osuši',
  'osuta',
  'osuto',
  'ospem',
  'ospeš',
  'osula',
  'osule',
  'osuli',
  'osulo',
  'osute',
  'osuti',
  'osvit',
  'osvrt',
  'ošine',
  'ošini',
  'ošinu',
  'ošiša',
  'ošita',
  'ošite',
  'ošiti',
  'ošitu',
  'oštar',
  'oštra',
  'oštre',
  'oštro',
  'oštru',
  'oštri',
  'oštro',
  'ocima',
  'očeva',
  'očeve',
  'očevi',
  'otaca',
  'otcem',
  'otada',
  'otapa',
  'otare',
  'otari',
  'otaru',
  'otale',
  'otava',
  'otave',
  'otavi',
  'otavo',
  'otavu',
  'otčin',
  'oteci',
  'oteče',
  'oteći',
  'oteže',
  'oteku',
  'oteti',
  'otele',
  'oteli',
  'otepe',
  'otepi',
  'otepu',
  'otesa',
  'oteše',
  'oteta',
  'otete',
  'oteto',
  'otela',
  'oteli',
  'otelo',
  'oteše',
  'otete',
  'oteti',
  'oteži',
  'otežu',
  'oteža',
  'other',
  'otiče',
  'otiči',
  'otiču',
  'odemo',
  'odete',
  'odimo',
  'odite',
  'odoše',
  'otići',
  'otiđe',
  'otiđi',
  'otiđu',
  'otima',
  'otire',
  'otira',
  'otiru',
  'otkad',
  'otkaz',
  'otkop',
  'otkos',
  'otkov',
  'otkuj',
  'otkri',
  'otkud',
  'otkup',
  'otmeš',
  'otoči',
  'otoci',
  'otoče',
  'otoka',
  'otoke',
  'otoku',
  'otoma',
  'otope',
  'otopi',
  'otpad',
  'otpao',
  'otpao',
  'otpis',
  'otpor',
  'otrca',
  'otrča',
  'otrče',
  'otrči',
  'otrže',
  'otrem',
  'otrov',
  'otruj',
  'otrta',
  'otrto',
  'otrtu',
  'otrla',
  'otrte',
  'otrti',
  'otući',
  'otuda',
  'otuđe',
  'otuđi',
  'otupe',
  'otupi',
  'otvor',
  'ovima',
  'ovime',
  'ovoga',
  'ovome',
  'ovomu',
  'ovako',
  'ovala',
  'ovale',
  'ovali',
  'ovalu',
  'ovamo',
  'ovana',
  'ovnom',
  'ovasa',
  'ovati',
  'ovaca',
  'ovcom',
  'ovčar',
  'ovčas',
  'ovčja',
  'ovčje',
  'ovčji',
  'ovčju',
  'ovdje',
  'oveća',
  'oveće',
  'oveći',
  'ovija',
  'ovise',
  'ovisi',
  'ovita',
  'ovito',
  'ovici',
  'oviče',
  'ovije',
  'oviju',
  'ovila',
  'ovile',
  'ovili',
  'ovilo',
  'oviše',
  'ovite',
  'oviti',
  'ovjes',
  'ovlaš',
  'ovnov',
  'ovoja',
  'ovoje',
  'ovoji',
  'ovoju',
  'ovrha',
  'ovrhe',
  'ovrho',
  'ovrhu',
  'ovrsi',
  'ovrši',
  'ovski',
  'ovuda',
  'ozalj',
  'ozare',
  'ozari',
  'ozdol',
  'ozebe',
  'ozebu',
  'ozida',
  'ozima',
  'ozime',
  'ozimi',
  'ozimo',
  'ozimu',
  'oziru',
  'ozona',
  'ozone',
  'ozoni',
  'ozonu',
  'ozren',
  'ožeme',
  'ožene',
  'oženi',
  'ožetu',
  'ožeti',
  'ožega',
  'ožive',
  'oživi',
  'oživi',
  'ožmem',
  'pacer',
  'packa',
  'packe',
  'packi',
  'packo',
  'packu',
  'pačah',
  'pačaj',
  'pačam',
  'pačao',
  'pačaš',
  'pačat',
  'pačić',
  'pačja',
  'pačje',
  'pačji',
  'pačju',
  'pakli',
  'padom',
  'padan',
  'padah',
  'padaj',
  'padam',
  'padao',
  'padaš',
  'padat',
  'padež',
  'palce',
  'pagan',
  'pagar',
  'pašeš',
  'pajac',
  'pajet',
  'pajzl',
  'pakom',
  'pakao',
  'pakla',
  'pakle',
  'paklu',
  'paker',
  'paket',
  'pakuj',
  'pakta',
  'paktu',
  'pakta',
  'palac',
  'palca',
  'palci',
  'palcu',
  'palče',
  'palež',
  'palim',
  'palir',
  'palio',
  'pališ',
  'palit',
  'palma',
  'palme',
  'palmi',
  'palmo',
  'palmu',
  'palom',
  'paloš',
  'palto',
  'pamet',
  'pampa',
  'pamte',
  'pamti',
  'pamuk',
  'panam',
  'panda',
  'pande',
  'pandi',
  'pando',
  'pandu',
  'panel',
  'panić',
  'panoa',
  'panoe',
  'panoi',
  'panou',
  'panoi',
  'panta',
  'panti',
  'pantu',
  'panus',
  'panja',
  'panji',
  'panju',
  'panja',
  'panje',
  'panju',
  'paola',
  'paolo',
  'papom',
  'papak',
  'papci',
  'papka',
  'papar',
  'papra',
  'papru',
  'papić',
  'papin',
  'papir',
  'papku',
  'papke',
  'papri',
  'parom',
  'paraf',
  'paran',
  'parno',
  'parah',
  'paraj',
  'param',
  'parao',
  'paraš',
  'parat',
  'parbu',
  'parče',
  'pared',
  'parfe',
  'parić',
  'parip',
  'paris',
  'parah',
  'pario',
  'parci',
  'parka',
  'parku',
  'parka',
  'parke',
  'parki',
  'parko',
  'parna',
  'parne',
  'parni',
  'parno',
  'parnu',
  'paroh',
  'parun',
  'pasom',
  'psima',
  'pasac',
  'pasah',
  'pasao',
  'pasat',
  'pasaž',
  'pasem',
  'paseš',
  'pashe',
  'pashi',
  'pashu',
  'pasiv',
  'pasja',
  'pasje',
  'pasji',
  'paska',
  'pasku',
  'paski',
  'pasmo',
  'pasoš',
  'pasta',
  'paste',
  'pasto',
  'pastu',
  'padne',
  'padni',
  'padnu',
  'padoh',
  'pasah',
  'pasle',
  'pasli',
  'paslo',
  'pasti',
  'pasus',
  'pašem',
  'pašom',
  'pašče',
  'pašin',
  'paška',
  'paški',
  'pašku',
  'pašni',
  'pašta',
  'pašte',
  'pašti',
  'pašto',
  'paštu',
  'patak',
  'patci',
  'patke',
  'pater',
  'patim',
  'patio',
  'patiš',
  'patit',
  'patka',
  'patke',
  'patki',
  'patko',
  'patku',
  'patos',
  'pauci',
  'pauče',
  'pauka',
  'pauke',
  'pauku',
  'paula',
  'paula',
  'paulo',
  'paulu',
  'pauna',
  'paune',
  'pauni',
  'paunu',
  'pause',
  'pauza',
  'pauze',
  'pauzi',
  'pauzo',
  'pauzu',
  'pavla',
  'pavel',
  'pavić',
  'pavit',
  'pavši',
  'pazar',
  'pazin',
  'pazim',
  'pazio',
  'paziš',
  'pazit',
  'pažah',
  'pazuh',
  'pažem',
  'pažen',
  'pčela',
  'pčele',
  'pčeli',
  'pčelo',
  'pčelu',
  'pčele',
  'pecan',
  'pecah',
  'pecaj',
  'pecam',
  'pecao',
  'pecaš',
  'pecat',
  'pecek',
  'pecka',
  'pecne',
  'pecni',
  'pecnu',
  'pečom',
  'pečat',
  'pečen',
  'pećar',
  'pećen',
  'pečem',
  'pečeš',
  'pekao',
  'pekla',
  'pekle',
  'pekli',
  'peklo',
  'pedel',
  'peder',
  'pegla',
  'pegle',
  'pegli',
  'peglu',
  'pegla',
  'pehom',
  'pehar',
  'pekom',
  'pekan',
  'pekar',
  'pelat',
  'pelen',
  'pelet',
  'pelig',
  'pelin',
  'pelir',
  'pelud',
  'penal',
  'penem',
  'penis',
  'penon',
  'penje',
  'penji',
  'penju',
  'pepeo',
  'perač',
  'perad',
  'perca',
  'perce',
  'percu',
  'perda',
  'perec',
  'peres',
  'periv',
  'perja',
  'perje',
  'perju',
  'perji',
  'perla',
  'perle',
  'perli',
  'perlo',
  'perlu',
  'perom',
  'peroj',
  'peron',
  'peruu',
  'perut',
  'pesar',
  'pešću',
  'pesti',
  'pešća',
  'petom',
  'petak',
  'petci',
  'petče',
  'petka',
  'petke',
  'petku',
  'peter',
  'petih',
  'petim',
  'petog',
  'petoj',
  'petom',
  'petit',
  'petna',
  'petne',
  'petnu',
  'petre',
  'phone',
  'photo',
  'piano',
  'picom',
  'picek',
  'pičci',
  'pička',
  'pičke',
  'pički',
  'pičko',
  'pičku',
  'pićem',
  'pijan',
  'pijuk',
  'pijun',
  'pikom',
  'pikaj',
  'pikao',
  'pikaš',
  'pikat',
  'pilac',
  'pilad',
  'pilan',
  'pilar',
  'pilav',
  'pilić',
  'pilim',
  'pilio',
  'piliš',
  'pilit',
  'pilom',
  'pilon',
  'pilot',
  'pilje',
  'pilji',
  'pinca',
  'pinen',
  'pinkl',
  'pinta',
  'pinte',
  'pinti',
  'pinto',
  'pintu',
  'piona',
  'pipom',
  'pipac',
  'pipca',
  'pipce',
  'pipci',
  'pipcu',
  'pipče',
  'pipak',
  'pipka',
  'pipan',
  'pipah',
  'pipaj',
  'pipam',
  'pipao',
  'pipaš',
  'pipat',
  'pipav',
  'pipka',
  'pipne',
  'pipni',
  'pipun',
  'pirom',
  'piran',
  'pirat',
  'pirea',
  'piree',
  'pirei',
  'pireu',
  'piren',
  'pirid',
  'pirit',
  'pirja',
  'pirka',
  'pirni',
  'pirol',
  'pirot',
  'pisac',
  'pisca',
  'pisce',
  'pisci',
  'piscu',
  'pišče',
  'pisač',
  'pisak',
  'piska',
  'piske',
  'pisan',
  'pisne',
  'pisnu',
  'pisar',
  'pisah',
  'pisao',
  'pisat',
  'pišem',
  'pišeš',
  'piske',
  'piska',
  'piski',
  'pisma',
  'pismo',
  'pismu',
  'pisni',
  'pista',
  'piste',
  'pisti',
  'pisto',
  'pistu',
  'pišah',
  'pišaj',
  'pišam',
  'pišao',
  'pišaš',
  'pišat',
  'piški',
  'pište',
  'pišti',
  'pišta',
  'pitak',
  'pitke',
  'pitki',
  'pitko',
  'pitku',
  'pitan',
  'pitah',
  'pitaj',
  'pitam',
  'pitao',
  'pitaš',
  'pitat',
  'pijah',
  'pijem',
  'pitka',
  'pitki',
  'pitko',
  'pitom',
  'piton',
  'pivar',
  'pivom',
  'pivot',
  'pivši',
  'pizma',
  'pizda',
  'pizde',
  'pizdi',
  'pizdo',
  'pizdu',
  'pizza',
  'pizze',
  'pizzu',
  'pjega',
  'pjege',
  'pjegi',
  'pjego',
  'pjegu',
  'pjena',
  'pjene',
  'pjeni',
  'pjeno',
  'pjenu',
  'piješ',
  'pijmo',
  'pijte',
  'pjeva',
  'pjeve',
  'pjevi',
  'pjevu',
  'pjeva',
  'placa',
  'place',
  'placu',
  'plača',
  'plaču',
  'plaća',
  'plaće',
  'plaći',
  'plaćo',
  'plaću',
  'placi',
  'plaha',
  'plahe',
  'plahi',
  'plaho',
  'plahu',
  'plaho',
  'plaje',
  'plaka',
  'plaku',
  'plače',
  'plači',
  'plaču',
  'plana',
  'planu',
  'plane',
  'plani',
  'planu',
  'plast',
  'plaše',
  'plaši',
  'plašt',
  'plata',
  'plate',
  'plati',
  'plato',
  'platu',
  'plate',
  'plati',
  'plato',
  'plava',
  'plavi',
  'plavu',
  'plavo',
  'plave',
  'plavi',
  'plaza',
  'plazu',
  'plaze',
  'plazi',
  'plaža',
  'plaže',
  'plaži',
  'plažo',
  'plažu',
  'pleća',
  'pleha',
  'plehu',
  'pleme',
  'plesa',
  'plese',
  'plesu',
  'pleše',
  'pleši',
  'plešu',
  'plele',
  'pleli',
  'plest',
  'plete',
  'pleti',
  'pletu',
  'plela',
  'plelo',
  'plića',
  'plići',
  'plika',
  'plike',
  'pliku',
  'plima',
  'plime',
  'plimi',
  'plimo',
  'plimu',
  'plina',
  'pline',
  'plini',
  'plinu',
  'plise',
  'pliša',
  'pliše',
  'plišu',
  'pliće',
  'pliću',
  'plive',
  'plivi',
  'plivu',
  'pliva',
  'ploča',
  'ploče',
  'ploči',
  'pločo',
  'ploču',
  'ploda',
  'plode',
  'plodu',
  'plodi',
  'ploha',
  'plohe',
  'plohi',
  'ploho',
  'plohu',
  'plosi',
  'plota',
  'plove',
  'plovi',
  'pluća',
  'plući',
  'pluću',
  'pluga',
  'pluge',
  'plugu',
  'pluzi',
  'pluže',
  'plusa',
  'plusu',
  'pluta',
  'pluto',
  'plutu',
  'pluži',
  'pljas',
  'pljes',
  'pljus',
  'pljuj',
  'pobij',
  'pobih',
  'pobio',
  'pobit',
  'pobiv',
  'pobol',
  'pobra',
  'počan',
  'poček',
  'počeh',
  'počeo',
  'počet',
  'počev',
  'počne',
  'počni',
  'počnu',
  'pođem',
  'pođeš',
  'pođoh',
  'pošao',
  'pošav',
  'pošla',
  'pošle',
  'pošli',
  'pošlo',
  'podom',
  'podan',
  'podao',
  'podla',
  'podle',
  'podlo',
  'podlu',
  'podah',
  'podaj',
  'podam',
  'podaš',
  'podat',
  'podbo',
  'podij',
  'podli',
  'podlo',
  'podne',
  'podna',
  'podne',
  'podni',
  'podno',
  'podnu',
  'podno',
  'podug',
  'poema',
  'poeme',
  'poemi',
  'poemo',
  'poemu',
  'poena',
  'poene',
  'poeni',
  'poenu',
  'poeta',
  'poetu',
  'pogan',
  'pogna',
  'pogne',
  'pogni',
  'pognu',
  'pogon',
  'pohan',
  'pohah',
  'pohaj',
  'poham',
  'pohao',
  'pohaš',
  'pohat',
  'pohod',
  'poima',
  'point',
  'pojem',
  'pojac',
  'pojam',
  'pojma',
  'pojme',
  'pojmu',
  'pojas',
  'pojan',
  'pojao',
  'pojat',
  'pojmo',
  'pojte',
  'pojeo',
  'pojim',
  'pojio',
  'pojiš',
  'pojit',
  'pojmi',
  'pojna',
  'pojni',
  'pokoj',
  'pokom',
  'pokaj',
  'pokal',
  'pokaz',
  'poker',
  'pokla',
  'pokop',
  'pokos',
  'pokri',
  'pokus',
  'polom',
  'polan',
  'polen',
  'polet',
  'polić',
  'polio',
  'polip',
  'polih',
  'polij',
  'polio',
  'polit',
  'polka',
  'polke',
  'polki',
  'polko',
  'polku',
  'polni',
  'polog',
  'polja',
  'polje',
  'polju',
  'pomak',
  'poman',
  'pomna',
  'pomne',
  'pomni',
  'pomnu',
  'pomeo',
  'pomet',
  'pomno',
  'pomoć',
  'pomol',
  'pomor',
  'pompa',
  'pompe',
  'pompi',
  'pompo',
  'pompu',
  'pomre',
  'pomri',
  'pomro',
  'pomru',
  'ponad',
  'pončo',
  'ponio',
  'ponoć',
  'ponor',
  'ponos',
  'ponte',
  'poora',
  'popom',
  'popao',
  'popeh',
  'popeo',
  'popet',
  'popev',
  'popić',
  'popis',
  'popih',
  'popij',
  'popio',
  'popit',
  'popne',
  'popni',
  'popnu',
  'popov',
  'popuj',
  'popra',
  'poput',
  'porom',
  'porat',
  'poraz',
  'pored',
  'porez',
  'porin',
  'poren',
  'porim',
  'poriv',
  'porna',
  'porne',
  'porni',
  'porno',
  'porod',
  'porok',
  'porta',
  'porte',
  'porti',
  'porto',
  'portu',
  'porto',
  'porub',
  'posan',
  'posao',
  'posla',
  'posle',
  'posli',
  'poslu',
  'posij',
  'posip',
  'posna',
  'posne',
  'posni',
  'posno',
  'posnu',
  'pospe',
  'posta',
  'postu',
  'posta',
  'poste',
  'posti',
  'posto',
  'pospi',
  'pospu',
  'posuh',
  'posuo',
  'posut',
  'posve',
  'pošta',
  'pošte',
  'pošti',
  'pošto',
  'poštu',
  'pošto',
  'potez',
  'potci',
  'potka',
  'potke',
  'potki',
  'potko',
  'potku',
  'potok',
  'potom',
  'potop',
  'potrk',
  'pouci',
  'pouče',
  'pouči',
  'pouka',
  'pouke',
  'pouko',
  'pouku',
  'poveo',
  'povez',
  'povik',
  'povih',
  'povij',
  'povio',
  'povit',
  'povod',
  'povoj',
  'povrh',
  'pozom',
  'pozan',
  'pozer',
  'poziv',
  'pozli',
  'pozna',
  'pozor',
  'pozva',
  'požar',
  'požeh',
  'požeo',
  'požet',
  'požme',
  'požmi',
  'požmu',
  'požun',
  'ppdiv',
  'prama',
  'praća',
  'praga',
  'prage',
  'pragu',
  'prazi',
  'praže',
  'praha',
  'prahe',
  'prahu',
  'praše',
  'prani',
  'prnju',
  'prasa',
  'prase',
  'prase',
  'praše',
  'praši',
  'perem',
  'pereš',
  'prala',
  'prale',
  'prali',
  'pralo',
  'praše',
  'prate',
  'prati',
  'prate',
  'prati',
  'prava',
  'prave',
  'pravi',
  'pravo',
  'pravu',
  'prave',
  'pravi',
  'prava',
  'pravo',
  'pravu',
  'praze',
  'prazi',
  'prčka',
  'prčka',
  'prćen',
  'prćah',
  'prćim',
  'prćio',
  'prćiš',
  'prćit',
  'prdac',
  'prdež',
  'prdim',
  'prdio',
  'prdiš',
  'prdne',
  'prdni',
  'prdnu',
  'prebi',
  'preča',
  'preče',
  'preči',
  'preču',
  'preda',
  'preci',
  'preda',
  'pređa',
  'pređe',
  'pređi',
  'pređo',
  'pređu',
  'preže',
  'preko',
  'prelu',
  'prela',
  'preli',
  'prelo',
  'prema',
  'preni',
  'prene',
  'preni',
  'prenu',
  'presa',
  'press',
  'prede',
  'predi',
  'predu',
  'prele',
  'prest',
  'preša',
  'preše',
  'preši',
  'prešo',
  'prešu',
  'preša',
  'previ',
  'prezl',
  'prgav',
  'prhak',
  'prhke',
  'prhki',
  'prhko',
  'prhne',
  'prhni',
  'prhnu',
  'prhut',
  'pribi',
  'prica',
  'priča',
  'priče',
  'priči',
  'pričo',
  'priču',
  'priče',
  'prići',
  'priđe',
  'priđi',
  'priđu',
  'prida',
  'prija',
  'prije',
  'priji',
  'prijo',
  'priju',
  'prije',
  'prima',
  'prime',
  'primi',
  'princ',
  'prion',
  'prior',
  'prisu',
  'priši',
  'prišt',
  'prita',
  'priti',
  'prici',
  'privi',
  'prkos',
  'prlić',
  'prlja',
  'prnja',
  'prnji',
  'prnjo',
  'prnje',
  'proba',
  'probe',
  'probi',
  'probo',
  'probu',
  'proći',
  'prođe',
  'prođi',
  'prođu',
  'proda',
  'prođa',
  'prođe',
  'prođu',
  'proha',
  'proji',
  'prola',
  'proli',
  'prole',
  'prolo',
  'prolu',
  'prose',
  'prosi',
  'prosa',
  'proso',
  'prosu',
  'prost',
  'proše',
  'proši',
  'prota',
  'prote',
  'proti',
  'proto',
  'protu',
  'protr',
  'protu',
  'provi',
  'proza',
  'proze',
  'prozi',
  'prozo',
  'prozu',
  'prože',
  'prpić',
  'prsat',
  'prsio',
  'prska',
  'prski',
  'prsna',
  'prsne',
  'prsni',
  'prsno',
  'prsnu',
  'prsta',
  'prste',
  'prsti',
  'prstu',
  'prsta',
  'pršić',
  'pršti',
  'pršte',
  'pršut',
  'prten',
  'pruća',
  'pruće',
  'pruću',
  'pruga',
  'pruge',
  'prugo',
  'prugu',
  'pruzi',
  'prusa',
  'pruta',
  'prute',
  'prutu',
  'pruža',
  'pruže',
  'pruži',
  'prvak',
  'prvih',
  'prvim',
  'prvog',
  'prvoj',
  'prvom',
  'pržen',
  'pržah',
  'pržim',
  'pržio',
  'pržiš',
  'pržit',
  'pržun',
  'psari',
  'pseća',
  'pseće',
  'pseći',
  'pseću',
  'pseta',
  'pseto',
  'psetu',
  'psiči',
  'psiču',
  'psiča',
  'psića',
  'psiće',
  'psići',
  'psiću',
  'psiha',
  'psihe',
  'psihi',
  'psihu',
  'psiho',
  'psiče',
  'psina',
  'psine',
  'psini',
  'psino',
  'psinu',
  'psuju',
  'psuje',
  'ptica',
  'ptice',
  'ptici',
  'ptico',
  'pticu',
  'ptiče',
  'ptića',
  'ptiće',
  'ptići',
  'ptiću',
  'ptoza',
  'pucom',
  'pucač',
  'pucan',
  'pucaj',
  'pucam',
  'pucao',
  'pucaš',
  'pucat',
  'pucka',
  'pucne',
  'pučem',
  'pučka',
  'pučke',
  'pučki',
  'pučko',
  'pučku',
  'pukao',
  'pukla',
  'pukle',
  'puklo',
  'pukoh',
  'puder',
  'pudle',
  'pudla',
  'pudli',
  'pudra',
  'pufer',
  'puhom',
  'puhan',
  'puhah',
  'puhao',
  'puhat',
  'pušem',
  'puhne',
  'puhni',
  'puhnu',
  'puhor',
  'puhov',
  'pukom',
  'pukli',
  'pukih',
  'pukim',
  'pukog',
  'pukoj',
  'pukao',
  'pukla',
  'pukle',
  'puklo',
  'pukne',
  'pukni',
  'puknu',
  'pukoh',
  'pulić',
  'pulpa',
  'pulpe',
  'pulpi',
  'pulpo',
  'pulpu',
  'pulsa',
  'pulse',
  'pulsu',
  'pulta',
  'pulte',
  'pultu',
  'pumom',
  'pumpa',
  'pumpe',
  'pumpi',
  'pumpo',
  'pumpu',
  'punih',
  'punim',
  'punac',
  'punca',
  'puncu',
  'punan',
  'punču',
  'punča',
  'punđa',
  'punđe',
  'punđi',
  'punđo',
  'punđu',
  'punog',
  'punoj',
  'punom',
  'punim',
  'punio',
  'puniš',
  'punit',
  'punkt',
  'punta',
  'pupom',
  'pupak',
  'pupci',
  'pupka',
  'pupku',
  'pupat',
  'purom',
  'puran',
  'purić',
  'purin',
  'pusom',
  'pušeš',
  'pustu',
  'pusta',
  'puste',
  'pusto',
  'pusta',
  'pusti',
  'puste',
  'pusti',
  'pušač',
  'pušen',
  'pušić',
  'pušah',
  'puših',
  'pušim',
  'pušio',
  'pušiš',
  'pušit',
  'pušci',
  'puška',
  'puške',
  'puški',
  'puško',
  'pušku',
  'pušta',
  'putem',
  'putom',
  'putar',
  'putra',
  'putre',
  'putri',
  'putru',
  'putem',
  'puten',
  'putić',
  'putin',
  'putna',
  'putne',
  'putni',
  'putno',
  'putnu',
  'putuj',
  'puzah',
  'puzao',
  'puzat',
  'pužeš',
  'puzav',
  'puzim',
  'puzio',
  'puziš',
  'puzit',
  'pužem',
  'pužna',
  'pužni',
  'pužnu',
  'pužev',
  'pužić',
  'pužne',
  'pužno',
  'rabat',
  'rabin',
  'rabim',
  'rabio',
  'račić',
  'račja',
  'račje',
  'račji',
  'račju',
  'rački',
  'račun',
  'račva',
  'račve',
  'račvi',
  'račvo',
  'račvu',
  'račve',
  'radom',
  'radan',
  'radnu',
  'radar',
  'raden',
  'radič',
  'radić',
  'radij',
  'radin',
  'radio',
  'radih',
  'radim',
  'radio',
  'radiš',
  'radit',
  'radna',
  'radne',
  'radni',
  'radno',
  'radnu',
  'radon',
  'radoš',
  'raduj',
  'radža',
  'radže',
  'radži',
  'radžo',
  'radžu',
  'rađan',
  'rađah',
  'rađaj',
  'rađam',
  'rađao',
  'rađaš',
  'rađat',
  'rađen',
  'rafal',
  'ragom',
  'ragbi',
  'ragua',
  'rague',
  'ragui',
  'raguu',
  'rahla',
  'rahlu',
  'rahle',
  'rahli',
  'rahlo',
  'raich',
  'rajem',
  'rajom',
  'rajka',
  'rajke',
  'rajne',
  'rajon',
  'rakom',
  'rakel',
  'rakis',
  'rakun',
  'ralom',
  'ralja',
  'ralje',
  'ramom',
  'rampa',
  'rampe',
  'rampi',
  'rampo',
  'rampu',
  'ranih',
  'ranim',
  'ranog',
  'ranom',
  'ranac',
  'ranca',
  'ranci',
  'rancu',
  'ranče',
  'ranar',
  'ranga',
  'range',
  'rangu',
  'ranzi',
  'ranže',
  'ranog',
  'ranoj',
  'ranom',
  'ranik',
  'ranio',
  'ranki',
  'ranku',
  'ranja',
  'ranje',
  'ranju',
  'rapir',
  'rarog',
  'rasom',
  'rasad',
  'rasan',
  'rasne',
  'rasap',
  'rasij',
  'rasip',
  'rasna',
  'rasne',
  'rasni',
  'rasno',
  'rasnu',
  'rasno',
  'rasol',
  'raspe',
  'rasta',
  'rastu',
  'rasla',
  'rasle',
  'rasli',
  'raslo',
  'raste',
  'rasti',
  'rastu',
  'rastu',
  'rasut',
  'raspi',
  'rasuh',
  'rasuo',
  'rasuv',
  'rašće',
  'raših',
  'rašij',
  'rašio',
  'rašit',
  'raška',
  'raške',
  'raški',
  'raško',
  'rašku',
  'rašpa',
  'rašpe',
  'ratom',
  'ratom',
  'ratan',
  'ratar',
  'ratik',
  'ratka',
  'ratna',
  'ratne',
  'ratni',
  'ratno',
  'ratnu',
  'ratuj',
  'ravan',
  'ravna',
  'ravne',
  'ravnu',
  'raven',
  'ravna',
  'ravni',
  'ravno',
  'razom',
  'razan',
  'razbi',
  'razda',
  'razli',
  'razma',
  'razmi',
  'razmu',
  'razna',
  'razne',
  'razni',
  'razno',
  'raznu',
  'razor',
  'razum',
  'razvi',
  'ražju',
  'ražom',
  'ražen',
  'rdbms',
  'rđama',
  'rđaju',
  'rđala',
  'rđati',
  'rđava',
  'rđave',
  'rđavi',
  'rđavo',
  'rđavu',
  'rebar',
  'rebra',
  'rebro',
  'rebru',
  'rebus',
  'recka',
  'recoj',
  'rečen',
  'rečem',
  'rečeš',
  'rekao',
  'rekav',
  'rekla',
  'rekle',
  'rekli',
  'reklo',
  'rekne',
  'rekni',
  'reknu',
  'rekoh',
  'redom',
  'redak',
  'redci',
  'redče',
  'retci',
  'retka',
  'retke',
  'retku',
  'redan',
  'redna',
  'redar',
  'redah',
  'redaj',
  'redam',
  'redao',
  'redaš',
  'redat',
  'redem',
  'redna',
  'redne',
  'redni',
  'redno',
  'rednu',
  'redno',
  'redom',
  'redov',
  'ređaš',
  'reful',
  'regal',
  'reket',
  'relej',
  'remom',
  'remek',
  'remen',
  'remet',
  'remis',
  'remih',
  'renal',
  'renco',
  'renij',
  'renin',
  'renta',
  'rente',
  'renti',
  'rento',
  'rentu',
  'rento',
  'repom',
  'repak',
  'repaš',
  'repat',
  'reper',
  'repić',
  'repna',
  'repne',
  'repni',
  'repno',
  'repnu',
  'repoa',
  'rerna',
  'resin',
  'resih',
  'resim',
  'resio',
  'resiš',
  'resit',
  'reska',
  'reski',
  'resko',
  'resor',
  'resta',
  'reška',
  'reške',
  'reški',
  'rešoa',
  'rešoe',
  'rešoi',
  'rešou',
  'retor',
  'retro',
  'reuma',
  'reume',
  'reumi',
  'reumo',
  'reumu',
  'revan',
  'revne',
  'revni',
  'revnu',
  'revao',
  'rever',
  'revir',
  'revno',
  'rezom',
  'rezač',
  'reska',
  'reske',
  'resku',
  'rezak',
  'rezan',
  'rezah',
  'rezao',
  'rezat',
  'režem',
  'režeš',
  'rezit',
  'rezna',
  'rezne',
  'rezni',
  'rezno',
  'rezol',
  'rezon',
  'režah',
  'režan',
  'režao',
  'režat',
  'režim',
  'režiš',
  'režim',
  'ribom',
  'ribar',
  'ribah',
  'ribam',
  'riban',
  'ribao',
  'ribež',
  'ribič',
  'ribić',
  'ribiz',
  'ričeš',
  'ricin',
  'ričem',
  'ridah',
  'ridaj',
  'ridam',
  'ridao',
  'ridaš',
  'ridat',
  'ridja',
  'ridji',
  'riđih',
  'riđim',
  'riđoj',
  'riđom',
  'riđan',
  'rigao',
  'rigaš',
  'rigat',
  'rigol',
  'rijač',
  'rijas',
  'riječ',
  'rikom',
  'rikah',
  'rikao',
  'rikat',
  'rikne',
  'rikni',
  'riknu',
  'rikša',
  'rikše',
  'rikši',
  'rikšo',
  'rikšu',
  'rilaš',
  'rilom',
  'rimom',
  'rimac',
  'ringa',
  'ringu',
  'rinem',
  'rinuh',
  'rioja',
  'risom',
  'risač',
  'risan',
  'risah',
  'risao',
  'risat',
  'rišem',
  'rišeš',
  'rista',
  'ristu',
  'ritam',
  'ritma',
  'ritme',
  'ritmi',
  'ritmu',
  'ritne',
  'ritao',
  'riter',
  'rijem',
  'riješ',
  'rivim',
  'rivom',
  'rival',
  'riven',
  'rizik',
  'rizji',
  'rizma',
  'rizom',
  'rižom',
  'rižin',
  'rižot',
  'rjeđe',
  'rjeđa',
  'rjeđi',
  'rkman',
  'roads',
  'robom',
  'robom',
  'robić',
  'robin',
  'robim',
  'robio',
  'robiš',
  'robit',
  'robna',
  'robne',
  'robni',
  'robno',
  'robnu',
  'robot',
  'robov',
  'robuj',
  'rocka',
  'ročan',
  'ročna',
  'ročne',
  'ročni',
  'ročna',
  'ročne',
  'ročni',
  'ročnu',
  'ročno',
  'rodom',
  'rodom',
  'rodan',
  'rodeo',
  'rodij',
  'rodih',
  'rodim',
  'rodio',
  'rodiš',
  'rodit',
  'rodna',
  'rodne',
  'rodni',
  'rodno',
  'rodnu',
  'rođak',
  'rođen',
  'rogom',
  'rogač',
  'rogat',
  'roger',
  'rogić',
  'rogoz',
  'rohav',
  'rojem',
  'rojim',
  'rojio',
  'rojiš',
  'rojit',
  'rojsa',
  'rojta',
  'rojte',
  'rojti',
  'rojto',
  'rojtu',
  'rokom',
  'rokći',
  'roker',
  'rokće',
  'rokću',
  'rolom',
  'roler',
  'rolni',
  'roloa',
  'roloe',
  'roloi',
  'rolou',
  'roman',
  'romba',
  'rombu',
  'romea',
  'romeo',
  'romon',
  'ronac',
  'ronda',
  'rondo',
  'ronim',
  'ronio',
  'roniš',
  'ronit',
  'ropac',
  'ropće',
  'rosan',
  'rosna',
  'rosne',
  'rosno',
  'rosim',
  'rosio',
  'rosiš',
  'rosit',
  'rošah',
  'rosni',
  'rosnu',
  'rotim',
  'rotor',
  'rovom',
  'rovca',
  'rovci',
  'rovcu',
  'rovač',
  'rovaš',
  'rovao',
  'rujem',
  'ruješ',
  'rovka',
  'rovke',
  'rovki',
  'rovko',
  'rovku',
  'rovna',
  'rovni',
  'rozga',
  'rozgi',
  'rtova',
  'rtove',
  'rtovi',
  'rtanj',
  'rubom',
  'rubac',
  'rupca',
  'rupce',
  'rupci',
  'rupcu',
  'ruban',
  'rubne',
  'rubin',
  'rubim',
  'rubio',
  'rubiš',
  'rubit',
  'rubna',
  'rubne',
  'rubni',
  'rubno',
  'rubnu',
  'ručak',
  'ručci',
  'ručka',
  'ručku',
  'ručah',
  'ručaj',
  'ručam',
  'ručao',
  'ručaš',
  'ručat',
  'ručin',
  'ručka',
  'ručke',
  'ručki',
  'ručko',
  'ručku',
  'ručna',
  'ručne',
  'ručni',
  'ručno',
  'ručnu',
  'ručno',
  'rukne',
  'rudom',
  'rudar',
  'rudim',
  'rudio',
  'rudiš',
  'rudit',
  'rudna',
  'rudne',
  'rudni',
  'rudno',
  'rudnu',
  'rugah',
  'rugaj',
  'rugam',
  'rugan',
  'rugao',
  'rugaš',
  'rugat',
  'rugla',
  'ruglo',
  'ruglu',
  'ruhom',
  'ruina',
  'ruine',
  'ruini',
  'ruino',
  'ruinu',
  'rujan',
  'rujan',
  'rujna',
  'rujne',
  'rujni',
  'rujno',
  'rujnu',
  'rukom',
  'rukav',
  'rulet',
  'rulna',
  'rulja',
  'rulje',
  'rulji',
  'ruljo',
  'rulju',
  'rumom',
  'rumba',
  'rumen',
  'runom',
  'runda',
  'runde',
  'rundi',
  'rundo',
  'rundu',
  'rupom',
  'rupel',
  'rusoj',
  'rusom',
  'rusin',
  'rusih',
  'rusim',
  'ruska',
  'ruske',
  'ruski',
  'rusko',
  'rusku',
  'rušen',
  'rušah',
  'rušim',
  'rušio',
  'rušiš',
  'rušit',
  'rutom',
  'rutav',
  'ruter',
  'rutil',
  'rutni',
  'rutno',
  'ružem',
  'ružom',
  'ružan',
  'ružna',
  'ružne',
  'ružni',
  'ružno',
  'ružnu',
  'ružin',
  'ružit',
  'ružni',
  'ružno',
  'rvača',
  'rvače',
  'rvači',
  'rvaču',
  'rvaju',
  'rvalo',
  'rvate',
  'rvati',
  'rzahu',
  'rzala',
  'rzali',
  'rzalo',
  'rzamo',
  'rzati',
  'ržite',
  'ržući',
  'sabat',
  'sabih',
  'sabij',
  'sabio',
  'sabit',
  'sabol',
  'sabor',
  'sabra',
  'sačem',
  'sačma',
  'sačme',
  'sačmi',
  'sačmo',
  'sačmu',
  'saćem',
  'sađem',
  'sađoh',
  'sašao',
  'sašav',
  'sašla',
  'sašle',
  'sašli',
  'sašlo',
  'sadim',
  'sadio',
  'sadiš',
  'sadit',
  'sadni',
  'sadon',
  'sadra',
  'sadre',
  'sadri',
  'sadru',
  'sadrt',
  'sađen',
  'safin',
  'safir',
  'sagom',
  'sagne',
  'sagni',
  'sagnu',
  'sagoh',
  'sagoa',
  'sahne',
  'sahni',
  'sahnu',
  'sajam',
  'sajma',
  'sajmu',
  'sajga',
  'sajla',
  'sajle',
  'sajli',
  'sajlo',
  'sajlu',
  'sakat',
  'sakoa',
  'sakoe',
  'sakoi',
  'sakou',
  'sakri',
  'saksu',
  'saksa',
  'salaš',
  'salda',
  'saldi',
  'saldo',
  'saldu',
  'sales',
  'salih',
  'salij',
  'salom',
  'salon',
  'salpi',
  'salpu',
  'salta',
  'salto',
  'saltu',
  'salva',
  'salve',
  'salvi',
  'salvo',
  'salvu',
  'samih',
  'samoj',
  'samac',
  'samca',
  'samce',
  'samci',
  'samcu',
  'samče',
  'samar',
  'samba',
  'samim',
  'samog',
  'samom',
  'samit',
  'samoe',
  'samrt',
  'samta',
  'samte',
  'samtu',
  'samur',
  'snima',
  'snova',
  'snove',
  'snovi',
  'sanci',
  'sanak',
  'sanku',
  'sandi',
  'sands',
  'sanen',
  'sanke',
  'sankt',
  'sante',
  'santi',
  'santu',
  'santa',
  'santo',
  'sanje',
  'sanja',
  'salim',
  'saone',
  'sapom',
  'sapet',
  'sapeh',
  'sapeo',
  'sapet',
  'sapne',
  'sapni',
  'sapnu',
  'sapun',
  'sarom',
  'sarah',
  'saraj',
  'sarka',
  'sarma',
  'sarme',
  'sarmi',
  'sarmo',
  'sarmu',
  'sasma',
  'sasku',
  'saska',
  'saske',
  'saski',
  'sasko',
  'sasta',
  'saspe',
  'saspi',
  'sasuh',
  'sasuo',
  'saših',
  'sašij',
  'sašio',
  'sašit',
  'satju',
  'satom',
  'satru',
  'saten',
  'sajmo',
  'satić',
  'satir',
  'satna',
  'satne',
  'satni',
  'satno',
  'satnu',
  'satno',
  'satrh',
  'satri',
  'satro',
  'satrt',
  'sauna',
  'saune',
  'sauni',
  'sauno',
  'saunu',
  'svega',
  'svemu',
  'svime',
  'savez',
  'savih',
  'savij',
  'savio',
  'savit',
  'savor',
  'saziv',
  'sazuj',
  'sazna',
  'sazre',
  'sazri',
  'sazru',
  'sazva',
  'sažet',
  'sažeh',
  'sažeo',
  'sažme',
  'sažmi',
  'sažmu',
  'scena',
  'scene',
  'sceni',
  'scenu',
  'sceno',
  'sched',
  'scott',
  'sobom',
  'sebra',
  'sebru',
  'sebum',
  'sedam',
  'sedef',
  'sedla',
  'sedla',
  'sedlo',
  'sedlu',
  'sedma',
  'sedme',
  'sedmi',
  'sedmo',
  'sedmu',
  'sedra',
  'sedre',
  'sedri',
  'sedro',
  'sedru',
  'sedre',
  'sefom',
  'seget',
  'sejom',
  'sekom',
  'seksa',
  'seksu',
  'seksi',
  'sekta',
  'sekte',
  'sekti',
  'sekto',
  'sektu',
  'sekte',
  'selca',
  'selci',
  'selcu',
  'selam',
  'selen',
  'selim',
  'selio',
  'seliš',
  'selit',
  'selom',
  'seljo',
  'semem',
  'semiš',
  'senat',
  'senfa',
  'senfe',
  'senfu',
  'senka',
  'senja',
  'senju',
  'seoba',
  'seobe',
  'seobi',
  'seobo',
  'seobu',
  'seoca',
  'seoce',
  'seoul',
  'sepia',
  'sepsa',
  'sepse',
  'sepsi',
  'sepso',
  'sepsu',
  'septa',
  'serač',
  'serij',
  'serin',
  'serum',
  'servo',
  'setom',
  'seter',
  'setup',
  'seven',
  'sever',
  'sezam',
  'sezao',
  'sezaš',
  'sezat',
  'sežem',
  'sezon',
  'sfera',
  'sfere',
  'sferi',
  'sfero',
  'sferu',
  'sfrka',
  'shane',
  'shell',
  'shema',
  'sheme',
  'shemi',
  'shemu',
  'shift',
  'shock',
  'shore',
  'short',
  'shrva',
  'sički',
  'sićom',
  'siđem',
  'siđeš',
  'siđoh',
  'sišao',
  'sišav',
  'sišla',
  'sišle',
  'sišli',
  'sišlo',
  'sidom',
  'sidon',
  'sidra',
  'sidre',
  'sidri',
  'sidra',
  'sidro',
  'sidru',
  'sifon',
  'sigom',
  'siget',
  'sigma',
  'sijač',
  'sijan',
  'sijah',
  'sijam',
  'sijao',
  'sijaš',
  'sijat',
  'sijem',
  'siješ',
  'sijte',
  'sijed',
  'sijev',
  'sijim',
  'sikća',
  'sikće',
  'sikći',
  'sikću',
  'silom',
  'silan',
  'silna',
  'silne',
  'silni',
  'silno',
  'silnu',
  'silaz',
  'silić',
  'silit',
  'silno',
  'silom',
  'silos',
  'siluj',
  'silur',
  'silva',
  'simon',
  'sinom',
  'sinci',
  'sinče',
  'singh',
  'singl',
  'sinku',
  'sinko',
  'sinoć',
  'sinod',
  'sinov',
  'sinus',
  'sinem',
  'sineš',
  'sinuh',
  'sinuo',
  'sinut',
  'sinja',
  'sinje',
  'sinji',
  'sinju',
  'sipom',
  'sipak',
  'sipka',
  'sipke',
  'sipki',
  'sipko',
  'sipku',
  'sipan',
  'sipar',
  'sipah',
  'sipaj',
  'sipao',
  'sipaš',
  'sipat',
  'sipin',
  'sipim',
  'sipio',
  'sipiš',
  'sipit',
  'sirom',
  'sirac',
  'sirca',
  'sirci',
  'sircu',
  'sirak',
  'sirem',
  'sirij',
  'sirim',
  'sirio',
  'siriš',
  'sirit',
  'sirot',
  'sirov',
  'sirup',
  'sisom',
  'sisal',
  'sisan',
  'sisar',
  'sisat',
  'sisah',
  'sisaj',
  'sisam',
  'sisao',
  'sisaš',
  'sišem',
  'sišeš',
  'siski',
  'sitih',
  'sitim',
  'sitog',
  'sitoj',
  'sitan',
  'sitna',
  'sitne',
  'sitni',
  'sitno',
  'sitnu',
  'sitar',
  'sitka',
  'sitne',
  'sitni',
  'sitno',
  'sitom',
  'sivih',
  'sivog',
  'sivoj',
  'sivom',
  'sivca',
  'sivci',
  'sivcu',
  'sivim',
  'sjaha',
  'sjaši',
  'sjašu',
  'sjaja',
  'sjaju',
  'sjaje',
  'sjaji',
  'sjahu',
  'sjaju',
  'sjala',
  'sjale',
  'sjali',
  'sjalo',
  'sjamo',
  'sjano',
  'sjaše',
  'sjate',
  'sjati',
  'sjeba',
  'sjebe',
  'sjebi',
  'sjebu',
  'sječa',
  'sječe',
  'sječi',
  'sječo',
  'sječu',
  'sjeća',
  'sjedu',
  'sjeda',
  'sjedi',
  'sjedi',
  'sjeđi',
  'sjeme',
  'sjena',
  'sjene',
  'sjeni',
  'sjeno',
  'sjenu',
  'sjede',
  'sjela',
  'sjele',
  'sjeli',
  'sjelo',
  'sjest',
  'sjeta',
  'sjete',
  'sjeti',
  'sjeto',
  'sjetu',
  'sjeti',
  'skova',
  'skača',
  'skaču',
  'skače',
  'skači',
  'skala',
  'skale',
  'skali',
  'skalo',
  'skalu',
  'skalp',
  'skapa',
  'skaut',
  'skeča',
  'skeču',
  'skejt',
  'skela',
  'skele',
  'skeli',
  'skelo',
  'skelu',
  'skele',
  'skoga',
  'skica',
  'skice',
  'skici',
  'skico',
  'skicu',
  'skice',
  'skiće',
  'skidu',
  'skida',
  'skija',
  'skije',
  'skiji',
  'skijo',
  'skiju',
  'skije',
  'skide',
  'skine',
  'skini',
  'skinu',
  'skići',
  'skiću',
  'skita',
  'skuje',
  'sklać',
  'sklad',
  'sklat',
  'sklek',
  'skliz',
  'sklon',
  'sklop',
  'skoba',
  'skobe',
  'skobi',
  'skobo',
  'skobu',
  'skoče',
  'skoči',
  'skoka',
  'skoku',
  'skora',
  'skore',
  'skori',
  'skoro',
  'skoru',
  'skota',
  'skote',
  'skoti',
  'skotu',
  'skuju',
  'skrbi',
  'skrbu',
  'skrbe',
  'skrha',
  'skrih',
  'skrij',
  'skrio',
  'skrit',
  'skriv',
  'skrok',
  'skroz',
  'skrpa',
  'skrpe',
  'skrpi',
  'skrše',
  'skrši',
  'skubi',
  'skuca',
  'skuči',
  'skuha',
  'skupa',
  'skupu',
  'skupe',
  'skupo',
  'skupe',
  'skupi',
  'skupo',
  'skuša',
  'skuše',
  'skuši',
  'skušo',
  'skušu',
  'skuta',
  'skute',
  'skuti',
  'skutu',
  'skuta',
  'skuto',
  'slaba',
  'slabe',
  'slabi',
  'slabo',
  'slabu',
  'slabe',
  'slabi',
  'slabo',
  'slaci',
  'slada',
  'slade',
  'sladu',
  'slađe',
  'sladi',
  'slađe',
  'slađa',
  'slađi',
  'slaže',
  'slaži',
  'slažu',
  'slajd',
  'slama',
  'slame',
  'slami',
  'slamo',
  'slamu',
  'slana',
  'slane',
  'slano',
  'slanu',
  'slang',
  'slani',
  'slano',
  'slapa',
  'slape',
  'slapu',
  'slast',
  'slahu',
  'slala',
  'slale',
  'slali',
  'slalo',
  'slaše',
  'slati',
  'šalje',
  'šalji',
  'šalju',
  'slave',
  'slava',
  'slave',
  'slavi',
  'slavo',
  'slavu',
  'slave',
  'slavi',
  'sleže',
  'sledu',
  'slede',
  'sledi',
  'sleđa',
  'sleđu',
  'slejt',
  'sleng',
  'sleta',
  'sleti',
  'slete',
  'sleti',
  'sliče',
  'sliči',
  'slići',
  'sliže',
  'slici',
  'slika',
  'slike',
  'sliko',
  'sliku',
  'slina',
  'sline',
  'slini',
  'slino',
  'slinu',
  'slije',
  'sliju',
  'slila',
  'slili',
  'slilo',
  'slite',
  'sliti',
  'sliva',
  'slive',
  'slivu',
  'sloga',
  'sloge',
  'slogu',
  'slože',
  'sloga',
  'sloge',
  'slogo',
  'slogu',
  'slozi',
  'sloja',
  'sloje',
  'sloju',
  'sloma',
  'slome',
  'slomu',
  'slomi',
  'slona',
  'slone',
  'slonu',
  'slova',
  'slovo',
  'slovu',
  'složi',
  'sluči',
  'sluga',
  'sluge',
  'slugi',
  'slugo',
  'slugu',
  'sluzi',
  'sluha',
  'sluhi',
  'sluhu',
  'sluše',
  'slunj',
  'slupa',
  'slusi',
  'sluša',
  'slute',
  'sluti',
  'sluzi',
  'služe',
  'služi',
  'sljev',
  'sljez',
  'smaći',
  'smaže',
  'smaka',
  'smače',
  'smaza',
  'smaži',
  'smažu',
  'smeča',
  'smeču',
  'smeća',
  'smeće',
  'smeću',
  'smeđa',
  'smeđe',
  'smeđo',
  'smeđu',
  'smeđi',
  'smela',
  'smeli',
  'smesa',
  'smele',
  'smelo',
  'smest',
  'smeta',
  'smete',
  'smeti',
  'smetu',
  'smeta',
  'smiču',
  'smije',
  'smiju',
  'smion',
  'smire',
  'smiri',
  'smili',
  'smjer',
  'smije',
  'smiju',
  'smjeh',
  'smjet',
  'smoče',
  'smoči',
  'smoći',
  'smože',
  'smoga',
  'smoje',
  'smoci',
  'smoku',
  'smola',
  'smole',
  'smoli',
  'smolo',
  'smolu',
  'smota',
  'smrad',
  'smrča',
  'smrče',
  'smrčo',
  'smrči',
  'smrde',
  'smrdi',
  'smrču',
  'smrsi',
  'smrću',
  'smrti',
  'smrve',
  'smrvi',
  'smuca',
  'smuči',
  'smuđa',
  'smuđu',
  'smuša',
  'smute',
  'smuti',
  'snaći',
  'snađe',
  'snađi',
  'snađu',
  'snaga',
  'snage',
  'snagi',
  'snago',
  'snagu',
  'snazi',
  'snaha',
  'snahe',
  'snahi',
  'snaho',
  'snahu',
  'snasi',
  'snast',
  'snaži',
  'snebi',
  'snena',
  'snene',
  'sneni',
  'sneno',
  'snenu',
  'sneno',
  'snese',
  'snesi',
  'snesu',
  'snije',
  'snima',
  'snime',
  'snimi',
  'snilo',
  'sniti',
  'sniva',
  'snize',
  'snizi',
  'snoba',
  'snobe',
  'snobu',
  'snopa',
  'snope',
  'snopu',
  'snose',
  'snosi',
  'snuje',
  'snuju',
  'snšan',
  'snube',
  'snubi',
  'sobom',
  'sobom',
  'sobar',
  'sobna',
  'sobne',
  'sobni',
  'sobno',
  'sobnu',
  'sobom',
  'sočan',
  'sočna',
  'sočne',
  'sočni',
  'sočno',
  'sočnu',
  'sočno',
  'sodom',
  'sofom',
  'sojem',
  'sojom',
  'sojin',
  'sokom',
  'sokak',
  'sokna',
  'sokne',
  'sokni',
  'sokno',
  'soknu',
  'sokne',
  'sokol',
  'solju',
  'soldo',
  'solim',
  'solio',
  'soliš',
  'solit',
  'solna',
  'solne',
  'solni',
  'solno',
  'solnu',
  'solom',
  'somom',
  'somić',
  'somun',
  'sonar',
  'sonda',
  'sonde',
  'sondi',
  'sondo',
  'sondu',
  'sonet',
  'songu',
  'songa',
  'sopću',
  'sopće',
  'sopći',
  'sorta',
  'sorte',
  'sorti',
  'sorto',
  'sortu',
  'sosom',
  'sošna',
  'sošne',
  'sošni',
  'sošno',
  'sošnu',
  'sovom',
  'spada',
  'spaja',
  'spale',
  'spali',
  'sparc',
  'spare',
  'spari',
  'spasa',
  'spase',
  'spasi',
  'spasu',
  'spase',
  'spasi',
  'spade',
  'spalo',
  'spast',
  'spahu',
  'spala',
  'spalo',
  'spana',
  'spane',
  'spaše',
  'spati',
  'spava',
  'spaza',
  'spazi',
  'spaze',
  'spazi',
  'speci',
  'speče',
  'speći',
  'spelt',
  'spina',
  'spisa',
  'spise',
  'spisi',
  'spisu',
  'spiza',
  'spjev',
  'splav',
  'spleo',
  'splet',
  'splin',
  'split',
  'spoja',
  'spoju',
  'spoje',
  'spoji',
  'spola',
  'spole',
  'spolu',
  'spone',
  'spona',
  'spone',
  'sponi',
  'spono',
  'sponu',
  'spora',
  'sporu',
  'spora',
  'spore',
  'spore',
  'spori',
  'spore',
  'spori',
  'sporo',
  'sporo',
  'sport',
  'spota',
  'spote',
  'spotu',
  'spram',
  'spran',
  'sprat',
  'spere',
  'speru',
  'sprah',
  'sprao',
  'sprat',
  'sprda',
  'spreg',
  'sprej',
  'sprot',
  'sprud',
  'sprže',
  'sprži',
  'spust',
  'sputa',
  'srama',
  'srame',
  'sramu',
  'srami',
  'srnom',
  'srast',
  'serem',
  'sereš',
  'srahu',
  'srana',
  'srane',
  'srani',
  'srati',
  'sraza',
  'sraze',
  'srazi',
  'srazu',
  'srbim',
  'srbuj',
  'srcem',
  'srčan',
  'srdit',
  'srdim',
  'srdio',
  'srdiš',
  'srđan',
  'sreća',
  'sreće',
  'sreći',
  'srećo',
  'sreću',
  'srede',
  'sredi',
  'srela',
  'srele',
  'sreli',
  'srelo',
  'srest',
  'sreće',
  'sreza',
  'sreže',
  'srežu',
  'srhom',
  'srhaj',
  'sriče',
  'sriči',
  'sriču',
  'srića',
  'srete',
  'srkan',
  'srčem',
  'srčeš',
  'srkah',
  'srkao',
  'srkat',
  'srkne',
  'srkni',
  'srknu',
  'srlja',
  'srnem',
  'srneš',
  'srnuh',
  'srnuo',
  'srnut',
  'sroče',
  'sroči',
  'sroka',
  'sroku',
  'sroza',
  'srpom',
  'srpak',
  'srpem',
  'sruče',
  'sruči',
  'sruše',
  'sruši',
  'sržju',
  'sržna',
  'sržne',
  'stada',
  'stado',
  'stadu',
  'staja',
  'staje',
  'staji',
  'stajo',
  'staju',
  'stoje',
  'stoji',
  'staje',
  'stana',
  'stanu',
  'stapa',
  'stape',
  'stapu',
  'stara',
  'stare',
  'staru',
  'stara',
  'stari',
  'staro',
  'start',
  'stasa',
  'stase',
  'stasu',
  'stade',
  'stala',
  'stale',
  'stali',
  'stalo',
  'stane',
  'stani',
  'stano',
  'staše',
  'stati',
  'stava',
  'stavu',
  'stave',
  'stavi',
  'staza',
  'staze',
  'stazi',
  'stazo',
  'stazu',
  'staža',
  'staže',
  'staži',
  'stažu',
  'steći',
  'steel',
  'stega',
  'stege',
  'stego',
  'stegu',
  'stezi',
  'steci',
  'steku',
  'stela',
  'stent',
  'stepa',
  'stepe',
  'stepi',
  'stepo',
  'stepu',
  'stera',
  'stere',
  'steve',
  'stevo',
  'steže',
  'steži',
  'stežu',
  'stick',
  'stići',
  'stida',
  'stide',
  'stidu',
  'stidi',
  'stiha',
  'stihu',
  'stisi',
  'stiše',
  'stiče',
  'stika',
  'stiku',
  'stila',
  'stile',
  'stili',
  'stilu',
  'stine',
  'stipi',
  'stipu',
  'stiša',
  'stiva',
  'stiže',
  'stižu',
  'stoga',
  'stogu',
  'stoga',
  'stoik',
  'stoci',
  'stoče',
  'stoka',
  'stoke',
  'stoko',
  'stoku',
  'stola',
  'stole',
  'stoli',
  'stolu',
  'stoli',
  'stolo',
  'stocu',
  'stoma',
  'stopa',
  'stopi',
  'stopu',
  'stopa',
  'stope',
  'stopi',
  'stopo',
  'stopu',
  'stopi',
  'stota',
  'stote',
  'stoti',
  'stoto',
  'stotu',
  'strah',
  'stran',
  'strča',
  'strče',
  'strči',
  'stres',
  'strga',
  'strže',
  'stric',
  'strem',
  'streš',
  'strip',
  'strci',
  'strka',
  'strke',
  'strko',
  'strku',
  'strma',
  'strme',
  'strmi',
  'strmo',
  'strmu',
  'strmo',
  'strog',
  'stroj',
  'strop',
  'strpa',
  'strše',
  'strše',
  'strši',
  'strta',
  'strto',
  'strla',
  'strle',
  'strli',
  'strlo',
  'strte',
  'strti',
  'strug',
  'struk',
  'strum',
  'strpi',
  'strvi',
  'stuba',
  'stubi',
  'stubu',
  'stube',
  'stuče',
  'stući',
  'stupa',
  'stupe',
  'stupi',
  'stupu',
  'stupe',
  'stupi',
  'stupo',
  'stupi',
  'stvar',
  'stvor',
  'sučev',
  'sućut',
  'sudom',
  'sucem',
  'sudac',
  'sudca',
  'sudce',
  'sudci',
  'sudcu',
  'sudče',
  'sudar',
  'sudba',
  'sudbe',
  'sudbi',
  'sudbu',
  'sudim',
  'sudio',
  'sudiš',
  'sudit',
  'suđem',
  'suđen',
  'suhih',
  'suhog',
  'suhom',
  'sušem',
  'suhim',
  'suhoj',
  'suite',
  'suiti',
  'sukan',
  'sučem',
  'sučeš',
  'sukah',
  'sukao',
  'sukat',
  'sukna',
  'sukno',
  'suknu',
  'sukob',
  'sukus',
  'sukut',
  'sulić',
  'sulud',
  'sumom',
  'sumor',
  'sumpu',
  'sunom',
  'sunca',
  'sunca',
  'sunce',
  'suncu',
  'sunča',
  'sunet',
  'sunit',
  'suoče',
  'suoči',
  'supom',
  'super',
  'supin',
  'supci',
  'supka',
  'supke',
  'supki',
  'supko',
  'supku',
  'surfa',
  'suroj',
  'surom',
  'surih',
  'surim',
  'surka',
  'surki',
  'surku',
  'surla',
  'surle',
  'surli',
  'surlo',
  'surlu',
  'surov',
  'surva',
  'susan',
  'sused',
  'susić',
  'suski',
  'sušom',
  'sušac',
  'sušca',
  'sušci',
  'sušcu',
  'sušač',
  'sušan',
  'sušna',
  'sušne',
  'sušno',
  'sušnu',
  'sušen',
  'suših',
  'sušoj',
  'sušah',
  'sušim',
  'sušio',
  'sušiš',
  'sušit',
  'sušni',
  'suton',
  'sutra',
  'suvag',
  'suzom',
  'suzan',
  'suzna',
  'suzne',
  'suzno',
  'suzbi',
  'suzni',
  'suznu',
  'sužen',
  'sužuj',
  'svađa',
  'svađe',
  'svađi',
  'svađo',
  'svađu',
  'svaka',
  'svake',
  'svaki',
  'svako',
  'svaku',
  'svaki',
  'svale',
  'svali',
  'svane',
  'svani',
  'svanu',
  'svare',
  'svari',
  'svast',
  'svati',
  'svega',
  'svede',
  'svedi',
  'svedu',
  'svela',
  'svele',
  'sveli',
  'svelo',
  'svest',
  'sveta',
  'svete',
  'sveto',
  'svetu',
  'sveca',
  'svece',
  'sveci',
  'svecu',
  'sveče',
  'sveti',
  'sveto',
  'sveza',
  'sveze',
  'svezi',
  'svezo',
  'svezu',
  'sveza',
  'sveže',
  'sveži',
  'svežu',
  'svima',
  'sviju',
  'sviba',
  'sviće',
  'svići',
  'sviću',
  'svidi',
  'sviđa',
  'svija',
  'svije',
  'sviju',
  'svila',
  'svile',
  'svili',
  'svilo',
  'svilu',
  'svima',
  'svime',
  'sving',
  'svine',
  'svini',
  'svinu',
  'svira',
  'svita',
  'svite',
  'sviti',
  'svito',
  'svitu',
  'svici',
  'sviče',
  'svjet',
  'svjež',
  'svlak',
  'svoda',
  'svode',
  'svodi',
  'svodu',
  'svode',
  'svodi',
  'svoga',
  'svoja',
  'svoje',
  'svoji',
  'svoju',
  'svome',
  'svomu',
  'svota',
  'svote',
  'svoti',
  'svoto',
  'svotu',
  'svrab',
  'svrbe',
  'svrbi',
  'svrći',
  'svrže',
  'svrha',
  'svrhe',
  'svrho',
  'svrhu',
  'svrsi',
  'svrne',
  'svrni',
  'svrnu',
  'svrše',
  'svrši',
  'svuci',
  'svuče',
  'svući',
  'svuku',
  'svuda',
  'šahom',
  'šahin',
  'šahov',
  'šahta',
  'šahte',
  'šahtu',
  'šahta',
  'šajci',
  'šajka',
  'šajke',
  'šajki',
  'šajku',
  'šakom',
  'šakal',
  'šakah',
  'šakaj',
  'šakam',
  'šakao',
  'šakaš',
  'šakat',
  'šalom',
  'šalim',
  'šalio',
  'šališ',
  'šalit',
  'šalši',
  'šaman',
  'šamar',
  'šamot',
  'šanac',
  'šanca',
  'šance',
  'šanci',
  'šancu',
  'šanče',
  'šanka',
  'šanku',
  'šansa',
  'šanse',
  'šansi',
  'šansu',
  'šapom',
  'šapat',
  'šapta',
  'šapte',
  'šapne',
  'šapni',
  'šapnu',
  'šaptu',
  'šapti',
  'šapće',
  'šapći',
  'šapću',
  'šarom',
  'šarac',
  'šarca',
  'šarce',
  'šarci',
  'šarcu',
  'šarče',
  'šaraf',
  'šarag',
  'šaran',
  'šaran',
  'šarah',
  'šaraj',
  'šaram',
  'šarao',
  'šaraš',
  'šarat',
  'šaren',
  'šarka',
  'šarke',
  'šarki',
  'šarko',
  'šarku',
  'šarma',
  'šarme',
  'šarmu',
  'šarov',
  'šarun',
  'šarža',
  'šarže',
  'šarži',
  'šaržu',
  'šašem',
  'šašav',
  'šaško',
  'šatru',
  'šator',
  'šatra',
  'šatre',
  'šatri',
  'šatro',
  'šavom',
  'šavan',
  'šavno',
  'šavna',
  'šavne',
  'šavni',
  'ščepa',
  'šeboj',
  'šećer',
  'šećem',
  'šedok',
  'šefom',
  'šefov',
  'šegrt',
  'šeike',
  'šelak',
  'šelfa',
  'šenil',
  'šenoa',
  'šenut',
  'šenem',
  'šeneš',
  'šenuh',
  'šenuo',
  'šenut',
  'šepah',
  'šepaj',
  'šepam',
  'šepao',
  'šepaš',
  'šepat',
  'šepav',
  'šerif',
  'šerpa',
  'šerpe',
  'šerpi',
  'šerpo',
  'šerpu',
  'šesta',
  'šeste',
  'šesti',
  'šesto',
  'šestu',
  'šesto',
  'šešir',
  'šetač',
  'šećeš',
  'šetah',
  'šetaj',
  'šetam',
  'šetao',
  'šetaš',
  'šetat',
  'ševar',
  'šibom',
  'šibaj',
  'šibam',
  'šiban',
  'šibao',
  'šibaš',
  'šibat',
  'šiber',
  'šibik',
  'šibne',
  'šibni',
  'šibnu',
  'šifon',
  'šifra',
  'šifre',
  'šifri',
  'šifro',
  'šifru',
  'šifru',
  'šijom',
  'šiješ',
  'šijit',
  'šijun',
  'šikne',
  'šikni',
  'šiknu',
  'šikće',
  'šikći',
  'šikću',
  'šilom',
  'šilok',
  'šilje',
  'šilji',
  'šimić',
  'šimun',
  'šinom',
  'šipak',
  'šipci',
  'šipče',
  'šipka',
  'šipke',
  'šipku',
  'šipka',
  'šipke',
  'šipki',
  'šipko',
  'šipke',
  'širen',
  'šireg',
  'širem',
  'širić',
  'širit',
  'širah',
  'širim',
  'širio',
  'širiš',
  'široj',
  'širih',
  'širok',
  'širom',
  'šišak',
  'šišku',
  'šišan',
  'šišah',
  'šišaj',
  'šišam',
  'šišao',
  'šišaš',
  'šišat',
  'šiška',
  'šiško',
  'šiške',
  'šiški',
  'šište',
  'šišti',
  'šijah',
  'šijem',
  'šivan',
  'šivah',
  'šivaj',
  'šivam',
  'šivao',
  'šivaš',
  'šivat',
  'šizio',
  'šizma',
  'šizme',
  'šizmi',
  'šizmo',
  'šizmu',
  'škamp',
  'škaru',
  'škara',
  'škare',
  'škart',
  'škija',
  'škoda',
  'škodu',
  'škode',
  'škodi',
  'škola',
  'škole',
  'školi',
  'školo',
  'školu',
  'školu',
  'škopi',
  'škope',
  'škote',
  'škoti',
  'škrba',
  'škrbe',
  'škrbi',
  'škrbo',
  'škrbu',
  'škrga',
  'škrgu',
  'škrga',
  'škrge',
  'škrip',
  'škrob',
  'škrta',
  'škrte',
  'škrti',
  'škrtu',
  'škrca',
  'škrce',
  'škrci',
  'škrcu',
  'škrče',
  'škrto',
  'škrto',
  'škuda',
  'škudi',
  'škudu',
  'škuna',
  'škune',
  'škuni',
  'škunu',
  'škura',
  'šlaga',
  'šlagu',
  'šlajm',
  'šlapa',
  'šlapu',
  'šlapi',
  'šlepa',
  'šlica',
  'šlicu',
  'šlifa',
  'šljam',
  'šljem',
  'šmrca',
  'šmrče',
  'šmrka',
  'šmrku',
  'šmrči',
  'šmrču',
  'šnale',
  'šnaps',
  'šnjur',
  'šofer',
  'šogor',
  'šojci',
  'šojka',
  'šojke',
  'šojki',
  'šojko',
  'šojku',
  'šokom',
  'šolja',
  'šolje',
  'šolju',
  'šolji',
  'šonje',
  'šonjo',
  'šopaj',
  'špadi',
  'špaga',
  'špage',
  'špagi',
  'špago',
  'špagu',
  'špana',
  'španu',
  'špeče',
  'špeka',
  'špeku',
  'špeni',
  'špici',
  'špica',
  'špice',
  'špici',
  'špico',
  'špicu',
  'špigl',
  'špila',
  'špilu',
  'špilj',
  'špiro',
  'šport',
  'špula',
  'špule',
  'špuli',
  'špulo',
  'špulu',
  'šrafe',
  'štova',
  'štaba',
  'štabi',
  'štabu',
  'štaci',
  'štaka',
  'štake',
  'štako',
  'štaku',
  'štala',
  'štale',
  'štali',
  'štalo',
  'štalu',
  'štand',
  'štapa',
  'štape',
  'štapi',
  'štapu',
  'štava',
  'štave',
  'štavi',
  'štede',
  'štedi',
  'šteka',
  'šteki',
  'šteku',
  'štene',
  'šteta',
  'štete',
  'šteti',
  'šteto',
  'štetu',
  'šteti',
  'štiga',
  'štiha',
  'štihe',
  'štihu',
  'štiše',
  'štima',
  'štimu',
  'štipa',
  'štita',
  'štite',
  'štitu',
  'štite',
  'štiti',
  'štiva',
  'štive',
  'štivi',
  'štivu',
  'štivo',
  'štofa',
  'štofe',
  'štofu',
  'štoka',
  'štoke',
  'štoku',
  'štono',
  'štopa',
  'štosa',
  'štosu',
  'štuje',
  'štuju',
  'štrca',
  'štrik',
  'štrče',
  'štrka',
  'štrku',
  'štuca',
  'štuka',
  'štuke',
  'štuki',
  'štuko',
  'štuku',
  'štula',
  'štule',
  'štuli',
  'štulo',
  'štulu',
  'štura',
  'šturi',
  'šturu',
  'šugom',
  'šugav',
  'šulac',
  'šulja',
  'šumom',
  'šumom',
  'šuman',
  'šumna',
  'šumne',
  'šumni',
  'šumno',
  'šumnu',
  'šumar',
  'šumah',
  'šumim',
  'šumio',
  'šumiš',
  'šumor',
  'šunda',
  'šunde',
  'šundu',
  'šunka',
  'šunke',
  'šunki',
  'šunko',
  'šunku',
  'šunja',
  'šupom',
  'šupak',
  'šupci',
  'šupče',
  'šupka',
  'šupke',
  'šupku',
  'šušom',
  'šušku',
  'šuške',
  'šuški',
  'šuška',
  'šušte',
  'šušti',
  'šušti',
  'šutom',
  'šutej',
  'šuter',
  'šućah',
  'šutim',
  'šutio',
  'šutiš',
  'šutke',
  'švelu',
  'šverc',
  'šveli',
  'švelo',
  'tabak',
  'taban',
  'tabaj',
  'tabao',
  'tabla',
  'table',
  'tabli',
  'tablo',
  'tablu',
  'tabor',
  'tabua',
  'tabui',
  'tabuu',
  'tacom',
  'tačke',
  'tačku',
  'takli',
  'tadić',
  'tafra',
  'tafta',
  'tafte',
  'taftu',
  'tajom',
  'tajac',
  'tajca',
  'tajcu',
  'tajče',
  'tajan',
  'tajnu',
  'tajga',
  'tajge',
  'tajim',
  'tajio',
  'tajiš',
  'tajit',
  'tajna',
  'tajne',
  'tajni',
  'tajno',
  'tajnu',
  'tajne',
  'tajni',
  'tajno',
  'takom',
  'takav',
  'takva',
  'takve',
  'takvi',
  'takvo',
  'takvu',
  'takao',
  'takla',
  'taklo',
  'takne',
  'takni',
  'taknu',
  'takov',
  'taksa',
  'takse',
  'taksi',
  'takso',
  'taksu',
  'taksi',
  'takta',
  'takte',
  'taktu',
  'talac',
  'talče',
  'taoca',
  'taoce',
  'taoci',
  'taocu',
  'talij',
  'talir',
  'talim',
  'talio',
  'tališ',
  'talit',
  'talka',
  'talku',
  'talna',
  'talne',
  'talni',
  'talog',
  'talon',
  'talpa',
  'talus',
  'tamom',
  'tamal',
  'taman',
  'tamne',
  'tamnu',
  'tamna',
  'tamni',
  'tamne',
  'tamno',
  'tanac',
  'tanca',
  'tanče',
  'tanad',
  'tanak',
  'tanke',
  'tanki',
  'tanom',
  'tanan',
  'tanga',
  'tango',
  'tangu',
  'tanin',
  'tanka',
  'tanka',
  'tanko',
  'tanku',
  'tanko',
  'tanja',
  'tanju',
  'tanji',
  'tanje',
  'tapam',
  'tapan',
  'tapao',
  'tapaš',
  'tapir',
  'tapit',
  'tapka',
  'tapša',
  'tarom',
  'tarac',
  'tarna',
  'tarne',
  'tarni',
  'tarno',
  'tasta',
  'taste',
  'tastu',
  'tašta',
  'tašte',
  'tašti',
  'taštu',
  'tašta',
  'tašto',
  'tašto',
  'tatom',
  'tatar',
  'tavom',
  'tavan',
  'tažen',
  'tažah',
  'tažim',
  'tažio',
  'tažiš',
  'tažit',
  'tečaj',
  'tečan',
  'tečne',
  'tečni',
  'tečnu',
  'tečna',
  'tečno',
  'tečem',
  'tečeš',
  'tekao',
  'tekla',
  'tekle',
  'tekli',
  'teklo',
  'tegom',
  'tegla',
  'tegle',
  'tegli',
  'teglo',
  'teglu',
  'teglo',
  'teina',
  'teist',
  'tekom',
  'teksa',
  'teksu',
  'tekst',
  'tekuć',
  'telac',
  'telad',
  'telal',
  'telco',
  'telić',
  'telur',
  'temom',
  'temat',
  'tempa',
  'tempe',
  'tempi',
  'tempo',
  'tempu',
  'tenda',
  'tende',
  'tenis',
  'tenka',
  'tenke',
  'tenku',
  'tenor',
  'tenjo',
  'tepah',
  'tepaj',
  'tepam',
  'tepao',
  'tepaš',
  'tepat',
  'tepav',
  'tepih',
  'tercu',
  'terca',
  'terci',
  'teren',
  'teret',
  'termi',
  'terma',
  'terme',
  'termo',
  'teror',
  'tesan',
  'tesar',
  'tesah',
  'tesao',
  'tesat',
  'tešem',
  'tesla',
  'tesle',
  'teslu',
  'testa',
  'testu',
  'tesli',
  'teško',
  'tetom',
  'tetak',
  'tetci',
  'tetka',
  'tetke',
  'tetki',
  'tetko',
  'tetku',
  'tetra',
  'teuta',
  'tezom',
  'tezga',
  'tezge',
  'tezgi',
  'tezgo',
  'tezgu',
  'tezge',
  'težom',
  'težak',
  'teška',
  'teške',
  'teški',
  'teško',
  'tešku',
  'težaj',
  'težeg',
  'težem',
  'težih',
  'težim',
  'težom',
  'težah',
  'težim',
  'težio',
  'težiš',
  'težit',
  'thaci',
  'tobom',
  'tican',
  'ticah',
  'ticam',
  'ticao',
  'ticat',
  'tičem',
  'tičeš',
  'tifus',
  'tigar',
  'tigre',
  'tigri',
  'tigru',
  'tigra',
  'tihih',
  'tihim',
  'tihoj',
  'tihom',
  'tišeg',
  'tihim',
  'tihog',
  'tihić',
  'tijek',
  'tikom',
  'tikao',
  'tikva',
  'tikve',
  'tikvi',
  'tikvo',
  'tikvu',
  'tilom',
  'tilda',
  'tilde',
  'tildi',
  'tildo',
  'tildu',
  'timom',
  'timar',
  'times',
  'timin',
  'timol',
  'timus',
  'tinel',
  'tinol',
  'tinta',
  'tinte',
  'tinti',
  'tinto',
  'tintu',
  'tinja',
  'tiola',
  'tioli',
  'tipom',
  'tipka',
  'tipke',
  'tipki',
  'tipko',
  'tipku',
  'tipni',
  'tipno',
  'tiran',
  'tirom',
  'tisom',
  'tisak',
  'tisci',
  'tiska',
  'tisku',
  'tiska',
  'tiski',
  'tisni',
  'tisnu',
  'tišom',
  'tišma',
  'tišem',
  'tišoj',
  'tiših',
  'tišim',
  'tište',
  'tišti',
  'titan',
  'titar',
  'titla',
  'title',
  'titlu',
  'title',
  'titov',
  'titra',
  'titra',
  'tivat',
  'tjeme',
  'tjera',
  'tješe',
  'tješi',
  'tkača',
  'tkače',
  'tkači',
  'tkaču',
  'tkana',
  'tkane',
  'tkano',
  'tkani',
  'tkaju',
  'tkala',
  'tkale',
  'tkali',
  'tkalo',
  'tkate',
  'tkati',
  'tkiva',
  'tkivo',
  'tkivu',
  'tlaci',
  'tlače',
  'tlači',
  'tlaka',
  'tlaku',
  'tlaka',
  'tlake',
  'tlako',
  'tmast',
  'tmica',
  'tmici',
  'tmicu',
  'tmina',
  'tmine',
  'tmini',
  'tmino',
  'tminu',
  'točak',
  'točan',
  'točna',
  'točne',
  'točni',
  'točno',
  'točnu',
  'točen',
  'točim',
  'točio',
  'točiš',
  'točit',
  'točci',
  'točka',
  'točke',
  'točki',
  'točko',
  'točku',
  'točno',
  'togom',
  'tokom',
  'tokar',
  'token',
  'tokić',
  'tolar',
  'tolić',
  'tolik',
  'tomom',
  'tomca',
  'tomba',
  'tomić',
  'tonom',
  'tonom',
  'toner',
  'tonik',
  'tonko',
  'tonus',
  'tonem',
  'toneš',
  'tonuo',
  'tonut',
  'tools',
  'topom',
  'topao',
  'topla',
  'topli',
  'toplo',
  'toplu',
  'topaz',
  'topim',
  'topio',
  'topiš',
  'topit',
  'topiv',
  'tople',
  'toplo',
  'topol',
  'topot',
  'topta',
  'topću',
  'topuz',
  'torba',
  'torbe',
  'torbi',
  'torbo',
  'torbu',
  'torij',
  'torka',
  'torta',
  'torte',
  'torti',
  'torto',
  'tortu',
  'torte',
  'torus',
  'torza',
  'torzo',
  'torzu',
  'tosta',
  'toste',
  'tosti',
  'tostu',
  'tošić',
  'total',
  'totem',
  'touru',
  'tovom',
  'tovan',
  'tovar',
  'tovim',
  'tovio',
  'toviš',
  'tovit',
  'tovna',
  'tovne',
  'tovni',
  'tovno',
  'tovnu',
  'trace',
  'trača',
  'trači',
  'traču',
  'trači',
  'traće',
  'traći',
  'trade',
  'trafo',
  'traga',
  'trage',
  'tragu',
  'trazi',
  'traže',
  'traga',
  'traja',
  'traje',
  'traju',
  'traci',
  'trače',
  'traka',
  'traku',
  'traci',
  'traka',
  'trake',
  'trako',
  'traku',
  'trakt',
  'trami',
  'trans',
  'trape',
  'trapi',
  'trasa',
  'trase',
  'trasi',
  'traso',
  'trasu',
  'trata',
  'trati',
  'trate',
  'trati',
  'trava',
  'trave',
  'travi',
  'travo',
  'travu',
  'traže',
  'traže',
  'traži',
  'trbuh',
  'trčak',
  'trčci',
  'trčah',
  'trčan',
  'trčao',
  'trčat',
  'trčim',
  'trčiš',
  'trčka',
  'trčke',
  'trčki',
  'trčko',
  'trčku',
  'treba',
  'treću',
  'treća',
  'treće',
  'treći',
  'treću',
  'trefa',
  'trefe',
  'trefu',
  'trema',
  'treme',
  'tremi',
  'tremu',
  'trena',
  'trene',
  'treni',
  'trenu',
  'trend',
  'tresi',
  'tresu',
  'trese',
  'trest',
  'trgom',
  'trgan',
  'trgah',
  'trgaj',
  'trgam',
  'trgao',
  'trgaš',
  'trgat',
  'trgla',
  'trgle',
  'trgli',
  'trglo',
  'trgne',
  'trgni',
  'trgnu',
  'trgoh',
  'trguj',
  'trima',
  'trias',
  'trica',
  'trice',
  'tricu',
  'trice',
  'trija',
  'trici',
  'triče',
  'trika',
  'trike',
  'triku',
  'triko',
  'trilj',
  'triju',
  'triom',
  'triol',
  'trion',
  'tripa',
  'tripe',
  'trkom',
  'trkač',
  'trkin',
  'trkne',
  'trkom',
  'trlja',
  'trnom',
  'trnče',
  'trnce',
  'trnci',
  'trnov',
  'trnem',
  'trneš',
  'trnuo',
  'trnja',
  'trnje',
  'trnju',
  'troga',
  'troje',
  'troji',
  'trola',
  'trole',
  'troli',
  'trolo',
  'trolu',
  'troma',
  'trome',
  'tromi',
  'tromo',
  'tromu',
  'tromb',
  'tromo',
  'trona',
  'trone',
  'tronu',
  'tropa',
  'tropo',
  'trope',
  'tropi',
  'troše',
  'troši',
  'truje',
  'truju',
  'trpom',
  'trpak',
  'trpke',
  'trpko',
  'trpku',
  'trpan',
  'trpna',
  'trpno',
  'trpah',
  'trpaj',
  'trpam',
  'trpao',
  'trpaš',
  'trpat',
  'trpim',
  'trpio',
  'trpiš',
  'trpit',
  'trpio',
  'trpka',
  'trpki',
  'trpne',
  'trpni',
  'trsom',
  'trsim',
  'trsio',
  'trsiš',
  'trsit',
  'trsja',
  'trsju',
  'trsci',
  'trska',
  'trske',
  'trski',
  'trsko',
  'trsku',
  'trstu',
  'trtom',
  'tarah',
  'tarem',
  'tareš',
  'trite',
  'trtoj',
  'trtih',
  'trtim',
  'truba',
  'trube',
  'trubi',
  'trubo',
  'trubu',
  'truda',
  'trudi',
  'trudu',
  'trude',
  'trudi',
  'trulu',
  'trule',
  'truli',
  'trulo',
  'truna',
  'trune',
  'trunu',
  'truni',
  'trula',
  'trupa',
  'trupe',
  'trupi',
  'trupo',
  'trupu',
  'trusi',
  'trust',
  'truta',
  'trute',
  'truti',
  'trutu',
  'trven',
  'trzaj',
  'trzan',
  'trzah',
  'trzam',
  'trzao',
  'trzaš',
  'trzat',
  'trzav',
  'tržna',
  'tržah',
  'tržim',
  'tržio',
  'tržiš',
  'tržit',
  'trživ',
  'tržni',
  'tržno',
  'tubom',
  'tuber',
  'tubul',
  'tubus',
  'tucan',
  'tucah',
  'tucaj',
  'tucam',
  'tucao',
  'tucaš',
  'tucat',
  'tucet',
  'tucka',
  'tučom',
  'tučak',
  'tučci',
  'tučka',
  'tučke',
  'tučen',
  'tučem',
  'tukao',
  'tukla',
  'tukle',
  'tukli',
  'tuklo',
  'tudor',
  'tuđeg',
  'tuđem',
  'tuđih',
  'tuđoj',
  'tuđim',
  'tuđom',
  'tuđin',
  'tugom',
  'tuguj',
  'tukom',
  'tukac',
  'tukca',
  'tukce',
  'tukci',
  'tukcu',
  'tukan',
  'tučeš',
  'tulac',
  'tulij',
  'tulio',
  'tulit',
  'tulum',
  'tumač',
  'tumba',
  'tumbe',
  'tumor',
  'tunom',
  'tunel',
  'tunji',
  'tunju',
  'tunja',
  'tupih',
  'tupog',
  'tupoj',
  'tupom',
  'tupan',
  'tupav',
  'tupik',
  'tupim',
  'tupio',
  'tupiš',
  'tupit',
  'turom',
  'turaj',
  'turam',
  'turan',
  'turao',
  'turaš',
  'turat',
  'turbo',
  'turci',
  'turek',
  'turen',
  'turih',
  'turim',
  'turio',
  'turiš',
  'turit',
  'turne',
  'tuske',
  'tuski',
  'tusta',
  'tuste',
  'tusto',
  'tustu',
  'tusti',
  'tušek',
  'tušem',
  'tutne',
  'tutni',
  'tutnu',
  'tutor',
  'tužan',
  'tužna',
  'tužne',
  'tužni',
  'tužno',
  'tužnu',
  'tužba',
  'tužbe',
  'tužbi',
  'tužbo',
  'tužbu',
  'tužen',
  'tužio',
  'tužit',
  'tužno',
  'tvari',
  'tvoga',
  'tvoju',
  'tvome',
  'tvoja',
  'tvoje',
  'tvoji',
  'tvora',
  'tvore',
  'tvoru',
  'tvore',
  'tvori',
  'tvrda',
  'tvrde',
  'tvrdi',
  'tvrdo',
  'tvrdu',
  'tvrde',
  'tvrdi',
  'tvrdo',
  'tvrđa',
  'tvrđe',
  'tvrđi',
  'tvrđo',
  'tvrđu',
  'tvrci',
  'tvtka',
  'tvtke',
  'ubace',
  'ubaci',
  'ubada',
  'ubava',
  'ubave',
  'ubavi',
  'ubavo',
  'ubavu',
  'ubavo',
  'uberu',
  'uberi',
  'ubica',
  'ubici',
  'ubija',
  'ubire',
  'ubiri',
  'ubiru',
  'ubita',
  'ubije',
  'ubiju',
  'ubila',
  'ubile',
  'ubili',
  'ubilo',
  'ubiše',
  'ubite',
  'ubiti',
  'uboda',
  'ubode',
  'ubodi',
  'ubodu',
  'uboga',
  'uboge',
  'ubogi',
  'ubogo',
  'ubogu',
  'uboje',
  'ubolo',
  'ubole',
  'ubost',
  'ubran',
  'ubere',
  'ubrah',
  'ubrao',
  'ubrat',
  'ubrus',
  'ubrza',
  'ubrzo',
  'ucrta',
  'učahu',
  'učaše',
  'učeća',
  'učeći',
  'učena',
  'učene',
  'učeni',
  'učeno',
  'učenu',
  'učeni',
  'učeno',
  'učila',
  'učila',
  'učilo',
  'učilu',
  'učine',
  'učini',
  'učita',
  'učile',
  'učili',
  'učimo',
  'učite',
  'učiti',
  'učmao',
  'učtiv',
  'uđemo',
  'uđete',
  'uđimo',
  'uđite',
  'uđoše',
  'ugnem',
  'udima',
  'udova',
  'udove',
  'udovi',
  'udaha',
  'udasi',
  'udaja',
  'udaje',
  'udaji',
  'udajo',
  'udaju',
  'udalj',
  'udanu',
  'udana',
  'udara',
  'udare',
  'udari',
  'udaru',
  'udara',
  'udare',
  'udate',
  'udane',
  'udata',
  'udatu',
  'udahu',
  'udala',
  'udale',
  'udali',
  'udamo',
  'udani',
  'udano',
  'udaše',
  'udati',
  'udava',
  'udave',
  'udavi',
  'udavu',
  'udbin',
  'udesa',
  'udese',
  'udesi',
  'udesu',
  'udica',
  'udice',
  'udici',
  'udico',
  'udicu',
  'udiše',
  'udiši',
  'udišu',
  'udiše',
  'udivi',
  'udjel',
  'udome',
  'udomi',
  'udova',
  'udovu',
  'udreš',
  'udube',
  'udubi',
  'ufaju',
  'ufala',
  'ufali',
  'ufalo',
  'ufamo',
  'ufano',
  'ufaše',
  'ufate',
  'ufati',
  'ugađa',
  'ugalj',
  'uglja',
  'uglju',
  'ugane',
  'ugani',
  'uganu',
  'ugaon',
  'ugara',
  'ugare',
  'ugari',
  'ugaru',
  'ugase',
  'ugasi',
  'ugaze',
  'ugazi',
  'ugiba',
  'ugibe',
  'ugine',
  'ugini',
  'uginu',
  'uginj',
  'uglas',
  'uglat',
  'ugled',
  'ugnaj',
  'ugnut',
  'ugnuh',
  'ugnuo',
  'ugnut',
  'ugoda',
  'ugodu',
  'ugode',
  'ugodi',
  'ugoje',
  'ugoji',
  'ugore',
  'ugori',
  'ugoru',
  'ugrij',
  'ugriz',
  'ugura',
  'uguše',
  'uguši',
  'uhata',
  'uhate',
  'uhite',
  'uhiti',
  'ušesa',
  'ušiju',
  'ušima',
  'uhoda',
  'uhodu',
  'uhode',
  'uhodi',
  'uhvat',
  'ujače',
  'ujaci',
  'ujaka',
  'ujake',
  'ujaku',
  'ujnom',
  'ujaše',
  'ujeda',
  'ujedi',
  'ujedu',
  'ujele',
  'ujest',
  'ujela',
  'ujeli',
  'ujelo',
  'ujina',
  'ujini',
  'ujinu',
  'ukaza',
  'ukaze',
  'ukazi',
  'ukazu',
  'ukaže',
  'ukaži',
  'ukažu',
  'ukeba',
  'ukida',
  'ukide',
  'ukine',
  'ukini',
  'ukinu',
  'ukipe',
  'ukipi',
  'uklet',
  'ukleo',
  'uklop',
  'ukoče',
  'ukoči',
  'ukopa',
  'ukope',
  'ukopi',
  'ukopu',
  'ukora',
  'ukore',
  'ukori',
  'ukoru',
  'ukose',
  'ukosi',
  'ukoso',
  'ukras',
  'ukrao',
  'ukrca',
  'ukrug',
  'ukuca',
  'ukuha',
  'ukusa',
  'ukuse',
  'ukusi',
  'ukusu',
  'ulaže',
  'ulaži',
  'ulažu',
  'ulara',
  'ulare',
  'ulari',
  'ularu',
  'ulaza',
  'ulaze',
  'ulazi',
  'ulazu',
  'ulazi',
  'ulaze',
  'ulazi',
  'ulcer',
  'ulete',
  'uleti',
  'ulica',
  'ulice',
  'ulici',
  'ulico',
  'ulicu',
  'ulije',
  'ulika',
  'ulita',
  'ulito',
  'uliju',
  'ulila',
  'ulile',
  'ulili',
  'ulilo',
  'uliše',
  'ulite',
  'uliti',
  'ulkus',
  'uloga',
  'uloge',
  'ulogu',
  'ulozi',
  'ulože',
  'uloga',
  'uloge',
  'ulogo',
  'ulogu',
  'ulova',
  'ulove',
  'ulovi',
  'ulovu',
  'ulozi',
  'uloži',
  'uludo',
  'ulupe',
  'ulupi',
  'uljan',
  'uljar',
  'uljem',
  'uljen',
  'uljev',
  'uljez',
  'uljna',
  'uljne',
  'uljni',
  'uljno',
  'uljnu',
  'umova',
  'umove',
  'umovi',
  'umače',
  'umaći',
  'umagu',
  'umaci',
  'umaka',
  'umake',
  'umaku',
  'umalo',
  'umnih',
  'umnim',
  'umnog',
  'umnoj',
  'umnom',
  'umara',
  'umeci',
  'umeče',
  'umeće',
  'umeću',
  'umiju',
  'umine',
  'umini',
  'uminu',
  'umire',
  'umiri',
  'umiru',
  'umiri',
  'umita',
  'umije',
  'umila',
  'umile',
  'umili',
  'umiše',
  'umiti',
  'umiva',
  'umije',
  'umnik',
  'umoče',
  'umoči',
  'umora',
  'umore',
  'umori',
  'umoru',
  'umota',
  'umuje',
  'umuju',
  'umovi',
  'umrem',
  'umreš',
  'umrla',
  'umrli',
  'umrlo',
  'umrti',
  'umrla',
  'umrle',
  'umrli',
  'umrlo',
  'umski',
  'uncom',
  'uncan',
  'ungar',
  'unići',
  'uniđe',
  'uniđi',
  'uniđu',
  'unija',
  'unije',
  'uniji',
  'unijo',
  'uniju',
  'unese',
  'unesi',
  'unesu',
  'union',
  'unize',
  'unizi',
  'unmik',
  'unosa',
  'unose',
  'unosi',
  'unosu',
  'unose',
  'unosi',
  'unska',
  'unske',
  'unski',
  'unsko',
  'unsku',
  'unšan',
  'unuče',
  'unuci',
  'unuka',
  'unuke',
  'unuku',
  'unuka',
  'unuko',
  'uočen',
  'uočih',
  'uočim',
  'uočio',
  'uočiš',
  'uočit',
  'uočiv',
  'uopće',
  'uopće',
  'uopći',
  'upada',
  'upade',
  'upadi',
  'upadu',
  'upada',
  'upala',
  'upale',
  'upali',
  'upalo',
  'upalu',
  'upale',
  'upali',
  'upale',
  'upali',
  'upast',
  'upeći',
  'upere',
  'uperi',
  'upeti',
  'upija',
  'upire',
  'upiri',
  'upiru',
  'upisa',
  'upise',
  'upisi',
  'upisu',
  'upiše',
  'upiši',
  'upišu',
  'upiša',
  'upita',
  'upite',
  'upiti',
  'upitu',
  'upita',
  'upije',
  'upiju',
  'upila',
  'upile',
  'upili',
  'upilo',
  'upiti',
  'upleo',
  'upliv',
  'upola',
  'upore',
  'upori',
  'uporu',
  'upora',
  'uprav',
  'upreo',
  'uprem',
  'uprli',
  'uprta',
  'uprtu',
  'uprte',
  'uprti',
  'uprte',
  'uprti',
  'upust',
  'uputa',
  'upute',
  'uputi',
  'uputo',
  'uputu',
  'upute',
  'urama',
  'uraci',
  'urače',
  'urade',
  'uradi',
  'urame',
  'urami',
  'urana',
  'urane',
  'urani',
  'urara',
  'urare',
  'urari',
  'uraru',
  'urban',
  'urbar',
  'ureom',
  'ureći',
  'ureda',
  'urede',
  'uredi',
  'uredu',
  'urede',
  'uredi',
  'ureja',
  'ureji',
  'ureju',
  'ureje',
  'uresa',
  'urese',
  'uresi',
  'uresu',
  'uresa',
  'ureza',
  'ureze',
  'urezi',
  'urezu',
  'ureza',
  'ureže',
  'ureži',
  'urežu',
  'urica',
  'urici',
  'uricu',
  'uriho',
  'urina',
  'urinu',
  'urini',
  'urlač',
  'urlah',
  'urlaj',
  'urlam',
  'urlao',
  'urlaš',
  'urlat',
  'urlik',
  'urama',
  'urnom',
  'urnih',
  'urnim',
  'urnoj',
  'uroči',
  'uroda',
  'urode',
  'urodi',
  'urodu',
  'uroci',
  'uroka',
  'urona',
  'urone',
  'uroni',
  'urota',
  'urote',
  'uroti',
  'uroto',
  'urotu',
  'uruče',
  'uruči',
  'uruše',
  'uruši',
  'usade',
  'usadi',
  'usame',
  'usami',
  'usele',
  'useli',
  'ushit',
  'usija',
  'usije',
  'usiju',
  'usire',
  'usiri',
  'usisi',
  'usisu',
  'usisa',
  'usiši',
  'usišu',
  'usili',
  'usiše',
  'usjek',
  'usjev',
  'uskog',
  'uskoj',
  'uskom',
  'uskok',
  'uskop',
  'usmen',
  'usana',
  'usnat',
  'usnen',
  'usnih',
  'usnim',
  'usnog',
  'usnoj',
  'usnom',
  'usnuo',
  'usnem',
  'usneš',
  'usnuh',
  'usnuo',
  'usnut',
  'usole',
  'usoli',
  'usova',
  'usove',
  'usovi',
  'usovu',
  'uspeh',
  'uspeo',
  'uspet',
  'uspio',
  'uspio',
  'uspje',
  'uspne',
  'uspni',
  'uspnu',
  'uspon',
  'uspor',
  'usput',
  'usere',
  'useri',
  'useru',
  'usrah',
  'usred',
  'ustaj',
  'ustah',
  'ustan',
  'ustao',
  'ustat',
  'ustav',
  'ustuk',
  'ustup',
  'usuda',
  'usude',
  'usudi',
  'usudu',
  'usudi',
  'usula',
  'usuta',
  'usuto',
  'usuli',
  'usulo',
  'usuše',
  'usute',
  'usuti',
  'ušiju',
  'ušima',
  'ušani',
  'ušara',
  'ušare',
  'ušari',
  'ušaro',
  'ušaru',
  'ušato',
  'ušatu',
  'ušata',
  'ušate',
  'ušati',
  'ušćem',
  'ušica',
  'ušice',
  'ušici',
  'ušico',
  'ušicu',
  'ušita',
  'ušito',
  'ušiče',
  'ušije',
  'ušiju',
  'ušila',
  'ušile',
  'ušili',
  'ušilo',
  'ušiše',
  'ušite',
  'ušiti',
  'ušiva',
  'ušaka',
  'uškom',
  'ušnih',
  'ušnim',
  'ušnog',
  'ušnoj',
  'ušnom',
  'uštap',
  'uštrb',
  'ušute',
  'ušuti',
  'utaba',
  'utaja',
  'utaje',
  'utaji',
  'utajo',
  'utaju',
  'utaje',
  'utače',
  'utapa',
  'utaže',
  'utaži',
  'uteci',
  'uteče',
  'uteći',
  'uteku',
  'utega',
  'utege',
  'utegu',
  'utezi',
  'uteže',
  'utego',
  'utiče',
  'utiči',
  'utiču',
  'utiha',
  'utija',
  'utina',
  'utire',
  'utiri',
  'utiru',
  'utiša',
  'utkan',
  'utkah',
  'utkaj',
  'utkam',
  'utkao',
  'utkaš',
  'utkat',
  'utoci',
  'utoka',
  'utoke',
  'utoku',
  'utole',
  'utoli',
  'utone',
  'utoni',
  'utonu',
  'utope',
  'utopi',
  'utora',
  'utore',
  'utori',
  'utoru',
  'utove',
  'utovi',
  'utrča',
  'utrče',
  'utrči',
  'utrem',
  'utrci',
  'utrka',
  'utrke',
  'utrki',
  'utrko',
  'utrku',
  'utrne',
  'utrni',
  'utrnu',
  'utrpa',
  'utrta',
  'utrte',
  'utrti',
  'utrto',
  'utrtu',
  'utare',
  'utrla',
  'utrle',
  'utrli',
  'utrlo',
  'utrti',
  'utuci',
  'utuče',
  'utući',
  'utuku',
  'uture',
  'utuve',
  'utuvi',
  'utuže',
  'utuži',
  'uvala',
  'uvale',
  'uvali',
  'uvalo',
  'uvalu',
  'uvali',
  'uvaža',
  'uvaže',
  'uvaži',
  'uveče',
  'uveća',
  'uvelu',
  'uvelo',
  'uvela',
  'uveli',
  'uvene',
  'uveni',
  'uvenu',
  'uvele',
  'uvelo',
  'uvede',
  'uvedi',
  'uvedu',
  'uveli',
  'uvest',
  'uveza',
  'uveze',
  'uvezu',
  'uvezi',
  'uveže',
  'uveži',
  'uvežu',
  'uvida',
  'uvide',
  'uvidi',
  'uvidu',
  'uviđa',
  'uvije',
  'uvija',
  'uvine',
  'uvini',
  'uvinu',
  'uvire',
  'uviru',
  'uvita',
  'uvito',
  'uviju',
  'uvila',
  'uvile',
  'uviše',
  'uvite',
  'uviti',
  'uvjet',
  'uvoda',
  'uvode',
  'uvodi',
  'uvodu',
  'uvodi',
  'uvode',
  'uvodi',
  'uvoja',
  'uvoza',
  'uvoze',
  'uvozi',
  'uvozu',
  'uvoze',
  'uvozi',
  'uvrće',
  'uvrći',
  'uvrću',
  'uvrte',
  'uvrti',
  'uvuci',
  'uvuče',
  'uvući',
  'uvuku',
  'uskih',
  'uskim',
  'užemu',
  'uzana',
  'uzane',
  'uzani',
  'uzano',
  'uzanu',
  'uzala',
  'uzdom',
  'uzdah',
  'uzdan',
  'uzdaj',
  'uzdam',
  'uzdao',
  'uzdaš',
  'uzdat',
  'uzduh',
  'uzduž',
  'uzeća',
  'uzeće',
  'uzeću',
  'uzeta',
  'uzete',
  'uzeti',
  'uzetu',
  'uzela',
  'uzele',
  'uzeli',
  'uzelo',
  'uzeše',
  'uzeti',
  'uzeto',
  'uzgoj',
  'uzgon',
  'uzica',
  'uzice',
  'uzici',
  'uzico',
  'uzicu',
  'uzići',
  'uziđe',
  'uziđi',
  'uziđu',
  'uzida',
  'uzima',
  'uzina',
  'uzini',
  'uzinu',
  'uzlaz',
  'uzlet',
  'uzlić',
  'uzlom',
  'uzmah',
  'uzmak',
  'uzmeš',
  'uznik',
  'uznio',
  'uzora',
  'uzore',
  'uzori',
  'uzoru',
  'uzoru',
  'uzrok',
  'uzusa',
  'uzvik',
  'uzvod',
  'uzvoj',
  'užadi',
  'užađu',
  'užara',
  'užare',
  'užari',
  'užasa',
  'užase',
  'užasi',
  'užasu',
  'užeta',
  'užetu',
  'užeći',
  'užegu',
  'užezi',
  'užeže',
  'užeži',
  'užgah',
  'užega',
  'užima',
  'užice',
  'užiže',
  'užina',
  'užine',
  'užini',
  'užino',
  'užinu',
  'užici',
  'užiče',
  'užiti',
  'uživa',
  'uživi',
  'uživo',
  'užnih',
  'vabom',
  'vabac',
  'vapci',
  'vabim',
  'vabio',
  'vabiš',
  'vabit',
  'vacek',
  'vadim',
  'vadio',
  'vadiš',
  'vadit',
  'vađen',
  'vafel',
  'vafla',
  'vafle',
  'vafli',
  'vaflu',
  'vaflo',
  'vagom',
  'vagan',
  'vagar',
  'vagah',
  'vagao',
  'vagaš',
  'vagat',
  'važem',
  'važeš',
  'vagir',
  'vagne',
  'vagni',
  'vagnu',
  'vagon',
  'vaida',
  'vajda',
  'vajdi',
  'vajdu',
  'vajni',
  'vajnu',
  'vakuf',
  'vakum',
  'valom',
  'valić',
  'valin',
  'valna',
  'valne',
  'valni',
  'valno',
  'valnu',
  'valno',
  'valov',
  'valja',
  'vanda',
  'vanje',
  'vanju',
  'vapaj',
  'vapim',
  'vapio',
  'vapiš',
  'vapit',
  'vapna',
  'vapno',
  'vapnu',
  'varan',
  'varah',
  'varaj',
  'varam',
  'varao',
  'varaš',
  'varat',
  'varav',
  'varen',
  'varim',
  'vario',
  'variš',
  'varit',
  'varci',
  'varka',
  'varke',
  'varki',
  'varko',
  'varku',
  'varoš',
  'vašeg',
  'vašem',
  'vaših',
  'vašim',
  'vašoj',
  'vašom',
  'vašar',
  'vatom',
  'vatra',
  'vatre',
  'vatri',
  'vatro',
  'vatru',
  'vazom',
  'vazal',
  'vazda',
  'važan',
  'važno',
  'važnu',
  'važah',
  'važen',
  'važim',
  'važio',
  'važiš',
  'važit',
  'važna',
  'važne',
  'važni',
  'važnu',
  'važno',
  'veber',
  'večer',
  'većeg',
  'većem',
  'većih',
  'većim',
  'većoj',
  'većom',
  'većma',
  'vedar',
  'vedre',
  'vedri',
  'vedru',
  'vedra',
  'vedro',
  'velne',
  'velim',
  'velik',
  'veliš',
  'venom',
  'venus',
  'venem',
  'veneš',
  'venuo',
  'venut',
  'velom',
  'veoma',
  'vepar',
  'vepre',
  'vepri',
  'vepru',
  'verah',
  'veran',
  'verao',
  'verat',
  'verem',
  'verde',
  'vergl',
  'veron',
  'veseo',
  'veski',
  'vesla',
  'vesla',
  'vesla',
  'veslo',
  'veslu',
  'vesne',
  'vesni',
  'vespa',
  'veste',
  'vestu',
  'vesti',
  'vezeš',
  'vezla',
  'vezli',
  'vetom',
  'vezom',
  'vezom',
  'vezač',
  'vezan',
  'vezne',
  'vezno',
  'veznu',
  'vezah',
  'vezao',
  'vezat',
  'vežem',
  'vežeš',
  'vezen',
  'vezir',
  'vezuj',
  'vezna',
  'vezni',
  'vezuv',
  'vežom',
  'vežuć',
  'vicem',
  'vicom',
  'vičan',
  'vična',
  'vične',
  'vični',
  'vično',
  'vičnu',
  'vidom',
  'vidan',
  'vidne',
  'vidar',
  'vidam',
  'vidao',
  'vidaš',
  'vidat',
  'videa',
  'videi',
  'video',
  'videu',
  'videu',
  'vidik',
  'vidim',
  'vidio',
  'vidiš',
  'vidje',
  'vidna',
  'vidni',
  'vidno',
  'vidnu',
  'vidno',
  'vidov',
  'vidra',
  'vidre',
  'vidri',
  'vidro',
  'vidru',
  'viđan',
  'viđah',
  'viđaj',
  'viđam',
  'viđao',
  'viđaš',
  'viđat',
  'viđen',
  'vihor',
  'vijče',
  'vijak',
  'vijci',
  'vijka',
  'vijke',
  'vijku',
  'vijan',
  'vijao',
  'vijat',
  'vijmo',
  'vijte',
  'vijek',
  'vijem',
  'vijuk',
  'vikom',
  'vikač',
  'vikar',
  'vičem',
  'vičeš',
  'vikah',
  'vikao',
  'vikat',
  'vikne',
  'vikni',
  'viknu',
  'vilim',
  'vilin',
  'vilko',
  'villa',
  'vilom',
  'vincu',
  'vinar',
  'vinca',
  'vinci',
  'vinil',
  'vinka',
  'vinko',
  'vinku',
  'vinom',
  'vinov',
  'vinem',
  'vineš',
  'vinuh',
  'vinuo',
  'vinut',
  'violu',
  'viola',
  'viole',
  'violi',
  'virom',
  'viren',
  'virim',
  'virio',
  'viriš',
  'virit',
  'virus',
  'visom',
  'visak',
  'visci',
  'viska',
  'viske',
  'visku',
  'višče',
  'visim',
  'visio',
  'visiš',
  'visit',
  'višen',
  'visio',
  'višen',
  'viski',
  'visok',
  'vista',
  'visti',
  'višak',
  'višci',
  'viška',
  'viške',
  'višku',
  'višeg',
  'višem',
  'viših',
  'višim',
  'višoj',
  'višom',
  'viški',
  'vitak',
  'vitka',
  'vitke',
  'vitki',
  'vitku',
  'vitao',
  'vitla',
  'vitle',
  'vitli',
  'vitlu',
  'vitez',
  'viješ',
  'vijmo',
  'vijte',
  'vitog',
  'vitik',
  'vitko',
  'vitla',
  'vitlo',
  'vitlu',
  'vitro',
  'vivak',
  'vizom',
  'vizir',
  'vizna',
  'vizne',
  'vizni',
  'viznu',
  'vizor',
  'vižla',
  'vižlu',
  'vjeđa',
  'vjeđe',
  'vjeđi',
  'vjeđo',
  'vjeđu',
  'vjera',
  'vjere',
  'vjeri',
  'vjero',
  'vjeru',
  'vjere',
  'vjeri',
  'vješa',
  'vješt',
  'vlače',
  'vlada',
  'vlade',
  'vladi',
  'vlado',
  'vladu',
  'vlaga',
  'vlage',
  'vlagi',
  'vlago',
  'vlagu',
  'vlaji',
  'vlaci',
  'vlaka',
  'vlake',
  'vlaku',
  'vlaka',
  'vlasi',
  'vlast',
  'vlaću',
  'vlati',
  'vlazi',
  'vlaže',
  'vlaži',
  'vlora',
  'voćom',
  'voćar',
  'voćem',
  'voćci',
  'voćka',
  'voćke',
  'voćki',
  'voćko',
  'voćku',
  'voćke',
  'voćna',
  'voćne',
  'voćni',
  'voćno',
  'voćnu',
  'vodom',
  'vodom',
  'vodnu',
  'voden',
  'vodič',
  'vodik',
  'vodim',
  'vodio',
  'vodiš',
  'vodit',
  'vođah',
  'vodiv',
  'vodka',
  'vodku',
  'vodna',
  'vodne',
  'vodni',
  'vodno',
  'vođom',
  'vođen',
  'voice',
  'vojem',
  'vojak',
  'vojan',
  'vojka',
  'vojko',
  'vojna',
  'vojna',
  'vojne',
  'vojni',
  'vojno',
  'vojnu',
  'vokal',
  'volom',
  'volan',
  'volej',
  'volov',
  'volta',
  'volte',
  'volti',
  'voltu',
  'volja',
  'volje',
  'volji',
  'volju',
  'volim',
  'volio',
  'voliš',
  'vonja',
  'vonje',
  'vonju',
  'vonja',
  'vosak',
  'voska',
  'vosku',
  'vošče',
  'voska',
  'voski',
  'vošti',
  'votci',
  'votka',
  'votke',
  'votki',
  'votko',
  'votku',
  'vozač',
  'vozan',
  'vozar',
  'vozah',
  'vozaj',
  'vozam',
  'vozao',
  'vozaš',
  'vozat',
  'vozim',
  'vozio',
  'voziš',
  'vozit',
  'vozna',
  'vozne',
  'vozni',
  'vozno',
  'voznu',
  'vožen',
  'vrače',
  'vrači',
  'vraču',
  'vrača',
  'vrače',
  'vrači',
  'vraća',
  'vraga',
  'vrage',
  'vragu',
  'vraže',
  'vraga',
  'vrane',
  'vrani',
  'vranu',
  'vrana',
  'vrane',
  'vranu',
  'vranj',
  'vrata',
  'vrate',
  'vratu',
  'vrata',
  'vereš',
  'vrati',
  'vrazi',
  'vrbom',
  'vrbak',
  'vrbik',
  'vrbin',
  'vrbov',
  'vrcaj',
  'vrcan',
  'vrcao',
  'vrcat',
  'vrcnu',
  'vrčem',
  'vrčić',
  'vrgli',
  'vrdaj',
  'vrdao',
  'vrdaš',
  'vreba',
  'vreća',
  'vreće',
  'vreći',
  'vrećo',
  'vreću',
  'vreli',
  'vrela',
  'vrelo',
  'vrelu',
  'vrela',
  'vrele',
  'vrelo',
  'vresk',
  'vrahu',
  'vraše',
  'vrela',
  'vrena',
  'vreni',
  'vreti',
  'vrimo',
  'vrite',
  'vreva',
  'vreve',
  'vrevi',
  'vrevo',
  'vrevu',
  'vrgao',
  'vrgne',
  'vrgni',
  'vrgnu',
  'vrgoh',
  'vrgoč',
  'vrhom',
  'vrhom',
  'vrije',
  'vriju',
  'vrime',
  'vrišt',
  'vriti',
  'vrlet',
  'vrlih',
  'vrlim',
  'vrlog',
  'vrloj',
  'vrlom',
  'vrnut',
  'vrpac',
  'vrpcu',
  'vrpca',
  'vrpce',
  'vrpci',
  'vrpco',
  'vrpcu',
  'vrbem',
  'vrsta',
  'vrste',
  'vrsti',
  'vrstu',
  'vrsna',
  'vrsne',
  'vrsni',
  'vrsno',
  'vrsnu',
  'vršom',
  'vršak',
  'vrške',
  'vršku',
  'vršan',
  'vršen',
  'vršim',
  'vršio',
  'vršiš',
  'vršit',
  'vršci',
  'vrška',
  'vršna',
  'vršne',
  'vršni',
  'vršno',
  'vršnu',
  'vršno',
  'vrtim',
  'vrtom',
  'vrtao',
  'vrtić',
  'vrtio',
  'vrtiš',
  'vrtna',
  'vrtne',
  'vrtni',
  'vrtno',
  'vrtnu',
  'vruče',
  'vruća',
  'vruću',
  'vruće',
  'vruće',
  'vrući',
  'vruci',
  'vrvce',
  'vrvcu',
  'vrvim',
  'vrvio',
  'vrviš',
  'vrzin',
  'vrzma',
  'vučom',
  'vučak',
  'vučka',
  'vučen',
  'vučić',
  'vučja',
  'vučju',
  'vučje',
  'vučji',
  'vučko',
  'vučna',
  'vučne',
  'vučni',
  'vučno',
  'vučnu',
  'vučem',
  'vučeš',
  'vukao',
  'vukla',
  'vukle',
  'vukli',
  'vuklo',
  'vuica',
  'vukom',
  'vukov',
  'vulić',
  'vulva',
  'vulve',
  'vunom',
  'vunen',
  'zabat',
  'zabit',
  'zabih',
  'zabij',
  'zabio',
  'zabok',
  'zaboo',
  'začas',
  'začet',
  'začeh',
  'začeo',
  'začet',
  'začev',
  'začne',
  'začni',
  'začnu',
  'začin',
  'začuh',
  'začuo',
  'začuv',
  'zađeš',
  'zađoh',
  'zašao',
  'zašav',
  'zašla',
  'zašle',
  'zašli',
  'zašlo',
  'zadah',
  'zadak',
  'zatku',
  'zadan',
  'zadra',
  'zadre',
  'zadru',
  'zadaj',
  'zadam',
  'zadao',
  'zadaš',
  'zadat',
  'zadav',
  'zadrt',
  'zadri',
  'zađem',
  'zagat',
  'zagon',
  'zahod',
  'zahum',
  'zaika',
  'zajam',
  'zajma',
  'zajme',
  'zajmu',
  'zajca',
  'zajeo',
  'zajmi',
  'zakiv',
  'zakla',
  'zakle',
  'zakon',
  'zakov',
  'zakri',
  'zakup',
  'zalaj',
  'zalaz',
  'zalet',
  'zalih',
  'zalih',
  'zalij',
  'zalim',
  'zalio',
  'zalit',
  'zaliv',
  'zalog',
  'zamah',
  'zamak',
  'zamku',
  'zameo',
  'zamet',
  'zamci',
  'zamka',
  'zamke',
  'zamki',
  'zamko',
  'zamku',
  'zamor',
  'zamot',
  'zamre',
  'zamri',
  'zamru',
  'zamro',
  'zanat',
  'zanić',
  'zanio',
  'zanos',
  'zanja',
  'zanje',
  'zlima',
  'zloga',
  'zlome',
  'zaova',
  'zaove',
  'zaovi',
  'zaovo',
  'zaovu',
  'zapad',
  'zapao',
  'zapru',
  'zapao',
  'zapah',
  'zapeh',
  'zapeo',
  'zapet',
  'zapev',
  'zapne',
  'zapis',
  'zapon',
  'zapor',
  'zapri',
  'zapuh',
  'zarad',
  'zarđa',
  'zarez',
  'zarih',
  'zarij',
  'zario',
  'zarit',
  'zaron',
  'zarza',
  'zarže',
  'zasad',
  'zasij',
  'zasip',
  'zasja',
  'zaspa',
  'zaspi',
  'zaspu',
  'zasta',
  'zasun',
  'zaspe',
  'zasuh',
  'zasuo',
  'zasut',
  'zaših',
  'zašij',
  'zašio',
  'zašit',
  'zašto',
  'zateg',
  'zatik',
  'zatim',
  'zaton',
  'zator',
  'zatru',
  'zatre',
  'zatrh',
  'zatri',
  'zatro',
  'zatrt',
  'zauze',
  'zavar',
  'zaveo',
  'zavid',
  'zavih',
  'zavij',
  'zavio',
  'zavit',
  'zavod',
  'zavoj',
  'zavor',
  'zazor',
  'zbace',
  'zbaci',
  'zbija',
  'zbija',
  'zbira',
  'zbiru',
  'zbitu',
  'zbiju',
  'zbila',
  'zbile',
  'zbili',
  'zbilo',
  'zbiše',
  'zbiti',
  'zbude',
  'zbudi',
  'zbudu',
  'zbiva',
  'zbjeg',
  'zbora',
  'zbore',
  'zboru',
  'zbori',
  'zbrci',
  'zbrka',
  'zbrke',
  'zbrko',
  'zbrku',
  'zbrku',
  'zbroj',
  'zbune',
  'zbuni',
  'zdani',
  'zdere',
  'zdimi',
  'zdipi',
  'zdrav',
  'zdrug',
  'zebom',
  'zebić',
  'zebra',
  'zebre',
  'zebri',
  'zebro',
  'zebru',
  'zecom',
  'zečar',
  'zečić',
  'zečja',
  'zečji',
  'zečju',
  'zefir',
  'zekom',
  'zekić',
  'zelen',
  'zelja',
  'zelje',
  'zelju',
  'zeman',
  'zemna',
  'zemne',
  'zemni',
  'zemno',
  'zemnu',
  'zemun',
  'zenit',
  'zebem',
  'zebeš',
  'zerom',
  'zetem',
  'zetom',
  'zeusa',
  'zezaj',
  'zezam',
  'zezao',
  'zezaš',
  'zgade',
  'zgadi',
  'zgaze',
  'zgazi',
  'zgibe',
  'zgibu',
  'zgiba',
  'zglob',
  'zgoda',
  'zgode',
  'zgodi',
  'zgodo',
  'zgodu',
  'zgrbi',
  'zgrče',
  'zgrči',
  'zgrij',
  'zgrne',
  'zgrni',
  'zgrnu',
  'zgrće',
  'zgrći',
  'zgrću',
  'zgura',
  'zgure',
  'zguri',
  'zguro',
  'zguru',
  'ziban',
  'zibah',
  'zibaj',
  'zibam',
  'zibao',
  'zibaš',
  'zibat',
  'zicer',
  'ziđem',
  'zidom',
  'zidan',
  'zidar',
  'zidah',
  'zidaj',
  'zidam',
  'zidao',
  'zidaš',
  'zidat',
  'zidić',
  'zidje',
  'zidna',
  'zidne',
  'zidni',
  'zidno',
  'zidnu',
  'zijah',
  'zijaj',
  'zijam',
  'zijan',
  'zijaš',
  'zijat',
  'zijev',
  'zimom',
  'zimim',
  'zinem',
  'zinuh',
  'zinuo',
  'zinut',
  'zipci',
  'zipka',
  'zipke',
  'zipki',
  'zipko',
  'zipku',
  'zirka',
  'zivka',
  'zjalo',
  'zjape',
  'zjapi',
  'zlata',
  'zlato',
  'zlatu',
  'zloba',
  'zlobe',
  'zlobi',
  'zlobo',
  'zlobu',
  'zloća',
  'zloće',
  'zloći',
  'zloćo',
  'zloću',
  'zloti',
  'zlote',
  'zmaja',
  'zmaje',
  'zmaji',
  'zmaju',
  'zmija',
  'zmije',
  'zmiji',
  'zmijo',
  'zmiju',
  'znače',
  'znači',
  'znaci',
  'znače',
  'znaka',
  'znake',
  'znaku',
  'znana',
  'znane',
  'znano',
  'znanu',
  'znani',
  'znata',
  'znato',
  'znade',
  'znadu',
  'znahu',
  'znaju',
  'znala',
  'znale',
  'znali',
  'znalo',
  'znamo',
  'znaše',
  'znate',
  'znati',
  'znoja',
  'znoju',
  'znoje',
  'znoji',
  'zobam',
  'zobao',
  'zoben',
  'zobun',
  'zolja',
  'zombi',
  'zonom',
  'zorom',
  'zorak',
  'zorić',
  'zoril',
  'zorim',
  'zorio',
  'zorna',
  'zorne',
  'zorni',
  'zorno',
  'zornu',
  'zorno',
  'zovom',
  'zovne',
  'zovni',
  'zovnu',
  'zrače',
  'zrači',
  'zraka',
  'zraku',
  'zraci',
  'zraka',
  'zrake',
  'zrako',
  'zrnim',
  'zreli',
  'zrelo',
  'zrela',
  'zrele',
  'zrelu',
  'zrelo',
  'zreti',
  'zrije',
  'zrinj',
  'zriti',
  'zrnaš',
  'zrnat',
  'zrnca',
  'zrnce',
  'zrncu',
  'zrnić',
  'zrnom',
  'zrnja',
  'zrnje',
  'zrnju',
  'zubom',
  'zubac',
  'zupca',
  'zupce',
  'zupci',
  'zupcu',
  'zubak',
  'zubar',
  'zubat',
  'zubić',
  'zubna',
  'zubne',
  'zubni',
  'zubno',
  'zubnu',
  'zujem',
  'zujak',
  'zujah',
  'zujan',
  'zujao',
  'zujat',
  'zujim',
  'zujiš',
  'zuluf',
  'zulum',
  'zumom',
  'zumer',
  'zupče',
  'zurim',
  'zurio',
  'zuriš',
  'zurit',
  'zvana',
  'zvane',
  'zvano',
  'zvani',
  'zvanu',
  'zovem',
  'zoveš',
  'zvahu',
  'zvala',
  'zvale',
  'zvali',
  'zvalo',
  'zvaše',
  'zvati',
  'zveče',
  'zveči',
  'zveči',
  'zveka',
  'zveke',
  'zveki',
  'zveku',
  'zvonu',
  'zvone',
  'zvoni',
  'zvona',
  'zvono',
  'zvonu',
  'zvrče',
  'zvrči',
  'zvrka',
  'zvrke',
  'zvrku',
  'zvuča',
  'zvuče',
  'zvuči',
  'zvuči',
  'zvuci',
  'zvuka',
  'zvuke',
  'zvuku',
  'žabom',
  'žabac',
  'žabar',
  'žaboi',
  'žacah',
  'žacaj',
  'žacam',
  'žacao',
  'žacne',
  'žacnu',
  'žadom',
  'žagor',
  'žaket',
  'žalom',
  'žalac',
  'žalca',
  'žalce',
  'žalci',
  'žalcu',
  'žalče',
  'žaoci',
  'žalar',
  'žalba',
  'žalbe',
  'žalbi',
  'žalbo',
  'žalbu',
  'žalim',
  'žalit',
  'žalim',
  'žalio',
  'žališ',
  'žalit',
  'žalom',
  'žamor',
  'žanra',
  'žanre',
  'žanru',
  'žanje',
  'žanju',
  'žaoka',
  'žarom',
  'žarom',
  'žarač',
  'žarna',
  'žarnu',
  'žarče',
  'žaren',
  'žarah',
  'žarim',
  'žario',
  'žariš',
  'žarit',
  'žarka',
  'žarke',
  'žarki',
  'žarko',
  'žarku',
  'žarka',
  'žarko',
  'žarne',
  'žarni',
  'žbica',
  'žbice',
  'žbici',
  'žbico',
  'žbicu',
  'žbira',
  'žbire',
  'žbiri',
  'žbiru',
  'žbuci',
  'žbuka',
  'žbuke',
  'žbuki',
  'žbuko',
  'žbuku',
  'žbuna',
  'žbune',
  'žbunu',
  'ždera',
  'ždere',
  'žderi',
  'žderu',
  'ždral',
  'žegao',
  'žegla',
  'žegle',
  'žegli',
  'žeglo',
  'žežeš',
  'žedan',
  'žedna',
  'žedne',
  'žedni',
  'žedno',
  'žednu',
  'žeđom',
  'žeđam',
  'žeđao',
  'žeđaš',
  'žeđat',
  'žegom',
  'žegar',
  'želea',
  'želee',
  'želei',
  'želeu',
  'želja',
  'želje',
  'želji',
  'željo',
  'želju',
  'želim',
  'želio',
  'želiš',
  'ženom',
  'ženik',
  'ženin',
  'ženim',
  'ženio',
  'ženiš',
  'ženit',
  'ženka',
  'ženke',
  'ženki',
  'ženko',
  'ženku',
  'žesti',
  'žešća',
  'žešće',
  'žeton',
  'žetva',
  'žetve',
  'žetvi',
  'žetvo',
  'žetvu',
  'žežem',
  'žezla',
  'žezlo',
  'žezlu',
  'žežen',
  'žgali',
  'žgati',
  'žicom',
  'žican',
  'žicah',
  'žicaj',
  'žicam',
  'žicao',
  'žicaš',
  'žicat',
  'žičan',
  'žičnu',
  'žična',
  'žične',
  'žični',
  'žično',
  'židak',
  'žitka',
  'žitke',
  'žitko',
  'žitku',
  'žigom',
  'žigić',
  'žigne',
  'žilom',
  'žilav',
  'žilet',
  'žilić',
  'žilna',
  'žilne',
  'žilni',
  'žilno',
  'žilja',
  'žilju',
  'žirom',
  'žiroa',
  'žiroe',
  'žiroi',
  'žirou',
  'žiška',
  'žitan',
  'žitar',
  'žitak',
  'žitki',
  'žitna',
  'žitne',
  'žitni',
  'žitno',
  'žitnu',
  'žitom',
  'živih',
  'živim',
  'živom',
  'živac',
  'živca',
  'živce',
  'živci',
  'živcu',
  'živče',
  'živad',
  'živež',
  'živog',
  'živoj',
  'živom',
  'živin',
  'živim',
  'živim',
  'živio',
  'živiš',
  'živka',
  'živnu',
  'život',
  'žižom',
  'žišci',
  'žižak',
  'žižić',
  'žlica',
  'žlice',
  'žlici',
  'žlico',
  'žlicu',
  'žljeb',
  'žmare',
  'žmiga',
  'žmire',
  'žmiri',
  'žnori',
  'žnoru',
  'žnora',
  'žohar',
  'žrecu',
  'žrtva',
  'žrtve',
  'žrtvi',
  'žrtvo',
  'žrtvu',
  'žubor',
  'žučju',
  'žučan',
  'žučne',
  'žučno',
  'žučnu',
  'žučna',
  'žučni',
  'žućih',
  'žućim',
  'žućoj',
  'žućom',
  'žudan',
  'žudim',
  'žudio',
  'žudiš',
  'žuđah',
  'žudno',
  'žuđen',
  'žuhak',
  'žukih',
  'žukoj',
  'žukom',
  'žulja',
  'žulje',
  'žulju',
  'žulji',
  'žunom',
  'župom',
  'župan',
  'župna',
  'župne',
  'župni',
  'župno',
  'župnu',
  'žuran',
  'žurna',
  'žurne',
  'žurni',
  'žurno',
  'žurnu',
  'žurba',
  'žurbe',
  'žurbi',
  'žurbo',
  'žurbu',
  'žurah',
  'žurim',
  'žurio',
  'žuriš',
  'žurit',
  'žurna',
  'žurno',
  'žutih',
  'žutim',
  'žutog',
  'žutom',
  'žutoj',
  'žužul',
  'žvaka',
  'žvake',
  'žvaku',
  'žvače',
  'žvači',
  'žvaču',
  'žvale',
  'žvala',
  'žvale',
  'žvali',
]
