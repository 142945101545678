import { CONFIG } from '../constants/config'

export type GameSettings = {
  shuffle: boolean
}

const gameSettingsKey = 'gameSettings'
export const saveSettingsToLocalStorage = (gameSettings: GameSettings) => {
  localStorage.setItem(gameSettingsKey, JSON.stringify(gameSettings))
}

export const loadSettingsFromLocalStorage = () => {
  const settings = localStorage.getItem(gameSettingsKey)
  return settings ? (JSON.parse(settings) as GameSettings) : null
}

let settings = loadSettingsFromLocalStorage()

const gameStateKey = settings?.shuffle ? 'gameStateShuffle' : 'gameState'

type StoredGameState = {
  guesses: string[][]
  solution: string
}

export const saveGameStateToLocalStorage = (gameState: StoredGameState) => {
  localStorage.setItem(gameStateKey, JSON.stringify(gameState))
}

export const loadGameStateFromLocalStorage = () => {
  const state = localStorage.getItem(gameStateKey)
  return state ? (JSON.parse(state) as StoredGameState) : null
}

const gameStatKey = 'gameStats'

export type GameStats = {
  winDistribution: number[]
  gamesFailed: number
  currentStreak: number
  bestStreak: number
  totalGames: number
  successRate: number
}

export const saveStatsToLocalStorage = (gameStats: GameStats) => {
  let shuffle = loadSettingsFromLocalStorage()?.shuffle
  if (!shuffle) {
    localStorage.setItem(gameStatKey, JSON.stringify(gameStats))
  }
}

export const loadStatsFromLocalStorage = () => {
  const stats = localStorage.getItem(gameStatKey)
  return stats ? (JSON.parse(stats) as GameStats) : null
}
